<div class="card card-form">
  <div class="card-return" routerLink="../login"></div>
  <div class="card-title">Forgot password</div>
  <div class="card-subtitle">
    Requesting a password reset will send you an email, containing a one time
    link used for setting a new password.
  </div>
  <div class="card-body">
    <form class="form">
      <input class="form-input" type="email" placeholder="Email address" />
      <button class="btn btn-secondary">Send reset request</button>
    </form>
  </div>
</div>
