import { Injectable } from '@angular/core';
import kjua from 'kjua-svg';
import { jsPDF } from 'jspdf';

import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, Subject } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(
    private fn: AngularFireFunctions,
    private dashboardService: DashboardService
  ) {}

  private formQRCodeSubject = new Subject();
  public formQRCode = this.formQRCodeSubject.asObservable();

  private columnsPerPage = 3;
  private rowsPerPage = 5;
  private pageWidth = 210;
  private pageHeight = 297;
  private cellWidth = 60;
  private cellHeight = 60;
  private borderTopBottom =
    (this.pageHeight - this.rowsPerPage * this.cellHeight) / 2;

  public generateFormQRCode(
    formType: string,
    step: number | string,
    formData: any
  ): void {
    this.urlEncode({ formType, step, formData }).subscribe((token) => {
      this.formQRCodeSubject.next(this.generateSVGFromToken(token));
    });
  }

  public generateSVGFromToken(token: string, size?: number): string {
    const qrCode = kjua({
      render: 'svg',
      size: size || 150,
      text: `${environment.qrUrl}${token}`,
    });

    return qrCode.lastChild.attributes.d.value;
  }

  private urlEncode(data: any): Observable<any> {
    return this.fn.httpsCallable('qrcode-urlEncode')(data);
  }

  private generateBarcode(text: string, label: string): any {
    return kjua({
      render: 'canvas',
      size: 500,
      text,
      quiet: 5,
      mode: 'label',
      mSize: 5,
      mPosX: 50,
      mPosY: 100,
      label,
      fontname: 'montserrat',
      fontcolor: '#000',
    });
  }

  public generatePDF(
    type: string,
    index = 0,
    document = new jsPDF(),
    colPos = 0,
    rowPos = 0
  ): void {
    const selection = this.dashboardService.selection.value;
    const text = `${environment.qrUrl}${type}=${selection[index].uid}`;
    const label = selection[index].name?.substring(0, 25);
    const barcodeData = this.generateBarcode(text, label);

    const x =
      this.pageWidth / this.columnsPerPage / 2 -
      this.cellWidth / 2 +
      colPos * (this.pageWidth / this.columnsPerPage);
    const y = this.borderTopBottom + rowPos * this.cellHeight + 1;

    document.addImage(
      barcodeData,
      'JPG',
      x,
      y,
      this.cellWidth - 2,
      this.cellHeight - 2
    );
    colPos++;

    if (colPos >= this.columnsPerPage) {
      colPos = 0;
      rowPos++;
    }
    if (rowPos >= this.rowsPerPage && index < selection.length - 1) {
      rowPos = 0;
      colPos = 0;
      document.addPage();
    }

    if (index === selection.length - 1) {
      document.save(`AssetIO-QRCodes.pdf`);
    } else {
      requestAnimationFrame(() =>
        this.generatePDF(type, index + 1, document, colPos, rowPos)
      );
    }
  }
}
