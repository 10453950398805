<div class="view-root">
  <section class="background">
    <svg viewBox="0 0 743.483 852.672">
      <g id="undraw_Through_the_desert_rqtu" transform="translate(0 0)">
        <path
          id="Path_161"
          data-name="Path 161"
          d="M272.347,537.956S137.224,619.473,386.23,744.984c0,0,54.864-101.9-5.794-184.951a81.8,81.8,0,0,0-106.052-23.316Z"
          transform="translate(-235.746 59.234)"
          fill="#28d8a1"
        />
        <path
          id="Path_162"
          data-name="Path 162"
          d="M254.845,531.286s115.642,88.39,112.385,208.747"
          transform="translate(-216.686 64.186)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_163"
          data-name="Path 163"
          d="M315.694,864.728s-42.337-143.853,7.992-249.565a227.068,227.068,0,0,0,18.361-142.455,381.879,381.879,0,0,0-20.16-66.852"
          transform="translate(-172.582 -60.988)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <path
          id="Path_164"
          data-name="Path 164"
          d="M358.075,389.389c0,17.622-31.967,68.71-31.967,68.71s-31.967-51.088-31.967-68.71a31.967,31.967,0,1,1,63.795,0Z"
          transform="translate(-177.541 -111.433)"
          fill="#28d8a1"
        />
        <path
          id="Path_165"
          data-name="Path 165"
          d="M384.52,446.713c-9.57,14.785-64.115,40.339-64.115,40.339s1-60.219,10.569-75a31.967,31.967,0,1,1,53.545,34.685Z"
          transform="translate(-151.259 -71.157)"
          fill="#28d8a1"
        />
        <path
          id="Path_166"
          data-name="Path 166"
          d="M389.158,534.411c-15.724,7.992-75.7,2.458-75.7,2.458s31.248-51.487,46.992-59.419a31.967,31.967,0,0,1,28.731,56.962Z"
          transform="translate(-158.195 7.915)"
          fill="#28d8a1"
        />
        <path
          id="Path_167"
          data-name="Path 167"
          d="M373.289,597.02c-14.166,10.469-74.2,15.2-74.2,15.2s22.1-55.943,36.263-66.512A31.967,31.967,0,1,1,373.309,597Z"
          transform="translate(-172.536 71.078)"
          fill="#28d8a1"
        />
        <path
          id="Path_168"
          data-name="Path 168"
          d="M292.5,471.24c12.627,12.268,71.507,24.995,71.507,24.995s-14.425-58.48-27.052-70.748A31.967,31.967,0,1,0,292.5,471.24Z"
          transform="translate(-190.348 -51.949)"
          fill="#28d8a1"
        />
        <path
          id="Path_169"
          data-name="Path 169"
          d="M279.9,547.63c15.724,7.992,75.7,2.458,75.7,2.458s-31.248-51.487-46.992-59.419a31.967,31.967,0,1,0-28.731,56.962Z"
          transform="translate(-211.133 19.231)"
          fill="#28d8a1"
        />
        <path
          id="Path_170"
          data-name="Path 170"
          d="M271.069,620.36c14.166,10.469,74.2,15.2,74.2,15.2s-22.1-55.943-36.263-66.512a31.967,31.967,0,1,0-37.961,51.288Z"
          transform="translate(-215.088 94.37)"
          fill="#28d8a1"
        />
        <path
          id="Path_171"
          data-name="Path 171"
          d="M358.075,389.389c0,17.622-31.967,68.71-31.967,68.71s-31.967-51.088-31.967-68.71a31.967,31.967,0,1,1,63.795,0Z"
          transform="translate(-177.541 -111.433)"
          opacity="0.25"
        />
        <path
          id="Path_172"
          data-name="Path 172"
          d="M384.52,446.713c-9.57,14.785-64.115,40.339-64.115,40.339s1-60.219,10.569-75a31.967,31.967,0,1,1,53.545,34.685Z"
          transform="translate(-151.259 -71.157)"
          opacity="0.25"
        />
        <path
          id="Path_173"
          data-name="Path 173"
          d="M389.158,534.411c-15.724,7.992-75.7,2.458-75.7,2.458s31.248-51.487,46.992-59.419a31.967,31.967,0,0,1,28.731,56.962Z"
          transform="translate(-158.195 7.915)"
          opacity="0.25"
        />
        <path
          id="Path_174"
          data-name="Path 174"
          d="M373.289,597.02c-14.166,10.469-74.2,15.2-74.2,15.2s22.1-55.943,36.263-66.512A31.967,31.967,0,1,1,373.309,597Z"
          transform="translate(-172.536 71.078)"
          opacity="0.25"
        />
        <path
          id="Path_175"
          data-name="Path 175"
          d="M292.5,471.24c12.627,12.268,71.507,24.995,71.507,24.995s-14.425-58.48-27.052-70.748A31.967,31.967,0,1,0,292.5,471.24Z"
          transform="translate(-190.348 -51.949)"
          opacity="0.25"
        />
        <path
          id="Path_176"
          data-name="Path 176"
          d="M279.9,547.63c15.724,7.992,75.7,2.458,75.7,2.458s-31.248-51.487-46.992-59.419a31.967,31.967,0,1,0-28.731,56.962Z"
          transform="translate(-211.133 19.231)"
          opacity="0.25"
        />
        <path
          id="Path_177"
          data-name="Path 177"
          d="M271.069,620.36c14.166,10.469,74.2,15.2,74.2,15.2s-22.1-55.943-36.263-66.512a31.967,31.967,0,1,0-37.961,51.288Z"
          transform="translate(-215.088 94.37)"
          opacity="0.25"
        />
        <path
          id="Path_178"
          data-name="Path 178"
          d="M376.734,889.078S334.4,745.224,384.726,639.512a227.069,227.069,0,0,0,18.361-142.455,381.868,381.868,0,0,0-20.159-66.852"
          transform="translate(-111.666 -36.688)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <path
          id="Path_179"
          data-name="Path 179"
          d="M419,411.648c0,17.622-31.967,68.71-31.967,68.71s-31.808-51.148-31.808-68.71a31.967,31.967,0,0,1,63.795,0Z"
          transform="translate(-116.51 -85.002)"
          fill="#28d8a1"
        />
        <path
          id="Path_180"
          data-name="Path 180"
          d="M445.56,471.083c-9.57,14.785-64.115,40.339-64.115,40.339s1-60.219,10.569-75A31.967,31.967,0,1,1,445.56,471.1Z"
          transform="translate(-90.344 -46.837)"
          fill="#28d8a1"
        />
        <path
          id="Path_181"
          data-name="Path 181"
          d="M450.2,559.741c-15.724,7.992-75.7,2.458-75.7,2.458s31.228-51.468,46.972-59.459A31.967,31.967,0,1,1,450.2,559.7Z"
          transform="translate(-97.28 31.277)"
          fill="#28d8a1"
        />
        <path
          id="Path_182"
          data-name="Path 182"
          d="M434.349,620.2c-14.166,10.469-74.2,15.2-74.2,15.2s22.1-55.943,36.263-66.512a31.967,31.967,0,0,1,37.961,51.288Z"
          transform="translate(-111.6 96.592)"
          fill="#28d8a1"
        />
        <path
          id="Path_183"
          data-name="Path 183"
          d="M352.019,494.18c12.627,12.268,71.507,24.994,71.507,24.994s-14.425-58.48-27.052-70.748a31.967,31.967,0,0,0-44.455,45.753Z"
          transform="translate(-127.909 -26.098)"
          fill="#28d8a1"
        />
        <path
          id="Path_184"
          data-name="Path 184"
          d="M340.986,572.015c15.724,7.992,75.7,2.457,75.7,2.457s-31.248-51.488-46.992-59.419a31.967,31.967,0,1,0-28.711,56.962Z"
          transform="translate(-150.223 43.536)"
          fill="#28d8a1"
        />
        <path
          id="Path_185"
          data-name="Path 185"
          d="M332.1,644.73c14.166,10.469,74.2,15.2,74.2,15.2s-22.1-55.943-36.263-66.512a31.967,31.967,0,1,0-37.961,51.288Z"
          transform="translate(-154.183 118.69)"
          fill="#28d8a1"
        />
        <path
          id="Path_186"
          data-name="Path 186"
          d="M663.9,249.789H652.094a8.272,8.272,0,1,0,0-16.543H556.432a8.272,8.272,0,1,0,0,16.543H568.24a8.272,8.272,0,1,0,0,16.543H551.7a8.272,8.272,0,1,0,0,16.543h95.662a8.272,8.272,0,1,0,.008-16.543H663.9a8.272,8.272,0,1,0,.008-16.543Z"
          transform="translate(71.306 -233.246)"
          fill="#252223"
          opacity="0.1"
        />
        <path
          id="Path_187"
          data-name="Path 187"
          d="M565.1,313.389H553.3a8.272,8.272,0,1,0,0-16.543H457.654a8.272,8.272,0,1,0,0,16.543h11.808a8.272,8.272,0,1,0,0,16.543H452.919a8.272,8.272,0,0,0-.008,16.543h95.67a8.272,8.272,0,1,0,.008-16.543h16.535a8.272,8.272,0,1,0,0-16.543Z"
          transform="translate(-27.275 -169.776)"
          fill="#252223"
          opacity="0.1"
        />
        <circle
          id="Ellipse_29"
          data-name="Ellipse 29"
          cx="87.531"
          cy="87.531"
          r="87.531"
          transform="translate(161.653 0.42)"
          fill="#28d8a1"
          opacity="0.3"
        />
      </g>
    </svg>
  </section>
  <section class="content">
    <router-outlet></router-outlet>
  </section>
</div>
