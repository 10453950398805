import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPageInfo } from 'ngx-virtual-scroller';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/shared/services/log.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit {
  constructor(private logService: LogService) {}

  public loading = false;
  public data: any;
  @Input() type: string;
  @Input() id: string;

  ngOnInit(): void {
    this.data = this.logService
      .getLogCollection(this.getLimit)
      .pipe(map((v) => Object.values(v)));
  }

  public fetchMore(event: IPageInfo): void {
    // console.log(event);
  }

  public logData(log) {
    // console.log(log);
  }

  private get getLimit(): any {
    switch (this.type) {
      case 'users':
        return { type: 'userId', id: this.id };
      case 'assets':
        return { type: 'assetId', id: this.id };
      case 'groups':
        return { type: 'groupId', id: this.id };
      default:
        break;
    }
  }
}
