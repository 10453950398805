import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(
    private fs: AngularFirestore,
    private fn: AngularFireFunctions,
    private authService: AuthService,
    private router: Router
  ) {
    if (location.hostname !== 'assetio.co.za') {
      fn.useFunctionsEmulator('http://172.16.5.204:5001');
    }
  }

  private get collection(): string {
    return `organisations/${this.authService.orgId$.value}/groups`;
  }

  public createGroup(data: any): Observable<any> {
    const { formValue, checkboxControl } = data;
    const {
      section1: { displayName, name, serialCode },
      section2: { groupType, notes },
    } = formValue;
    const { usersAssets } = checkboxControl;
    return this.fn.httpsCallable('groups-newGroup')({
      displayName,
      name,
      serialCode,
      groupType,
      notes,
      usersAssets,
    });
  }

  public getGroupCollection(limit?: string): Observable<any> {
    return this.fs
      .collection(this.collection, (ref) =>
        ref
          .orderBy('displayName')
          .where(
            'groupType',
            limit ? '==' : 'in',
            limit ? limit : ['user', 'device', 'accessory', 'book']
          )
          .where('displayName', '>=', '')
          .where('displayName', '<=', '' + '\uf8ff')
      )
      .snapshotChanges()
      .pipe(
        map((arr) => {
          if (!arr) {
            return new Error(
              'Group collection empty. Add a group and try again.'
            );
          }

          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc['id'];
            const data: any = cur.payload.doc.data();

            data.uid = id;

            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  public updateGroup(id: string, updatedData: any): Observable<any> {
    return this.fn
      .httpsCallable('groups-updateGroup')({ id, updatedData })
      .pipe(take(1));
  }

  public deleteGroup(id: string): void {
    this.fn
      .httpsCallable('groups-deleteGroup')(id)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('dashboard/groups');
      });
  }

  public deleteGroups(ids: Array<string>): Observable<any> {
    return this.fn.httpsCallable('groups-deleteGroup')(ids).pipe(take(1));
  }

  public getGroup(id: string): Observable<any> {
    return this.fs
      .collection(this.collection)
      .doc(id)
      .get()
      .pipe(
        map((doc) => {
          if (!doc.exists) {
            throw new Error('Group does not exist');
          }
          const data = doc.data();
          data['id'] = doc.id;

          return data;
        })
      );
  }

  // public getGroupItems(groupId: string): Observable<any> {
  //   return this.fs
  //     .collection(`organisations/${this.authService.orgId}/users`, (ref) =>
  //       ref.where('groups', 'array-contains', groupId)
  //     )
  //     .snapshotChanges()
  //     .pipe(
  //       map((arr) => {
  //         return arr.reduce((acc, cur) => {
  //           const id = cur.payload.doc['id'];
  //           const data: any = cur.payload.doc.data();

  //           data.uid = id;

  //           return { ...acc, [id]: data };
  //         }, {});
  //       })
  //     );
  // }
}
