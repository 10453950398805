import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private bpObserver: BreakpointObserver) {}

  public get isDesktop(): Observable<boolean> {
    return this.bpObserver.observe(['(min-width: 1024px)']).pipe(
      map((device) => {
        return device.matches;
      })
    );
  }

  public get isMobile(): Observable<boolean> {
    return this.bpObserver.observe(['(max-width: 1024px)']).pipe(
      map((device) => {
        return device.matches;
      })
    );
  }
}
