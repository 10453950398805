import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    public formService: FormService,
    public authService: AuthService
  ) {}

  public loading = false;
  public errorMessage: string;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.loginForm.valid) {
      return this.loginForm.markAllAsTouched();
    }
    this.loading = true;
    const { email, password } = this.loginForm.value;

    try {
      await this.authService.emailSignIn(email, password);
    } catch (error) {
      this.loading = false;
      this.errorMessage = error.message;
    }
  }
}
