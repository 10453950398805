import { Component, OnInit } from '@angular/core';
import { Scanner } from 'src/app/shared/models/terminal.model';
import { User } from 'src/app/shared/models/user.model';
import { TerminalService } from 'src/app/shared/services/terminal.service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit {
  constructor(private terminalService: TerminalService) {}

  public terminalName: string;
  public state: string;

  public svgData: string;
  public user: User;
  public scanner: Scanner;
  public timer: number;

  ngOnInit(): void {
    this.terminalService.terminal$.subscribe((terminal) => {
      const { state, registration, view, scanner } = terminal;
      const { terminalName } = registration || {};
      const { qrCode, user, timer } = view || {};

      this.state = state;
      this.terminalName = terminalName;

      this.svgData = qrCode;
      this.user = user;
      this.timer = timer;

      this.scanner = scanner;
    });
  }
}
