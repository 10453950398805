<nav class="navbar">
  <ul>
    <li
      class="menu-item btn-menu add"
      [class.open]="dashboardService?.newForm | async"
      (click)="toggleForm()"
    >
      <svg viewBox="0 0 53 53">
        <g id="Group_57" data-name="Group 57" transform="translate(-26 -192)">
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="26.5"
            cy="26.5"
            r="26.5"
            transform="translate(26 192)"
            fill="#28d8a1"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M19.99,13.566H13.566V19.99H11.424V13.566H5V11.424h6.424V5h2.141v6.424H19.99Z"
            transform="translate(40.354 206.354)"
            fill="#fff"
          />
        </g>
      </svg>
    </li>
    <li
      class="menu-item btn-route"
      routerLink="overview"
      routerLinkActive="active"
    >
      <svg viewBox="0 0 38 38">
        <path
          id="Path_465"
          data-name="Path 465"
          d="M31.083,11.214l-1.947,2.929a12.667,12.667,0,0,1-.348,12H6.842A12.667,12.667,0,0,1,23.483,8.49l2.929-1.948A15.833,15.833,0,0,0,4.119,27.728a3.167,3.167,0,0,0,2.723,1.583H28.771a3.167,3.167,0,0,0,2.755-1.583A15.833,15.833,0,0,0,31.1,11.2Zm-15.5,10.83a3.167,3.167,0,0,0,4.481,0L29.025,8.6,15.582,17.563a3.167,3.167,0,0,0,0,4.481Z"
          transform="translate(1.185 2.355)"
          fill="#c9c9c9"
        />
      </svg>
      <span>Overview</span>
    </li>
    <li
      class="menu-item btn-route"
      routerLink="users"
      routerLinkActive="active"
    >
      <svg viewBox="0 0 38.516 38.516">
        <path
          id="Path_21"
          data-name="Path 21"
          d="M13.234,19.042C9.478,19.042,2,20.92,2,24.659v2.808H24.468V24.659C24.468,20.92,16.989,19.042,13.234,19.042ZM5.755,24.258a15.473,15.473,0,0,1,7.478-2.006,15.473,15.473,0,0,1,7.478,2.006Zm7.478-8.024a5.617,5.617,0,1,0-5.617-5.617A5.623,5.623,0,0,0,13.234,16.234Zm0-8.024a2.407,2.407,0,1,1-2.407,2.407A2.4,2.4,0,0,1,13.234,8.21Zm11.3,10.929a6.728,6.728,0,0,1,3.145,5.521v2.808H34.1V24.659C34.1,21.417,28.48,19.572,24.532,19.138Zm-1.669-2.9A5.617,5.617,0,1,0,22.863,5a5.529,5.529,0,0,0-2.407.562,8.764,8.764,0,0,1,0,10.11A5.529,5.529,0,0,0,22.863,16.234Z"
          transform="translate(1.21 3.024)"
          fill="#c9c9c9"
        /></svg
      ><span>Users</span>
    </li>
    <li class="menu-item btn-menu camera" routerLink="qr">
      <svg viewBox="0 0 53 53">
        <g
          id="Group_225"
          data-name="Group 225"
          transform="translate(-3612 -3246)"
        >
          <circle
            id="Ellipse_65"
            data-name="Ellipse 65"
            cx="26.5"
            cy="26.5"
            r="26.5"
            transform="translate(3612 3246)"
            fill="#025ff5"
          />
          <g id="photo_camera-24px" transform="translate(3621.143 3254.817)">
            <path
              id="Path_368"
              data-name="Path 368"
              d="M0,0H35.359V35.359H0Z"
              fill="none"
            />
            <path
              id="Path_369"
              data-name="Path 369"
              d="M19.856,4.947l2.7,2.947h5.967v17.68H4.947V7.893h5.967l2.7-2.947h6.247M21.153,2h-8.84l-2.7,2.947H4.947A2.955,2.955,0,0,0,2,7.893v17.68a2.955,2.955,0,0,0,2.947,2.947H28.519a2.955,2.955,0,0,0,2.947-2.947V7.893a2.955,2.955,0,0,0-2.947-2.947h-4.67Zm-4.42,10.313a4.42,4.42,0,1,1-4.42,4.42,4.433,4.433,0,0,1,4.42-4.42m0-2.947A7.366,7.366,0,1,0,24.1,16.733,7.369,7.369,0,0,0,16.733,9.366Z"
              transform="translate(0.947 0.947)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </li>
    <li
      class="menu-item btn-route"
      routerLink="assets"
      routerLinkActive="active"
    >
      <svg viewBox="0 0 32 32">
        <path
          id="Path_23"
          data-name="Path 23"
          d="M5.333,6.667h24V4h-24A2.675,2.675,0,0,0,2.667,6.667V21.333H0v4H18.667v-4H5.333ZM30.667,9.333h-8a1.337,1.337,0,0,0-1.333,1.333V24a1.337,1.337,0,0,0,1.333,1.333h8A1.337,1.337,0,0,0,32,24V10.667A1.337,1.337,0,0,0,30.667,9.333Zm-1.333,12H24V12h5.333Z"
          transform="translate(0 1.333)"
          fill="#c9c9c9"
        /></svg
      ><span>Assets</span>
    </li>
    <li
      class="menu-item btn-route"
      routerLink="groups"
      routerLinkActive="active"
    >
      <svg viewBox="0 0 35 35">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M12.456,6.917l2.917,2.917H28.25V24.417H4.917V6.917h7.54M13.667,4H4.917a2.913,2.913,0,0,0-2.9,2.917L2,24.417a2.925,2.925,0,0,0,2.917,2.917H28.25a2.925,2.925,0,0,0,2.917-2.917V9.833A2.925,2.925,0,0,0,28.25,6.917H16.583Z"
          transform="translate(0.917 1.833)"
          fill="#c9c9c9"
        /></svg
      ><span>Groups</span>
    </li>
    <li class="menu-item btn-route" (click)="authService?.logout()">
      <svg viewBox="0 0 36 36">
        <path
          id="Path_467"
          data-name="Path 467"
          d="M24.5,9l-2.115,2.115L26.255,15H11v3H26.255l-3.87,3.87L24.5,24,32,16.5ZM5,6H17V3H5A3.009,3.009,0,0,0,2,6V27a3.009,3.009,0,0,0,3,3H17V27H5Z"
          transform="translate(1 1.5)"
          fill="#c9c9c9"
        />
      </svg>
      <span>Logout</span>
    </li>
    <div class="active-indicator"></div>
  </ul>
</nav>
