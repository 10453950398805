<zxing-scanner
  (scanSuccess)="terminalService?.terminalQrScan($event)"
></zxing-scanner>
<div class="card card-terminal" [ngSwitch]="getSection">
  <section *ngSwitchCase="0">
    <div
      class="fingerprint"
      [class.danger]="hasError"
      [class.loading]="!hasError && state === 'busy'"
    ></div>

    <h5>Please place your right index finger on the reader.</h5>
  </section>
  <section class="terminal-asset-group" *ngSwitchCase="1">
    <div class="asset" [ngClass]="assetClass"></div>
    <div class="loading" *ngIf="assetClass"></div>
    <h5>
      {{ assetText }}
    </h5>
  </section>
  <section *ngSwitchCase="2">
    <div class="fingerprint new" [class.success]="scanner?.link?.valid"></div>

    <div class="progress" *ngIf="!scanner?.link?.valid">
      <span class="step" [class.complete]="complete(0)"></span>
      <span class="step" [class.complete]="complete(1)"></span>
      <span class="step" [class.complete]="complete(2)"></span>
    </div>
    <h5 *ngIf="!scanner?.link?.valid">{{ instruction }}</h5>
  </section>
</div>
