<div class="card card-terminal-link">
  <form
    class="form"
    [formGroup]="terminalForm"
    (ngSubmit)="registerNewTerminal()"
  >
    <app-checkbox-control
      [type]="modal"
      *ngIf="modal"
      (selection)="selection($event)"
    ></app-checkbox-control>
    <div
      class="validation-container"
      *ngFor="let formField of formFields"
      [class.invalid]="
        formService?.inputValidation(terminalForm, formField) && !modal
      "
    >
      <input
        class="form-input"
        [type]="formField.type"
        [placeholder]="formField.placeholder"
        [formControlName]="formField.formControlName"
        [readonly]="formField.type == 'menu'"
        (click)="
          formField.type == 'menu' && openModal(formField.formControlName)
        "
      />
      <p class="form-error-text">
        {{ formService?.inputValidationError(terminalForm, formField) }}
      </p>
    </div>
    <button type="submit" class="btn-no-margin btn-secondary">
      <div [class.loader]="loading === 'newTerminal'">
        Register new terminal
      </div>
    </button>
    <div class="hr"><span>or</span></div>
    <button
      type="button"
      class="btn-no-margin btn-secondary"
      (click)="openModal('terminal')"
    >
      <div [class.loader]="loading === 'terminal'">Choose terminal</div>
    </button>
  </form>
</div>
