import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const firebaseConfig = environment.firebaseConfig;
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { DashboardModule } from './views/dashboard/dashboard.module';
import { LandingModule } from './views/landing/landing.module';
import { AssetModule } from './views/asset/asset.module';
import { TerminalModule } from './views/terminal/terminal.module';
import { SharedModule } from './shared/shared.module';

const viewModules = [
  LandingModule,
  DashboardModule,
  TerminalModule,
  AssetModule,
  SharedModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    viewModules,
    ToastrModule.forRoot({
      timeOut: 7000,
      easing: 'ease-in-out',
      maxOpened: 2,
    }),
    BrowserAnimationsModule,
  ],
  providers: [{ provide: REGION, useValue: 'europe-west1' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
