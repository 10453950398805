<zxing-scanner
  #scanner
  (scanSuccess)="scanSuccessHandler($event)"
></zxing-scanner>

<div class="card-container-qr" *ngIf="busy">
  <div class="card card-qr">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="210.069"
      height="156.243"
      viewBox="0 0 210.069 156.243"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="gray" stop-opacity="0.251" />
          <stop offset="0.54" stop-color="gray" stop-opacity="0.122" />
          <stop offset="1" stop-color="gray" stop-opacity="0.102" />
        </linearGradient>
      </defs>
      <g id="undraw_time_management_30iu" transform="translate(-0.007 -0.004)">
        <path
          id="Path_570"
          data-name="Path 570"
          d="M102.207,79.508a6.641,6.641,0,0,0-3.366,2.458,7.222,7.222,0,0,0-.528,4.38c1.234,12.932,4.485,26.684-1.129,38.4-2.725,5.683-7.283,10.237-10.908,15.4s-6.363,11.6-4.679,17.676c2.055,7.419,9.727,11.532,14.9,17.241,3.385,3.733,5.758,8.268,8.916,12.2s7.485,7.386,12.527,7.617c7.251.334,13.15-5.928,20.259-7.378,7.283-1.485,14.617,2.294,20.522,6.818s11.277,10,18.157,12.828c7.6,3.122,16.164,2.644,24.164,4.52,7.283,1.709,13.988,5.345,21.218,7.283s15.705,1.821,21.307-3.13c7.3-6.474,6.727-18.005,11.39-26.569,4.331-7.957,12.81-12.707,18.517-19.741,10.269-12.66,9.933-32.011,1.214-45.78s-24.514-22.106-40.664-24.277c-7.283-.977-14.783-.844-21.785-3.067-18.742-5.948-29.9-27.259-49.029-31.694-12.163-2.814-23.992,2.059-34.352,8.058C119.862,77.948,111.565,75.626,102.207,79.508Z"
          transform="translate(-81.08 -63.86)"
          fill="#28d8a1"
          opacity="0.1"
        />
        <path
          id="Path_571"
          data-name="Path 571"
          d="M172.882,347.69s-6.9,6.516-14.315,6.711a14.382,14.382,0,0,0-8.572,3.049,24.189,24.189,0,0,0-3.2,3.049"
          transform="translate(-133.491 -290.265)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <path
          id="Path_572"
          data-name="Path 572"
          d="M118.856,410.689c1-.506,4.795-.17,4.795-.17s-1.97,3.267-2.966,3.773a2.023,2.023,0,1,1-1.821-3.6Z"
          transform="translate(-110.23 -340.296)"
          fill="#28d8a1"
        />
        <path
          id="Path_573"
          data-name="Path 573"
          d="M135.007,383.226c1.111.117,4.117,2.464,4.117,2.464s-3.439,1.671-4.54,1.556a2.023,2.023,0,0,1,.423-4.02Z"
          transform="translate(-122.381 -318.609)"
          fill="#28d8a1"
        />
        <path
          id="Path_574"
          data-name="Path 574"
          d="M177.939,358.89c.9.662,2.31,4.206,2.31,4.206s-3.805-.289-4.7-.949a2.023,2.023,0,0,1,2.393-3.257Z"
          transform="translate(-155.829 -298.929)"
          fill="#28d8a1"
        />
        <path
          id="Path_575"
          data-name="Path 575"
          d="M217.814,349.779c1,.5,2.988,3.755,2.988,3.755s-3.8.358-4.8-.142a2.023,2.023,0,1,1,1.809-3.613Z"
          transform="translate(-187.735 -291.722)"
          fill="#28d8a1"
        />
        <path
          id="Path_576"
          data-name="Path 576"
          d="M158.56,394.876c.332-1.066-.639-4.756-.639-4.756s-2.889,2.492-3.221,3.559a2.023,2.023,0,1,0,3.86,1.2Z"
          transform="translate(-139.704 -324.111)"
          fill="#28d8a1"
        />
        <path
          id="Path_577"
          data-name="Path 577"
          d="M204.252,384.866c0-1.117-2.033-4.346-2.033-4.346s-2.011,3.237-2.009,4.358a2.023,2.023,0,1,0,4.046-.012Z"
          transform="translate(-176.103 -316.454)"
          fill="#28d8a1"
        />
        <path
          id="Path_578"
          data-name="Path 578"
          d="M247.508,366.4c.186-1.1-1.27-4.627-1.27-4.627s-2.531,2.857-2.715,3.957a2.023,2.023,0,0,0,3.985.672Z"
          transform="translate(-210.641 -301.497)"
          fill="#28d8a1"
        />
        <path
          id="Path_579"
          data-name="Path 579"
          d="M240.389,180.02s-8.317-4.568-10.789-11.566a14.382,14.382,0,0,0-5.537-7.22,24.19,24.19,0,0,0-3.892-2.1"
          transform="translate(-192.025 -139.853)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <path
          id="Path_580"
          data-name="Path 580"
          d="M199.018,135.3c.787.791,1.635,4.511,1.635,4.511s-3.714-.87-4.5-1.663a2.023,2.023,0,0,1,2.867-2.849Z"
          transform="translate(-172.451 -120.419)"
          fill="#28d8a1"
        />
        <path
          id="Path_581"
          data-name="Path 581"
          d="M229.779,140.714c.231,1.092-1.078,4.675-1.078,4.675s-2.644-2.749-2.875-3.844a2.023,2.023,0,1,1,3.955-.834Z"
          transform="translate(-196.485 -123.808)"
          fill="#28d8a1"
        />
        <path
          id="Path_582"
          data-name="Path 582"
          d="M266.48,174.5c-.352,1.058-3.292,3.492-3.292,3.492s-.894-3.708-.542-4.768a2.025,2.025,0,0,1,3.844,1.277Z"
          transform="translate(-225.843 -149.985)"
          fill="#28d8a1"
        />
        <path
          id="Path_583"
          data-name="Path 583"
          d="M287.194,209.987c-.168,1.1-2.654,4-2.654,4s-1.509-3.5-1.341-4.607a2.023,2.023,0,1,1,4,.607Z"
          transform="translate(-242.292 -178.486)"
          fill="#28d8a1"
        />
        <path
          id="Path_584"
          data-name="Path 584"
          d="M218.091,170.132c1.117-.012,4.329-2.072,4.329-2.072s-3.259-1.983-4.376-1.97a2.023,2.023,0,0,0,.047,4.046Z"
          transform="translate(-188.734 -145.404)"
          fill="#28d8a1"
        />
        <path
          id="Path_585"
          data-name="Path 585"
          d="M240.586,210.754c1.06-.346,3.51-3.271,3.51-3.271s-3.7-.916-4.764-.57a2.023,2.023,0,1,0,1.254,3.844Z"
          transform="translate(-206.14 -177.906)"
          fill="#28d8a1"
        />
        <path
          id="Path_586"
          data-name="Path 586"
          d="M271.656,246.06c1.1-.162,4.014-2.63,4.014-2.63s-3.5-1.529-4.6-1.368a2.023,2.023,0,1,0,.587,4Z"
          transform="translate(-231.18 -205.998)"
          fill="#28d8a1"
        />
        <circle
          id="Ellipse_69"
          data-name="Ellipse 69"
          cx="73.235"
          cy="73.235"
          r="73.235"
          transform="translate(32.363 4.537)"
          fill="url(#linear-gradient)"
        />
        <circle
          id="Ellipse_70"
          data-name="Ellipse 70"
          cx="72.022"
          cy="72.022"
          r="72.022"
          transform="translate(33.577 5.751)"
          fill="#28d8a1"
        />
        <circle
          id="Ellipse_71"
          data-name="Ellipse 71"
          cx="57.253"
          cy="57.253"
          r="57.253"
          transform="translate(48.345 20.52)"
          fill="#dde1ec"
        />
        <rect
          id="Rectangle_222"
          data-name="Rectangle 222"
          width="3.237"
          height="50.577"
          transform="translate(103.98 30.635)"
          fill="#535461"
          opacity="0.3"
        />
        <g
          id="Group_278"
          data-name="Group 278"
          transform="translate(74.88 61.421)"
          opacity="0.1"
        >
          <rect
            id="Rectangle_223"
            data-name="Rectangle 223"
            width="45.722"
            height="4.046"
            transform="translate(34.157 15.138)"
          />
          <rect
            id="Rectangle_224"
            data-name="Rectangle 224"
            width="4.046"
            height="32.474"
            transform="translate(0 3.584) rotate(-62.36)"
          />
          <circle
            id="Ellipse_72"
            data-name="Ellipse 72"
            cx="6.069"
            cy="6.069"
            r="6.069"
            transform="translate(24.649 11.092)"
          />
        </g>
        <rect
          id="Rectangle_225"
          data-name="Rectangle 225"
          width="45.722"
          height="4.046"
          transform="translate(109.038 75.75)"
          fill="#535461"
        />
        <rect
          id="Rectangle_226"
          data-name="Rectangle 226"
          width="32.474"
          height="4.046"
          transform="translate(76.771 60.616) rotate(27.64)"
          fill="#535461"
        />
        <circle
          id="Ellipse_73"
          data-name="Ellipse 73"
          cx="6.069"
          cy="6.069"
          r="6.069"
          transform="translate(99.529 71.704)"
          fill="#535461"
        />
        <g
          id="Group_279"
          data-name="Group 279"
          transform="translate(48.341 20.52)"
          opacity="0.1"
        >
          <path
            id="Path_587"
            data-name="Path 587"
            d="M324.451,229.4a57.257,57.257,0,0,1,103.007-34.425,57.259,57.259,0,1,0-95.953,61.971A56.992,56.992,0,0,1,324.451,229.4Z"
            transform="translate(-320 -165.27)"
          />
        </g>
        <path
          id="Path_611"
          data-name="Path 611"
          d="M809.424,313.09s-.738,1.756-.37,2.31"
          transform="translate(-661.692 -262.665)"
          opacity="0.05"
        />
        <path
          id="Path_612"
          data-name="Path 612"
          d="M665.58,310.35s.37,1.756,0,2.31"
          transform="translate(-547.325 -260.48)"
          opacity="0.05"
        />
        <path
          id="Path_613"
          data-name="Path 613"
          d="M997.576,508.14a20.21,20.21,0,0,0-3.686,21.024c4.778,12.559-8.189,33.446-8.189,33.446s.14-.02.4-.065a24.643,24.643,0,0,0,16.355-37.72C998.856,519.332,995.906,512.89,997.576,508.14Z"
          transform="translate(-802.683 -418.255)"
          fill="#28d8a1"
        />
        <path
          id="Path_614"
          data-name="Path 614"
          d="M997.576,508.84a20.21,20.21,0,0,0-3.686,21.024c4.778,12.559-8.189,33.444-8.189,33.444s.14-.02.4-.065a24.643,24.643,0,0,0,16.355-37.72C998.856,520.032,995.906,513.59,997.576,508.84Z"
          transform="translate(-802.683 -418.813)"
          opacity="0.1"
        />
        <path
          id="Path_615"
          data-name="Path 615"
          d="M997.567,508.14s-4.779,10.374,0,18.839.809,32.218-11.878,35.63"
          transform="translate(-802.674 -418.255)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_616"
          data-name="Path 616"
          d="M1004.116,676.943s-9.658-1.538-10.538,7.117S975,693.915,975,693.915l.332.221c14.607,9.589,28.572,6.108,26.466-6.626C1001.021,682.778,1001.086,678.024,1004.116,676.943Z"
          transform="translate(-794.147 -552.824)"
          fill="#28d8a1"
        />
        <path
          id="Path_617"
          data-name="Path 617"
          d="M1004.148,677.36s-7.5,1.645-6.878,8.463-11.127,14.989-22.23,8.511"
          transform="translate(-794.179 -553.241)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_618"
          data-name="Path 618"
          d="M959.162,733.359s-7.607-1.521-9.636-3.551-11.157-5.578-12.171-3.035-13.692,11.157-5.072,12.679,20.285,2.023,22.82,1.012S959.162,733.359,959.162,733.359Z"
          transform="translate(-757.69 -591.952)"
          fill="#a8a8a8"
        />
        <path
          id="Path_619"
          data-name="Path 619"
          d="M932.291,767.739c8.622,1.521,20.285,2.023,22.82,1.012,1.932-.773,3.273-4.483,3.8-6.211l.253.053s-1.521,6.085-4.056,7.1-14.2.508-22.82-1.012c-2.488-.439-3.2-1.471-2.931-2.776C929.57,766.756,930.438,767.412,932.291,767.739Z"
          transform="translate(-757.697 -621.188)"
          opacity="0.2"
        />
      </g>
    </svg>
    <div class="card-title">{{ title }}</div>
    <div class="card-body">{{ body }}</div>
    <div class="card-footer">
      <button class="btn-no-margin btn-secondary" (click)="closePrompt()">
        <div [class.loader]="loading">Close</div>
      </button>
    </div>
  </div>
</div>
