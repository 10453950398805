import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormService } from 'src/app/shared/services/form.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { TerminalService } from 'src/app/shared/services/terminal.service';
import { terminalFormNew } from '../../../../shared/models/form.model';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private groupService: GroupService,
    public formService: FormService,
    private terminalService: TerminalService
  ) {}

  public type = 'groups';
  public modal: boolean | any = false;
  public loading: boolean | string = false;

  public terminalForm: FormGroup;
  public formFields: any;

  private checkboxControlData = {};

  ngOnInit(): void {
    const { formGroup, formFields } = terminalFormNew;

    this.terminalForm = this.fb.group(formGroup);
    this.formFields = formFields;
  }

  public registerNewTerminal(): void {
    this.loading = 'newTerminal';
    const terminalName = this.terminalForm.get('terminalName').value;
    const data = { ...this.checkboxControlData, terminalName };

    this.terminalService.registerNewTerminal(data).subscribe((res) => {
      this.loading = false;
    });
  }

  private registerTerminal(id: string): void {
    this.loading = 'terminal';
    this.terminalService.registerTerminal(id).subscribe((res) => {
      this.loading = false;
    });
  }

  public openModal(formControlName: string): void {
    this.modal = { data: this.checkboxControlData, formControlName };
  }
  public selection({ data }): void {
    this.modal = false;

    const formControlName = Object.keys(data)[0];
    const formControlData = data[formControlName];
    const length = formControlData.length;
    this.checkboxControlData[formControlName] = formControlData;

    if (formControlName === 'terminal') {
      const id = formControlData.uid;
      return this.registerTerminal(id);
    }

    if (length > 1) {
      return this.terminalForm.patchValue({
        [formControlName]: `${formControlData[0].name} + ${length - 1} more`,
      });
    } else if (length) {
      this.terminalForm.patchValue({
        [formControlName]: formControlData[0].name,
      });
    } else {
      this.terminalForm.controls[formControlName].reset();
    }
  }
}
