import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, EMPTY, interval, timer } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  retryWhen,
  skip,
  skipWhile,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Terminal } from '../models/terminal.model';
import { AuthService } from './auth.service';
import { FingerprintService } from './fingerprint.service';
import { QrCodeService } from './qrcode.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class TerminalService {
  constructor(
    private fs: AngularFirestore,
    private fn: AngularFireFunctions,
    private authService: AuthService,
    private qrCodeService: QrCodeService,
    private fingerprintService: FingerprintService,
    private toastService: ToastService,
    private router: Router
  ) {
    if (location.hostname !== 'assetio.co.za') {
      fn.useFunctionsEmulator('http://172.16.5.204:5001');
    }
  }
  private terminalSub = new BehaviorSubject<Terminal>({
    state: 'offline',
  });
  public terminal$ = this.terminalSub.asObservable();

  private userQueueSub = new BehaviorSubject([]);
  public userQueue$ = this.userQueueSub.asObservable();

  private assetsSub = new BehaviorSubject([]);
  public assets$ = this.assetsSub.asObservable();

  private get collection(): string {
    return `organisations/${this.authService.orgId$.value}/terminals`;
  }

  private getTerminalConfig(
    terminalId: string,
    userGroups: string[],
    assetGroups: string[]
  ): void {
    this.getTerminal(terminalId);

    this.getUsersList(userGroups);
    this.getAssetList(assetGroups);

    this.getUserQueue(terminalId);
    this.fingerprintScannerHandler();
    this.timeoutHandler();
  }

  public registerNewTerminal(data: any): Observable<any> {
    return this.fn
      .httpsCallable('terminal-newTerminal')(data)
      .pipe(
        take(1),
        map(({ terminalId, userGroups, assetGroups }) => {
          if (terminalId) {
            this.getTerminalConfig(terminalId, userGroups, assetGroups);
          }
          return terminalId;
        })
      );
  }

  public registerTerminal(id: string): Observable<boolean | Error> {
    return this.fs
      .collection(this.collection)
      .doc(id)
      .get()
      .pipe(
        take(1),
        map((doc) => {
          if (doc.exists) {
            const { users, assets }: any = doc.data();
            this.getTerminalConfig(id, users, assets);
            return true;
          }
          return new Error(`No terminal with ${id} found`);
        })
      );
  }

  public addUserToQueue(terminalId: string, userId: string): Observable<any> {
    return this.fn
      .httpsCallable('terminal-addUserToQueue')({ terminalId, userId })
      .pipe(take(1));
  }

  private setTerminalSub({
    state,
    scanner,
    view,
    registration,
  }: Terminal | any): void {
    const terminalValue = this.terminalSub.value;
    this.terminalSub.next({
      ...terminalValue,
      state: state ? state : terminalValue.state,
      scanner: {
        ...terminalValue.scanner,
        ...scanner,
      },
      view: {
        ...terminalValue.view,
        ...view,
      },
      registration: {
        ...terminalValue.registration,
        ...registration,
      },
    });
  }

  private resetTerminalSub(): void {
    const terminalValue = this.terminalSub.value;
    this.fs
      .collection(`${this.collection}`)
      .doc(terminalValue.registration.terminalId)
      .update({ user: null });
    this.terminalSub.next({
      ...terminalValue,
      state: 'online',
      scanner: {
        ...terminalValue.scanner,
        asset: null,
      },
      view: {
        ...terminalValue.view,
        user: null,
        timer: 0,
      },
    });
  }

  private getTerminal(id: string): void {
    this.fs
      .collection(this.collection)
      .doc(id)
      .snapshotChanges()
      .subscribe((terminal) => {
        const terminalId = terminal.payload.id;
        const data: any = terminal.payload.data();
        const { qrAuthCode, name: terminalName, user } = data;

        const qrCode = qrAuthCode
          ? this.qrCodeService.generateSVGFromToken(
              `terminal=${terminalId}&authCode=${qrAuthCode}`,
              120
            )
          : undefined;

        this.setTerminalSub({
          state: user ? 'busy' : 'online',
          registration: {
            terminalId,
            terminalName,
          },
          view: {
            user,
            qrCode,
          },
        });
      });
  }

  private getUsersList(userGroups: Array<string>): void {
    this.fs
      .collection(
        `organisations/${this.authService.orgId$.value}/users`,
        (ref) =>
          ref
            .where('groups', 'array-contains-any', userGroups)
            .where('template', '>', '')
      )
      .snapshotChanges()
      .pipe(
        map((arr) => {
          return arr.reduce((acc, cur) => {
            const userId = cur.payload.doc.id;
            const userData: any = cur.payload.doc.data() || {};
            const data = { ...userData, userId };
            return [...acc, data];
          }, []);
        })
      )
      .subscribe((users) => {
        this.fingerprintService.usersSub.next(users);
      });
  }

  private getAssetList(assetGroups: Array<string>): void {
    this.fs
      .collection(
        `organisations/${this.authService.orgId$.value}/assets`,
        (ref) => ref.where('groups', 'array-contains-any', assetGroups)
      )
      .snapshotChanges()
      .pipe(
        map((arr) => {
          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc.id;
            const userData: any = cur.payload.doc.data() || {};
            return [...acc, { ...userData, id }];
          }, []);
        })
      )
      .subscribe((assets) => {
        this.assetsSub.next(assets);
      });
  }

  private getUserQueue(terminalId: string): void {
    this.fs
      .collection(`${this.collection}/${terminalId}/queue`, (ref) =>
        ref.orderBy('timestamp')
      )
      .snapshotChanges()
      .pipe(
        map((arr) => {
          if (!arr) {
            throw new Error('Terminal queue empty.');
          }

          const queue = arr.reduce((acc, cur) => {
            if (cur.payload.doc.metadata.fromCache) {
              return acc;
            }
            const userId = cur.payload.doc.id;
            const data: any = cur.payload.doc.data();
            return [...acc, { userId, ...data }];
          }, []);

          const newQueueItems = queue.filter((x) => {
            const currentQueue = this.userQueueSub.value.map((queueItem) => {
              return queueItem.userId;
            });
            return !currentQueue.includes(x.userId);
          });

          this.userQueueSub.next(queue);
          return newQueueItems;
        })
      )
      .subscribe((users: Array<any>) => {
        users.forEach((user) => {
          this.toastService.userAddedToQueue(user);
        });
      });
  }

  public getTerminalCollection(): Observable<any> {
    return this.fs
      .collection(this.collection, (ref) => ref.orderBy('name'))
      .snapshotChanges()
      .pipe(
        map((arr) => {
          if (!arr) {
            throw new Error(
              'Terminal collection empty. Add a terminal and try again.'
            );
          }
          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc.id;
            const data: any = cur.payload.doc.data();

            data.uid = id;

            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  public authUserWithQr(terminalId: string, authCode: string): Observable<any> {
    return this.fn
      .httpsCallable('terminal-authUser')({ terminalId, authCode })
      .pipe(take(1));
  }

  public terminalQrScan($event: string): void {
    const url = $event.replace('https://', '');
    let scanned = false;
    const {
      state,
      registration: { terminalId },
      view: { user },
      scanner: { asset },
    } = this.terminalSub.value;
    const { assets: assetId, users: userId } =
      this.router.parseUrl(url)?.queryParams;

    if (state === 'busy' && assetId && !asset) {
      const assets = this.assetsSub.value;
      const scanResult = assets?.find((a) => a.id === assetId);
      this.setTerminalSub({
        scanner: {
          asset: scanResult,
        },
      });
      this.fn
        .httpsCallable('terminal-assetHandler')({
          userId: user.userId,
          assetId,
        })
        .pipe(take(1))
        .subscribe(
          (res) => {
            this.toastService.terminalSuccess(res);
            this.resetTerminalSub();
          },
          ({ message }) => {
            this.toastService.terminalError(message);
            this.resetTerminalSub();
          }
        );
    }

    if (userId && !scanned) {
      scanned = true;
      this.addUserToQueue(terminalId, userId).subscribe(() => {
        scanned = false;
      });
    }
  }

  private fingerprintScannerHandler(): void {
    this.terminal$
      .pipe(
        distinctUntilChanged((prev, cur) => prev.state === cur.state),
        filter(({ state }) => state === 'online'),
        switchMap((terminal) =>
          this.userQueue$.pipe(
            skipWhile((queue) => !queue),
            debounceTime(5000),
            startWith([]),
            distinctUntilChanged(
              (prev, cur) => prev[0]?.userId === cur[0]?.userId
            ),
            switchMap((queue) => {
              const { state } = terminal;
              const queueLength = queue.length;

              if (state === 'online' && queueLength) {
                return this.queueHandler();
              }

              if (state === 'online') {
                return this.fingerprintAuthHandler();
              }
              return EMPTY;
            })
          )
        )
      )
      .subscribe();
  }

  private timeoutHandler(): void {
    const timeout = 15;
    this.terminal$
      .pipe(
        filter(
          ({ state, view, scanner }) =>
            state === 'busy' && (!view?.timer || !!scanner?.asset)
        ),
        switchMap(({ scanner }) => {
          if (!scanner.asset) {
            return timer(0, 1000).pipe(
              map((elapsed) => {
                const timeLeft = timeout - elapsed;
                if (timeLeft === 0) {
                  return this.resetTerminalSub();
                }
                this.setTerminalSub({ view: { timer: timeLeft } });
                return timeLeft;
              }),
              take(timeout + 1)
            );
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  private fingerprintAuthHandler(): Observable<any> {
    return this.fingerprintService.authUserWithFingerprint().pipe(
      retryWhen((error) =>
        error.pipe(
          tap((err) => {
            this.setTerminalSub({
              scanner: {
                hasError: true,
              },
            });
            this.toastService.terminalError(err);
          })
        )
      ),
      tap((res) => {
        const { valid, name, userId } = res;

        if (valid) {
          const firstName = name?.substr(0, name?.indexOf(' '));
          const user = {
            userId,
            name: firstName,
          };

          this.setTerminalSub({
            state: 'busy',
            view: {
              ...this.terminalSub.value.view,
              user,
            },
          });
        }
      })
    );
  }

  private queueHandler(): Observable<any> {
    return this.userQueue$.pipe(
      tap((queue) => {
        const {
          state,
          registration: { terminalId },
        } = this.terminalSub.value;
        const user = queue[0];
        if (state !== 'online' || !queue.length) {
          return;
        }
        this.fingerprintService
          .registerNewFingerprint(user.userId, terminalId)
          .subscribe(
            ({ step, valid }) => {
              this.setTerminalSub({
                state: 'link',
                view: {
                  user,
                },
                scanner: {
                  link: {
                    step,
                    valid,
                  },
                },
              });
            },
            () => {
              this.setTerminalSub({
                state: 'error',
                view: {
                  user,
                },
                scanner: {
                  hasError: true,
                  link: {
                    step: 0,
                    valid: false,
                  },
                },
              });
            },
            () => {
              this.setTerminalSub({
                state: 'online',
                view: {
                  user: undefined,
                },
                scanner: {
                  link: {
                    step: 0,
                    valid: false,
                  },
                },
              });
            }
          );
      })
    );
  }
}
