<main class="view-dashboard">
  <section>
    <app-navbar></app-navbar>
  </section>
  <section class="dashboard-container">
    <div class="dashboard-content">
      <div class="account-circle-container">
        <app-account-circle></app-account-circle>
      </div>
      <div class="breadcrumb">
        <app-breadcrumb header="Users" path="users"></app-breadcrumb>
      </div>
      <div class="dashboard-outlet">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</main>
