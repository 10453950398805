<div class="card-dashboard" (click)="$event.stopPropagation()">
  <form class="form">
    <app-checkbox-control
      [type]="modal"
      *ngIf="modal"
      (selection)="selection($event)"
    ></app-checkbox-control>
    <section class="form-group">
      <div class="form-qrcode">
        <svg viewBox="0 0 100 100">
          <path [attr.d]="svgData" />
        </svg>
      </div>
      <h5>
        {{
          formType == "users"
            ? "Present this QR Code to the nearest terminal to link or update the user's fingerprint."
            : "Scan this QR Code from any device to view more information."
        }}
      </h5>
      <div class="hr" *ngIf="formType == 'users'"><span>or</span></div>
      <button
        type="button"
        *ngIf="formType == 'users'"
        class="btn-no-margin btn-secondary"
        (click)="clickHandler()"
      >
        <div [class.loader]="loading">Choose terminal</div>
      </button>
    </section>
  </form>
</div>
