<div class="view-root">
  <section class="background">
    <svg id="test" viewBox="0 0 950.495 732.876">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.127"
          y1="1.017"
          x2="0.918"
          y2="-0.018"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="gray" stop-opacity="0.251" />
          <stop offset="0.54" stop-color="gray" stop-opacity="0.122" />
          <stop offset="1" stop-color="gray" stop-opacity="0.102" />
        </linearGradient>
        <filter
          id="Rectangle_216"
          x="133.091"
          y="173.091"
          width="323.818"
          height="323.818"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="22.5" result="blur" />
          <feFlood flood-opacity="0.02" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_187" data-name="Group 187" transform="translate(-203 -175)">
        <g id="Group_171" data-name="Group 171" transform="translate(54 -23)">
          <g
            id="undraw_sync4_xlc6"
            transform="translate(1101.589 928.876) rotate(180)"
          >
            <g
              id="Group_1"
              data-name="Group 1"
              transform="matrix(-0.848, -0.53, 0.53, -0.848, 162.656, 540.275)"
              opacity="0.3"
            >
              <path
                id="Path_1"
                data-name="Path 1"
                d="M0,0Q2.64,1.43,5.25,2.91"
                transform="translate(0 0)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
              />
              <path
                id="Path_2"
                data-name="Path 2"
                d="M0,0C105.72,64.33,176.33,180.62,176.33,313.4a367.175,367.175,0,0,1-11.5,91.44"
                transform="translate(15.47 8.86)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
                stroke-dasharray="11.85 11.85"
              />
              <path
                id="Path_3"
                data-name="Path 3"
                d="M1.63,0Q.84,2.89,0,5.77"
                transform="translate(177.17 419.43)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
              />
            </g>
            <path
              id="Path_4"
              data-name="Path 4"
              d="M243.54,0C243.54,159.28,141.93,294.83,0,345.4"
              transform="translate(590.549 366.5)"
              fill="none"
              stroke="#2196f3"
              stroke-miterlimit="10"
              stroke-width="4"
              stroke-dasharray="12"
              opacity="0.21"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M0,44.23A364.872,364.872,0,0,1,174.7,0C294.23,0,400.4,57.22,467.3,145.77"
              transform="translate(292.889 0)"
              fill="none"
              stroke="#2196f3"
              stroke-miterlimit="10"
              stroke-width="4"
              stroke-dasharray="12"
              opacity="0.5"
            />
            <g
              id="Group_2"
              data-name="Group 2"
              transform="translate(230.389 119.5)"
              opacity="0.21"
            >
              <path
                id="Path_6"
                data-name="Path 6"
                d="M0,5.74Q.84,2.86,1.75,0"
                transform="translate(0 172.14)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
              />
              <path
                id="Path_7"
                data-name="Path 7"
                d="M0,160.84C35,66.91,125.45,0,231.56,0c136.41,0,247,110.59,247,247s-110.59,247-247,247A245.921,245.921,0,0,1,81.78,443.4"
                transform="translate(5.64 0)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
                stroke-dasharray="11.95 11.95"
              />
              <path
                id="Path_8"
                data-name="Path 8"
                d="M4.63,3.81Q2.29,1.93,0,0"
                transform="translate(78.07 435.93)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
              />
            </g>
            <g
              id="Group_3"
              data-name="Group 3"
              transform="translate(327.588 288.26)"
              opacity="0.5"
            >
              <path
                id="Path_9"
                data-name="Path 9"
                d="M6,0Q3,.44,0,.76"
                transform="translate(154.98 216.64)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
              />
              <path
                id="Path_10"
                data-name="Path 10"
                d="M143.5,208.21H140A140,140,0,0,1,17.74,0"
                transform="translate(0 9.98)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
                stroke-dasharray="11.56 11.56"
              />
              <path
                id="Path_11"
                data-name="Path 11"
                d="M0,5Q1.57,2.44,3.25,0"
                transform="translate(20.66 0)"
                fill="none"
                stroke="#2196f3"
                stroke-miterlimit="10"
                stroke-width="4"
              />
            </g>
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="9.7"
              height="24.25"
              rx="1.5"
              transform="translate(117.597 153.539) rotate(-152.17)"
              fill="#f2f2f2"
            />
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="322.422"
              height="581.963"
              rx="12.25"
              transform="translate(927.978 605.155) rotate(157.05)"
              fill="url(#linear-gradient)"
            />
            <rect
              id="Rectangle_6"
              data-name="Rectangle 6"
              width="313.702"
              height="566.243"
              rx="13.64"
              transform="translate(920.904 599.604) rotate(157.05)"
              fill="#fff"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              d="M270.665,580.395a25.43,25.43,0,0,1,14.65-29.69l99.77-42.26a25.43,25.43,0,0,1,31.52,10.14l52.85-22.38a11.91,11.91,0,0,0,6.33-15.62L275.325,7.275A11.91,11.91,0,0,0,259.7.945L7.275,107.845a11.91,11.91,0,0,0-6.33,15.62l200.44,473.33a11.91,11.91,0,0,0,15.62,6.33Z"
              transform="translate(427.704 98.056)"
              fill="#28d8a1"
            />
            <rect
              id="Rectangle_7"
              data-name="Rectangle 7"
              width="69.91"
              height="4.37"
              rx="2"
              transform="translate(800.075 628) rotate(157.05)"
              fill="#dbdbdb"
            />
            <ellipse
              id="Ellipse_1"
              data-name="Ellipse 1"
              cx="2.62"
              cy="2.62"
              rx="2.62"
              ry="2.62"
              transform="translate(724.78 660.829) rotate(157.05)"
              fill="#dbdbdb"
            />
            <ellipse
              id="Ellipse_2"
              data-name="Ellipse 2"
              cx="14"
              cy="14"
              rx="14"
              ry="14"
              transform="translate(21.589 688.49)"
              fill="#80deea"
              opacity="0.3"
            />
            <ellipse
              id="Ellipse_3"
              data-name="Ellipse 3"
              cx="14"
              cy="14"
              rx="14"
              ry="14"
              transform="translate(166.089 369.9)"
              fill="#80deea"
              opacity="0.3"
            />
            <ellipse
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="14"
              cy="14"
              rx="14"
              ry="14"
              transform="translate(924.589 631.49)"
              fill="#80deea"
              opacity="0.5"
            />
            <ellipse
              id="Ellipse_5"
              data-name="Ellipse 5"
              cx="14"
              cy="14"
              rx="14"
              ry="14"
              transform="translate(910.589 674.49)"
              fill="#80deea"
              opacity="0.3"
            />
            <g
              id="Group_4"
              data-name="Group 4"
              transform="translate(629.359 98.056)"
              opacity="0.2"
            >
              <path
                id="Path_21"
                data-name="Path 21"
                d="M165.43,1.985,65.65,44.235A25.43,25.43,0,0,0,51,73.925L0,95.535l.76,1.79a11.91,11.91,0,0,0,15.62,6.33l53.65-22.72a25.43,25.43,0,0,1,14.65-29.69l99.75-42.26a25.08,25.08,0,0,1,8.41-1.94,25.42,25.42,0,0,0-27.41-5.06Z"
                transform="translate(0 499.46)"
                fill="#fff"
              />
              <path
                id="Path_22"
                data-name="Path 22"
                d="M199.7,473.595a11.91,11.91,0,0,1-6.33,15.62L147.82,508.5a25.32,25.32,0,0,1,11.71,10.09l52.85-22.38a11.91,11.91,0,0,0,6.33-15.62L18.25,7.275A11.91,11.91,0,0,0,2.63.945L0,2.055Z"
                transform="translate(56.42 0)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <g id="Group_186" data-name="Group 186" transform="translate(-3 -4)">
          <g
            transform="matrix(1, 0, 0, 1, 206, 179)"
            filter="url(#Rectangle_216)"
          >
            <rect
              id="Rectangle_216-2"
              data-name="Rectangle 216"
              width="144"
              height="144"
              rx="6"
              transform="translate(197.59 293.86) rotate(-23)"
              fill="#fff"
            />
          </g>
          <g id="barcode" transform="translate(429.713 482.941) rotate(-23)" *ngIf="!svgData">
            <path
              id="Path_37"
              data-name="Path 37"
              d="M444.158,16H433.737a1.737,1.737,0,1,0,0,3.474h8.684v8.684a1.737,1.737,0,1,0,3.474,0V17.737A1.737,1.737,0,0,0,444.158,16Z"
              transform="translate(-341.686 -16)"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M17.737,29.894a1.737,1.737,0,0,0,1.737-1.737V19.474h8.684a1.737,1.737,0,0,0,0-3.474H17.737A1.737,1.737,0,0,0,16,17.737V28.158A1.737,1.737,0,0,0,17.737,29.894Z"
              transform="translate(-16 -16)"
            />
            <path
              id="Path_39"
              data-name="Path 39"
              d="M444.158,432a1.737,1.737,0,0,0-1.737,1.737v8.684h-8.684a1.737,1.737,0,1,0,0,3.474h10.421a1.737,1.737,0,0,0,1.737-1.737V433.737A1.737,1.737,0,0,0,444.158,432Z"
              transform="translate(-341.686 -341.686)"
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M17.737,445.895H28.158a1.737,1.737,0,1,0,0-3.474H19.474v-8.684a1.737,1.737,0,1,0-3.474,0v10.421A1.737,1.737,0,0,0,17.737,445.895Z"
              transform="translate(-16 -341.686)"
            />
            <path
              id="Path_41"
              data-name="Path 41"
              d="M48,49.737V81a1.737,1.737,0,0,0,1.737,1.737H81A1.737,1.737,0,0,0,82.736,81V49.737A1.737,1.737,0,0,0,81,48H49.737A1.737,1.737,0,0,0,48,49.737Zm3.474,1.737H79.263V79.263H51.474Z"
              transform="translate(-41.053 -41.053)"
            />
            <path
              id="Path_42"
              data-name="Path 42"
              d="M99.1,80H81.737A1.737,1.737,0,0,0,80,81.737V99.1a1.737,1.737,0,0,0,1.737,1.737H99.1a1.737,1.737,0,0,0,1.737-1.737V81.737A1.737,1.737,0,0,0,99.1,80ZM97.368,97.368H83.474V83.474H97.368Z"
              transform="translate(-66.106 -66.106)"
            />
            <path
              id="Path_43"
              data-name="Path 43"
              d="M99.1,336H81.737A1.737,1.737,0,0,0,80,337.737V355.1a1.737,1.737,0,0,0,1.737,1.737H99.1a1.737,1.737,0,0,0,1.737-1.737V337.737A1.737,1.737,0,0,0,99.1,336Zm-1.737,17.368H83.474V339.474H97.368Z"
              transform="translate(-66.106 -266.528)"
            />
            <path
              id="Path_44"
              data-name="Path 44"
              d="M355.1,80H337.737A1.737,1.737,0,0,0,336,81.737V99.1a1.737,1.737,0,0,0,1.737,1.737H355.1a1.737,1.737,0,0,0,1.737-1.737V81.737A1.737,1.737,0,0,0,355.1,80Zm-1.737,17.368H339.474V83.474h13.894Z"
              transform="translate(-266.528 -66.106)"
            />
            <path
              id="Path_45"
              data-name="Path 45"
              d="M305.737,82.628H337a1.737,1.737,0,0,0,1.737-1.737V49.737A1.737,1.737,0,0,0,337,48H305.737A1.737,1.737,0,0,0,304,49.737V80.891A1.737,1.737,0,0,0,305.737,82.628Zm1.737-31.154h27.789v27.68H307.474Z"
              transform="translate(-241.475 -41.053)"
            />
            <path
              id="Path_46"
              data-name="Path 46"
              d="M48,337a1.737,1.737,0,0,0,1.737,1.737H81A1.737,1.737,0,0,0,82.736,337V305.737A1.737,1.737,0,0,0,81,304H49.737A1.737,1.737,0,0,0,48,305.737Zm3.474-29.526H79.263v27.789H51.474Z"
              transform="translate(-41.053 -241.475)"
            />
            <path
              id="Path_47"
              data-name="Path 47"
              d="M225.737,61.894a1.737,1.737,0,0,0,1.737-1.737V51.474h1.737a1.737,1.737,0,0,0,0-3.474h-3.474A1.737,1.737,0,0,0,224,49.737V60.158A1.737,1.737,0,0,0,225.737,61.894Z"
              transform="translate(-178.843 -41.053)"
            />
            <ellipse
              id="Ellipse_4-2"
              data-name="Ellipse 4"
              cx="1.858"
              cy="1.673"
              rx="1.858"
              ry="1.673"
              transform="translate(55.332 7.062)"
            />
            <path
              id="Path_48"
              data-name="Path 48"
              d="M257.737,86.947h3.474a1.737,1.737,0,0,0,1.737-1.737V81.737a1.737,1.737,0,1,0-3.474,0v1.737h-1.737a1.737,1.737,0,1,0,0,3.474Z"
              transform="translate(-203.896 -66.106)"
            />
            <path
              id="Path_49"
              data-name="Path 49"
              d="M261.21,136h-3.474A1.737,1.737,0,0,0,256,137.737v6.947a1.737,1.737,0,0,0,3.474,0v-5.21h1.737a1.737,1.737,0,0,0,0-3.474Z"
              transform="translate(-203.896 -109.948)"
            />
            <path
              id="Path_50"
              data-name="Path 50"
              d="M225.737,145.368h10.421a1.737,1.737,0,1,0,0-3.474h-8.684V129.737a1.737,1.737,0,0,0-3.474,0v13.894A1.737,1.737,0,0,0,225.737,145.368Z"
              transform="translate(-178.843 -103.685)"
            />
            <path
              id="Path_51"
              data-name="Path 51"
              d="M264.684,400a1.737,1.737,0,0,0-1.737,1.737v8.684h-5.21a1.737,1.737,0,0,0,0,3.474h6.947a1.737,1.737,0,0,0,1.737-1.737V401.737A1.737,1.737,0,0,0,264.684,400Z"
              transform="translate(-203.896 -316.633)"
            />
            <ellipse
              id="Ellipse_5-2"
              data-name="Ellipse 5"
              cx="1.858"
              cy="1.673"
              rx="1.858"
              ry="1.673"
              transform="translate(44.925 93.665)"
            />
            <path
              id="Path_52"
              data-name="Path 52"
              d="M225.737,406.947a1.737,1.737,0,0,0,1.737-1.737v-1.737h5.21a1.737,1.737,0,1,0,0-3.474h-6.947A1.737,1.737,0,0,0,224,401.737v3.474A1.737,1.737,0,0,0,225.737,406.947Z"
              transform="translate(-178.843 -316.633)"
            />
            <path
              id="Path_53"
              data-name="Path 53"
              d="M225.737,269.895a1.737,1.737,0,0,0,1.737-1.737v-8.684h3.474a1.737,1.737,0,0,0,0-3.474h-5.21A1.737,1.737,0,0,0,224,257.737v10.421A1.737,1.737,0,0,0,225.737,269.895Z"
              transform="translate(-178.843 -203.896)"
            />
            <path
              id="Path_54"
              data-name="Path 54"
              d="M225.737,227.474h10.421v8.684a1.737,1.737,0,1,0,3.474,0V225.737A1.737,1.737,0,0,0,237.895,224H225.737a1.737,1.737,0,0,0,0,3.474Z"
              transform="translate(-178.843 -178.843)"
            />
            <path
              id="Path_55"
              data-name="Path 55"
              d="M48,261.21a1.737,1.737,0,0,0,1.737,1.737H60.158a1.737,1.737,0,1,0,0-3.474H51.474v-1.737a1.737,1.737,0,1,0-3.474,0Z"
              transform="translate(-41.053 -203.896)"
            />
            <path
              id="Path_56"
              data-name="Path 56"
              d="M56.684,230.947a1.737,1.737,0,0,0,1.737-1.737v-3.474A1.737,1.737,0,0,0,56.684,224H49.737a1.737,1.737,0,0,0,0,3.474h5.21v1.737A1.737,1.737,0,0,0,56.684,230.947Z"
              transform="translate(-41.053 -178.843)"
            />
            <path
              id="Path_57"
              data-name="Path 57"
              d="M113.737,224A1.737,1.737,0,0,0,112,225.737v3.474a1.737,1.737,0,0,0,1.737,1.737h10.421a1.737,1.737,0,1,0,0-3.474h-8.684v-1.737A1.737,1.737,0,0,0,113.737,224Z"
              transform="translate(-91.158 -178.843)"
            />
            <path
              id="Path_58"
              data-name="Path 58"
              d="M145.368,225.737a1.737,1.737,0,1,0-3.474,0v8.684H129.737a1.737,1.737,0,1,0,0,3.474h13.894a1.737,1.737,0,0,0,1.737-1.737Z"
              transform="translate(-103.685 -178.843)"
            />
            <path
              id="Path_59"
              data-name="Path 59"
              d="M413.894,225.737A1.737,1.737,0,0,0,412.158,224H401.737a1.737,1.737,0,1,0,0,3.474h8.684v1.737a1.737,1.737,0,0,0,3.474,0Z"
              transform="translate(-316.633 -178.843)"
            />
            <circle
              id="Ellipse_6"
              data-name="Ellipse 6"
              cx="1.673"
              cy="1.673"
              r="1.673"
              transform="translate(64.253 62.443)"
            />
            <path
              id="Path_60"
              data-name="Path 60"
              d="M388.158,261.21v-3.474a1.737,1.737,0,1,0-3.474,0v1.737h-6.947a1.737,1.737,0,1,0,0,3.474h8.684A1.737,1.737,0,0,0,388.158,261.21Z"
              transform="translate(-297.844 -203.896)"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M392.684,368h-6.947a1.737,1.737,0,0,0,0,3.474h5.21v1.737a1.737,1.737,0,1,0,3.474,0v-3.474A1.737,1.737,0,0,0,392.684,368Z"
              transform="translate(-304.107 -291.58)"
            />
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="1.858"
              cy="1.858"
              r="1.858"
              transform="translate(86.554 86.603)"
            />
            <ellipse
              id="Ellipse_8"
              data-name="Ellipse 8"
              cx="1.673"
              cy="1.858"
              rx="1.673"
              ry="1.858"
              transform="translate(72.801 76.196)"
            />
            <path
              id="Path_62"
              data-name="Path 62"
              d="M360.684,403.474h-5.21v-1.737a1.737,1.737,0,1,0-3.474,0v3.474a1.737,1.737,0,0,0,1.737,1.737h6.947a1.737,1.737,0,0,0,0-3.474Z"
              transform="translate(-279.054 -316.633)"
            />
            <path
              id="Path_63"
              data-name="Path 63"
              d="M345.737,256A1.737,1.737,0,0,0,344,257.737v10.421a1.737,1.737,0,0,0,1.737,1.737H354.2a1.737,1.737,0,0,0,0-3.474h-6.73v-8.684A1.737,1.737,0,0,0,345.737,256Z"
              transform="translate(-272.791 -203.896)"
            />
            <path
              id="Path_64"
              data-name="Path 64"
              d="M325.894,224H313.737A1.737,1.737,0,0,0,312,225.737v10.421a1.737,1.737,0,1,0,3.474,0v-8.684h10.421a1.737,1.737,0,0,0,0-3.474Z"
              transform="translate(-247.738 -178.843)"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M225.737,346.421h13.894a1.737,1.737,0,0,0,1.737-1.737v-6.947A1.737,1.737,0,0,0,239.631,336H225.737A1.737,1.737,0,0,0,224,337.737v6.947A1.737,1.737,0,0,0,225.737,346.421Zm1.737-6.947h10.421v3.474H227.474Z"
              transform="translate(-178.843 -266.528)"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M321.737,363.789h27.789a1.737,1.737,0,0,0,1.737-1.737V337.737A1.737,1.737,0,0,0,349.526,336H321.737A1.737,1.737,0,0,0,320,337.737v24.315A1.737,1.737,0,0,0,321.737,363.789Zm1.737-24.315h24.315v20.842H323.474Z"
              transform="translate(-254.001 -266.528)"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M269.993,304h-3.257a1.737,1.737,0,0,0,0,3.474h3.257a1.737,1.737,0,0,0,0-3.474Z"
              transform="translate(-210.942 -241.475)"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M426.421,273.737a1.737,1.737,0,0,0-3.474,0v5.21h-5.21a1.737,1.737,0,0,0,0,3.474h6.947a1.737,1.737,0,0,0,1.737-1.737Z"
              transform="translate(-329.159 -216.422)"
            />
          </g>
          <g id="barcode" transform="translate(420 480) rotate(-23)" *ngIf="svgData">
            <path [attr.d]="svgData"></path>
          </g>
        </g>
      </g>
    </svg>
  </section>
  <section class="content">
    <div class="section">
      <div class="card-return" routerLink="/"></div>
      <div class="limit">
        <div class="card-alt card-code" *ngFor="let digit of code">
          <h1>{{ digit }}</h1>
        </div>
      </div>
    </div>
  </section>
</div>
