import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AssetService } from 'src/app/shared/services/asset.service';
import { FormService } from 'src/app/shared/services/form.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { QrCodeService } from 'src/app/shared/services/qrcode.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private qrService: QrCodeService,
    private userService: UserService,
    private assetService: AssetService,
    private groupService: GroupService,
    public formService: FormService,
    private route: ActivatedRoute
  ) {}

  public formType: string;
  public data: any;
  public svgData: any;
  public modal: any = false;
  public qrModal = false;
  public loading = false;
  public confirm = false;
  private checkboxControlData = {};
  private dirtyCheckboxControlData = false;
  private dirtyControls = {};

  public detailsForm!: FormGroup;
  public formFields: any;

  ngOnInit(): void {
    this.route.data.subscribe(({ type, data }) => {
      const { form, details } = data;
      const { groups, role, id } = details;
      this.svgData = this.qrService.generateSVGFromToken(`${type}=${id}`, 100);
      const group = groups?.length
        ? `Member of ${
            groups.length > 1 ? `${groups.length} groups` : `1 group`
          }`
        : null;

      if (groups?.length) {
        const map = groups.map((uid) => {
          return { uid };
        });
        this.checkboxControlData['groups'] = map;
      }

      if (role) {
        this.checkboxControlData['role'] = { name: role };
      }

      const patch =
        group !== null
          ? {
              ...details,
              groups: group,
            }
          : details;

      this.data = details;
      this.formType = type;
      this.formFields = form.formFields;

      this.detailsForm = this.fb.group(form.formGroup);
      this.detailsForm.patchValue(patch);
    });
  }

  public toggleQrModal(): void {
    this.qrModal = !this.qrModal;
  }

  public openModal(formControlName: string): void {
    this.modal = {
      data: this.checkboxControlData,
      formControlName,
      limitType: this.data.assetType ?? this.data.groupType ?? 'user',
    };
  }

  public selection({ data }): void {
    this.modal = false;
    this.dirtyCheckboxControlData = true;

    const formControlName = Object.keys(data)[0];
    const formControlData = data[formControlName];
    const length = formControlData.length;
    this.checkboxControlData[formControlName] = formControlData;

    if (length > 1) {
      return this.detailsForm.patchValue({
        [formControlName]: `${formControlData[0].name} + ${length - 1} more`,
      });
    } else {
      this.detailsForm.patchValue(
        formControlData[0]
          ? {
              [formControlName]: formControlData[0].name,
            }
          : { [formControlName]: formControlData.name }
      );
    }
    this.dirtyControls[formControlName] = formControlData;
  }

  public update(form: FormGroup): void {
    if (this.confirm) {
      this.confirm = false;
      return;
    }

    this.loading = true;

    Object.keys(form.controls).forEach((control) => {
      if (form.controls[control].dirty) {
        this.dirtyControls[control] = form.controls[control].value;
      }
    });

    if (this.dirtyCheckboxControlData) {
      this.dirtyControls = {
        ...this.dirtyControls,
        ...this.checkboxControlData,
      };
    }
    this.updatedItem(this.data.id, this.dirtyControls).subscribe(
      (res) => {
        this.loading = false;
        // console.log(res);
      },
      ({ code, message }) => {
        this.loading = false;
        // console.log(code, message);
      }
    );
  }

  private updatedItem(id: string, updatedData: any): Observable<any> {
    switch (this.formType) {
      case 'users':
        return this.userService.updateUser(id, updatedData);
      case 'assets':
        return this.assetService.updateAsset(id, updatedData);
      case 'groups':
        return this.groupService.updateGroup(id, updatedData);

      default:
        return null;
    }
  }

  public delete(): void {
    if (!this.confirm) {
      this.confirm = true;
    } else {
      this.loading = true;
      this.deleteItem(this.data.id);
    }
  }

  private deleteItem(id: string): void {
    switch (this.formType) {
      case 'users':
        return this.userService.deleteUser(id);
      case 'assets':
        return this.assetService.deleteAsset(id);
      case 'groups':
        return this.groupService.deleteGroup(id);
    }
  }

  public getStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'active';
      case 'disabled' || 'reporting issues':
        return 'disabled';

      default:
        return 'active';
    }
  }

  public modifiedTimeCalculator(): string {
    const diff =
      new Date().getTime() -
      new Date(this.data.lastModified.seconds * 1000).getTime();

    const days = Math.floor(diff / (60 * 60 * 24 * 1000));
    const hours = Math.floor(diff / (60 * 60 * 1000)) - days * 24;
    const minutes =
      Math.floor(diff / (60 * 1000)) - (days * 24 * 60 + hours * 60);
    const seconds =
      Math.floor(diff / 1000) -
      (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60);

    return `${
      days > 0
        ? `${days} days`
        : hours > 0
        ? `${hours} hours`
        : minutes > 0
        ? `${minutes} minutes`
        : `${seconds} seconds`
    } ago`;
  }
}
