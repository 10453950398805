import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackComponent } from 'src/app/views/asset/feedback/feedback.component';
import { LinkComponent } from 'src/app/views/asset/link/link.component';

const routes: Routes = [
  {
    path: 'feedback',
    component: FeedbackComponent,
  },
  {
    path: 'link',
    component: LinkComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssetRoutingModule {}
