<virtual-scroller #scroll [items]="data | async" (vsEnd)="fetchMore($event)">
  <table class="table-list">
    <thead #header>
      <tr class="table-list-header">
        <th>
          <span
            class="checkbox"
            [class.intermediate]="isIntermediate(scroll)"
            [class.checked]="isViewportItemsChecked(scroll)"
            (click)="masterToggle(scroll)"
          ></span>
        </th>
        <th *ngFor="let title of tableConfig?.headings">
          {{ title }}
        </th>
        <th>Status</th>
        <th>Created</th>
      </tr>
      <div
        class="table-loader"
        *ngIf="loading || !scroll.viewPortItems?.length"
      >
        <svg viewBox="0 0 24 24" fill="currentColor" color="#000000">
          <g>
            <path
              d="M10.998 22a.846.846 0 010-1.692 9.308 9.308 0 000-18.616 9.286 9.286 0 00-7.205 3.416.846.846 0 11-1.31-1.072A10.978 10.978 0 0110.998 0c6.075 0 11 4.925 11 11s-4.925 11-11 11z"
            ></path>
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="0 11 11"
              to="360 11 11"
              dur=".3s"
              calcMode="linear"
              repeatCount="indefinite"
            ></animateTransform>
          </g>
        </svg>
      </div>
    </thead>
    <tbody #container>
      <tr
        class="table-list-data"
        *ngFor="let data of scroll.viewPortItems"
        [routerLink]="data.uid"
      >
        <td>
          <span
            class="checkbox"
            (click)="toggleCheck(data); $event.stopPropagation()"
            [class.checked]="isSelected(data.uid)"
          ></span>
        </td>
        <td *ngFor="let attr of tableConfig?.dataAttr">
          {{ data[attr] | capitaliseFirstLetter }}
        </td>
        <td>{{ data.status }}</td>
        <td>
          {{ data.createdAt.seconds * 1000 | date }} -
          {{ data.createdBy }}
        </td>
      </tr>
    </tbody>
  </table>
</virtual-scroller>
