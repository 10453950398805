<div class="view-root">
  <section class="background">
    <svg viewBox="0 0 777.205 538.125">
      <g id="undraw_Post_re_mtr4" transform="translate(0 0)">
        <path
          id="Path_427"
          data-name="Path 427"
          d="M602.654,129.742H226.335a48.179,48.179,0,0,0-48.125,48.125V619.742a48.179,48.179,0,0,0,48.125,48.125H602.654a48.179,48.179,0,0,0,48.125-48.125V177.867A48.179,48.179,0,0,0,602.654,129.742Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_428"
          data-name="Path 428"
          d="M602.654,143.6H226.335a34.31,34.31,0,0,0-34.271,34.271V619.742a34.309,34.309,0,0,0,34.271,34.271H602.654a34.309,34.309,0,0,0,34.271-34.271V177.867A34.309,34.309,0,0,0,602.654,143.6Z"
          transform="translate(-178.21 -129.742)"
          fill="#fff"
        />
        <path
          id="Path_429"
          data-name="Path 429"
          d="M546.018,272.081h-190.3a8.014,8.014,0,1,1,0-16.028h190.3a8.014,8.014,0,0,1,0,16.028Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_430"
          data-name="Path 430"
          d="M579.076,299.129H355.716a8.014,8.014,0,1,1,0-16.028h223.36a8.014,8.014,0,0,1,0,16.028Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_431"
          data-name="Path 431"
          d="M546.018,393.3h-190.3a8.014,8.014,0,0,1,0-16.028h190.3a8.014,8.014,0,0,1,0,16.028Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_432"
          data-name="Path 432"
          d="M579.076,420.343H355.716a8.014,8.014,0,1,1,0-16.028h223.36a8.014,8.014,0,0,1,0,16.028Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_433"
          data-name="Path 433"
          d="M546.018,514.508h-190.3a8.014,8.014,0,0,1,0-16.028h190.3a8.014,8.014,0,1,1,0,16.028Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_434"
          data-name="Path 434"
          d="M579.076,541.556H355.716a8.014,8.014,0,1,1,0-16.028h223.36a8.014,8.014,0,0,1,0,16.028Z"
          transform="translate(-178.21 -129.742)"
          fill="#f2f2f2"
        />
        <path
          id="Path_435"
          data-name="Path 435"
          d="M313.087,311.408H245.742a3.847,3.847,0,0,1-3.843-3.843V247.617a3.847,3.847,0,0,1,3.843-3.843h67.346a3.847,3.847,0,0,1,3.843,3.843v59.947A3.847,3.847,0,0,1,313.087,311.408Z"
          transform="translate(-178.21 -129.742)"
          fill="#28d8a1"
        />
        <path
          id="Path_436"
          data-name="Path 436"
          d="M313.087,432.621H245.742a3.847,3.847,0,0,1-3.843-3.843V368.831a3.847,3.847,0,0,1,3.843-3.843h67.346a3.847,3.847,0,0,1,3.843,3.843v59.947A3.847,3.847,0,0,1,313.087,432.621Z"
          transform="translate(-178.21 -129.742)"
          fill="#ff6767"
        />
        <path
          id="Path_437"
          data-name="Path 437"
          d="M313.087,553.834H245.742a3.847,3.847,0,0,1-3.843-3.843V490.044a3.847,3.847,0,0,1,3.843-3.843h67.346a3.847,3.847,0,0,1,3.843,3.843v59.948A3.847,3.847,0,0,1,313.087,553.834Z"
          transform="translate(-178.21 -129.742)"
          fill="#28d8a1"
        />
        <path
          id="Path_438"
          data-name="Path 438"
          d="M934.165,547.652l2.985-26.109,14.969-130.908,2.985-26.109a48.179,48.179,0,0,0-42.346-53.281L573.1,272.406a48.179,48.179,0,0,0-53.281,42.346l-.008.067L498.884,497.81l-.008.067a48.179,48.179,0,0,0,42.346,53.281L880.884,590a48.179,48.179,0,0,0,53.281-42.346Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_439"
          data-name="Path 439"
          d="M920.4,546.079l3.536-30.924L937.8,393.877l3.536-30.923a34.309,34.309,0,0,0-30.156-37.943l-339.662-38.84a34.31,34.31,0,0,0-37.943,30.156l-.008.067-20.925,182.99-.008.068A34.309,34.309,0,0,0,542.8,537.394l339.662,38.84A34.309,34.309,0,0,0,920.4,546.079Z"
          transform="translate(-178.21 -129.742)"
          fill="#fff"
        />
        <path
          id="Path_440"
          data-name="Path 440"
          d="M864.8,380.158l-189.07-21.62a8.014,8.014,0,0,1,1.821-15.924l189.07,21.62a8.014,8.014,0,1,1-1.821,15.924Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_441"
          data-name="Path 441"
          d="M894.575,410.786,672.661,385.411a8.014,8.014,0,0,1,1.821-15.924L896.4,394.862a8.014,8.014,0,1,1-1.821,15.924Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_442"
          data-name="Path 442"
          d="M888.388,464.383l-327.17-37.412a8.014,8.014,0,1,1,1.821-15.924l327.17,37.411a8.014,8.014,0,1,1-1.821,15.924Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_443"
          data-name="Path 443"
          d="M886.366,491.375,558.145,453.844a8.014,8.014,0,0,1,1.821-15.925l328.221,37.532a8.014,8.014,0,0,1-1.821,15.924Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_444"
          data-name="Path 444"
          d="M882.366,517.039,555.2,479.628a8.014,8.014,0,1,1,1.821-15.924l327.169,37.411a8.014,8.014,0,1,1-1.821,15.924Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_445"
          data-name="Path 445"
          d="M880.345,544.032,552.124,506.5a8.014,8.014,0,0,1,1.821-15.924l328.221,37.531a8.014,8.014,0,0,1-1.821,15.924Z"
          transform="translate(-178.21 -129.742)"
          fill="#e6e6e6"
        />
        <path
          id="Path_446"
          data-name="Path 446"
          d="M628.913,392.767,562,385.116a3.847,3.847,0,0,1-3.381-4.254l6.81-59.559a3.847,3.847,0,0,1,4.254-3.381l66.91,7.651a3.847,3.847,0,0,1,3.381,4.254l-6.811,59.559A3.847,3.847,0,0,1,628.913,392.767Z"
          transform="translate(-178.21 -129.742)"
          fill="#28d8a1"
        />
      </g>
    </svg>
  </section>
  <section class="content">
    <div class="card-alt card-feedback">
      <svg viewBox="0 0 91.938 91.938">
        <path
          id="Path_449"
          data-name="Path 449"
          d="M0,0H91.938V91.938H0Z"
          fill="none"
        />
        <circle
          id="Ellipse_63"
          data-name="Ellipse 63"
          cx="5.5"
          cy="5.5"
          r="5.5"
          transform="translate(53.969 30.969)"
          fill="#28d8a1"
        />
        <circle
          id="Ellipse_64"
          data-name="Ellipse 64"
          cx="5.5"
          cy="5.5"
          r="5.5"
          transform="translate(26.969 30.969)"
          fill="#28d8a1"
        />
        <path
          id="Path_450"
          data-name="Path 450"
          d="M40.307,55.63a15.311,15.311,0,0,1-13.216-7.661h-6.4a21.05,21.05,0,0,0,39.227,0h-6.4A15.311,15.311,0,0,1,40.307,55.63ZM40.269,2A38.308,38.308,0,1,0,78.615,40.307,38.289,38.289,0,0,0,40.269,2Zm.038,68.953A30.646,30.646,0,1,1,70.953,40.307,30.638,30.638,0,0,1,40.307,70.953Z"
          transform="translate(5.661 5.661)"
          fill="#28d8a1"
        />
      </svg>
      <span
        >This device is performing as expected and does not have any
        issues.</span
      >
    </div>
    <div class="card-alt card-feedback">
      <svg viewBox="0 0 59.478 59.478">
        <path
          id="Path_449"
          data-name="Path 449"
          d="M0,0H59.478V59.478H0Z"
          fill="none"
        />
        <circle
          id="Ellipse_63"
          data-name="Ellipse 63"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(35.073 20.109)"
          fill="#e5a23d"
        />
        <circle
          id="Ellipse_64"
          data-name="Ellipse 64"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(17.073 20.109)"
          fill="#e5a23d"
        />
        <path
          id="Path_450"
          data-name="Path 450"
          d="M26.758,2A24.783,24.783,0,1,0,51.565,26.783,24.771,24.771,0,0,0,26.758,2Zm.025,44.609A19.826,19.826,0,1,1,46.609,26.783,19.821,19.821,0,0,1,26.783,46.609Z"
          transform="translate(2.957 2.957)"
          fill="#e5a23d"
        />
        <rect
          id="Rectangle_217"
          data-name="Rectangle 217"
          width="3.882"
          height="23.937"
          transform="translate(41.708 34.784) rotate(90)"
          fill="#e5a23d"
        />
      </svg>
      <span>This device is performing as expected but has some issues.</span>
    </div>
    <div class="card-alt card-feedback">
      <svg viewBox="0 0 59.478 59.478">
        <path
          id="Path_447"
          data-name="Path 447"
          d="M0,0H59.478V59.478H0Z"
          fill="none"
        />
        <circle
          id="Ellipse_61"
          data-name="Ellipse 61"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(35.073 19.477)"
          fill="#ff6767"
        />
        <circle
          id="Ellipse_62"
          data-name="Ellipse 62"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(17.073 19.477)"
          fill="#ff6767"
        />
        <path
          id="Path_448"
          data-name="Path 448"
          d="M26.783,31.739a13.618,13.618,0,0,0-12.689,8.674h4.139a9.853,9.853,0,0,1,17.1,0h4.139a13.618,13.618,0,0,0-12.689-8.674ZM26.758,2A24.783,24.783,0,1,0,51.565,26.783,24.771,24.771,0,0,0,26.758,2Zm.025,44.609A19.826,19.826,0,1,1,46.609,26.783,19.821,19.821,0,0,1,26.783,46.609Z"
          transform="translate(2.957 2.957)"
          fill="#ff6767"
        />
      </svg>
      <span>This device is not performing as expected and has issues.</span>
    </div>
  </section>
</div>
