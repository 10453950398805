<virtual-scroller #scroll [items]="data | async" (vsEnd)="fetchMore($event)">
  <div
    class="card-log"
    *ngFor="let log of scroll.viewPortItems"
    (click)="logData(log)"
  >
    <div class="log-detail">
      <h4 class="log-title">{{ log.type }}</h4>
      <p class="log-data" *ngFor="let logItem of log.data">
        {{ logItem }}
      </p>
      <div class="updates" *ngIf="log.updates">
        <p class="log-data" *ngFor="let logItem of log.updates">
          {{ logItem }}
        </p>
      </div>
      <p class="log-data" [ngClass]="log.extra?.type" *ngIf="log.extra?.detail">
        {{ log.extra?.detail }}
      </p>
      <p class="log-data" *ngIf="log.duration">
        {{ log.duration }}
      </p>
      <p class="log-data timestamp">
        {{ log.timestamp.seconds * 1000 | date: "medium" }}
      </p>
    </div>
    <div class="log-action">
      <button class="btn-log btn-secondary" type="button">
        <div [class.loader]="loading">
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            color="#fff"
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
            ></path>
          </svg>
          <span>Return asset</span>
        </div>
      </button>
    </div>
  </div>
</virtual-scroller>
