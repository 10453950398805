<div class="card card-form">
  <div class="card-title">Sign in</div>
  <div class="card-body">
    <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div
        class="validation-container"
        [class.invalid]="
          formService?.inputValidation(loginForm, {
            formControlName: 'email'
          })
        "
      >
        <input
          class="form-input"
          type="email"
          placeholder="Email address"
          formControlName="email"
        />
        <p class="form-error-text">
          {{
            formService?.inputValidationError(loginForm, {
              formControlName: "email",
              placeholder: "Email address"
            })
          }}
        </p>
      </div>
      <div
        class="validation-container"
        [class.invalid]="
          formService?.inputValidation(loginForm, {
            formControlName: 'password'
          })
        "
      >
        <input
          class="form-input"
          type="password"
          placeholder="Password"
          formControlName="password"
        />
        <p class="form-error-text">
          {{
            formService?.inputValidationError(loginForm, {
              formControlName: "password",
              placeholder: "Password"
            })
          }}
        </p>
      </div>

      <button class="btn btn-secondary">
        <div [class.loader]="loading">Sign in</div>
      </button>
      <p class="signin-error" *ngIf="errorMessage">
        {{ errorMessage }}
      </p>
    </form>
  </div>
  <div class="card-footer">
    <a class="link link-light" routerLink="../forgot-password"
      >Forgot your password?</a
    >
    <div class="hr"><span>or continue with</span></div>
    <div class="providers">
      <ul>
        <li (click)="authService?.googleSignIn()">
          <svg id="google" viewBox="0 0 40.006 40.006">
            <path
              id="Path_35"
              data-name="Path 35"
              d="M9.376,112.388a10.561,10.561,0,0,1,1.495-5.429v-6.744H4.127a20.013,20.013,0,0,0,0,24.345h6.744v-6.744A10.561,10.561,0,0,1,9.376,112.388Z"
              transform="translate(0 -92.384)"
              fill="#fbbd00"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M200.688,374.449,196,379.137l4.688,4.688a19.851,19.851,0,0,0,12.173-4.127v-6.737h-6.737A10.625,10.625,0,0,1,200.688,374.449Z"
              transform="translate(-180.685 -343.819)"
              fill="#0f9d58"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M59.567,325.477l-6.744,6.744a20.324,20.324,0,0,0,1.731,1.972A19.872,19.872,0,0,0,68.7,340.051v-9.376A10.631,10.631,0,0,1,59.567,325.477Z"
              transform="translate(-48.696 -300.045)"
              fill="#31aa52"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M276,201.909a20.178,20.178,0,0,0-.328-3.624l-.176-.961H256V206.7h9.49a10.579,10.579,0,0,1-4.054,4.347l6.737,6.737a20.32,20.32,0,0,0,1.972-1.731A19.872,19.872,0,0,0,276,201.909Z"
              transform="translate(-235.997 -181.906)"
              fill="#3c79e6"
            />
            <path
              id="Path_39"
              data-name="Path 39"
              d="M208.2,12.489l.829.829,6.63-6.63-.829-.829A19.872,19.872,0,0,0,200.688,0L196,4.688l4.688,4.688A10.557,10.557,0,0,1,208.2,12.489Z"
              transform="translate(-180.685)"
              fill="#cf2d48"
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M68.7,9.376V0A19.873,19.873,0,0,0,54.553,5.859a20.3,20.3,0,0,0-1.731,1.972l6.744,6.744a10.631,10.631,0,0,1,9.132-5.2Z"
              transform="translate(-48.695 0)"
              fill="#eb4132"
            />
          </svg>
          <span>Google</span>
        </li>
        <li disabled>
          <svg viewBox="0 0 37.072 45.197">
            <g id="apple" transform="translate(-46.022)">
              <path
                id="Path_41"
                data-name="Path 41"
                d="M58.313,152.742c-6.727-.039-12.291-13.77-12.291-20.763,0-11.423,8.569-13.924,11.872-13.924a13.661,13.661,0,0,1,4.479,1.1,10.013,10.013,0,0,0,2.558.733,7.96,7.96,0,0,0,1.835-.595,14.706,14.706,0,0,1,5.531-1.337h.013a11.336,11.336,0,0,1,9.486,4.791l.692,1.04-1,.751c-1.422,1.073-4.018,3.031-4.018,6.909a7.8,7.8,0,0,0,4.351,7.209c.623.375,1.269.762,1.269,1.607,0,.552-4.407,12.409-10.806,12.409a8.691,8.691,0,0,1-3.649-.886,7.4,7.4,0,0,0-3.248-.782,8.355,8.355,0,0,0-2.572.7,12.6,12.6,0,0,1-4.461,1.041Z"
                transform="translate(0 -107.545)"
              />
              <path
                id="Path_42"
                data-name="Path 42"
                d="M263.645,0c.167,6.009-4.131,10.179-8.424,9.917C254.514,5.121,259.514,0,263.645,0Z"
                transform="translate(-190.671)"
              />
            </g>
          </svg>
          <span>Apple</span>
        </li>
        <li>
          <svg id="microsoft" viewBox="0 0 40.006 40.006">
            <path
              id="Path_43"
              data-name="Path 43"
              d="M272,18.753h18.753V1.25A1.251,1.251,0,0,0,289.5,0H272Z"
              transform="translate(-250.747)"
              fill="#4caf50"
            />
            <path
              id="Path_44"
              data-name="Path 44"
              d="M18.753,18.753V0H1.25A1.251,1.251,0,0,0,0,1.25v17.5Z"
              fill="#f44336"
            />
            <path
              id="Path_45"
              data-name="Path 45"
              d="M18.753,272H0v17.5a1.251,1.251,0,0,0,1.25,1.25h17.5Z"
              transform="translate(0 -250.747)"
              fill="#2196f3"
            />
            <path
              id="Path_46"
              data-name="Path 46"
              d="M272,272v18.753h17.5a1.251,1.251,0,0,0,1.25-1.25V272Z"
              transform="translate(-250.747 -250.747)"
              fill="#ffc107"
            />
          </svg>
          <span>Microsoft</span>
        </li>
      </ul>
    </div>
  </div>
</div>
