import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetService } from 'src/app/shared/services/asset.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { TerminalService } from 'src/app/shared/services/terminal.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
})
export class CheckboxControlComponent implements OnInit {
  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private assetService: AssetService,
    private terminalService: TerminalService
  ) {}
  @Input() type: {
    data: any;
    formControlName: string;
    limitType: string | undefined;
    section: string | undefined;
  };
  @Output() selection = new EventEmitter<any>();

  public tempSelection = [];
  private initialSelection = [];

  private role: Observable<Array<any>> = of([
    { name: 'Domain administrator' },
    { name: 'Administrator' },
    { name: 'Guest ambassador' },
    { name: 'Guest' },
  ]);

  private assetTypes: Observable<Array<any>> = of([
    { name: 'Device' },
    { name: 'Accessory' },
    { name: 'Book' },
  ]);

  private groupTypes: Observable<Array<any>> = of([
    { name: 'User' },
    { name: 'Device' },
    { name: 'Accessory' },
    { name: 'Book' },
  ]);

  private groups: Observable<Array<any>>;
  private users: Observable<Array<any>>;
  private assets: Observable<Array<any>>;
  private terminals: Observable<Array<any>>;

  private removeUnavailable(v: any): Array<any> {
    this.tempSelection.forEach((group) => {
      const index = Object.values(v).findIndex((g: any) => {
        return group.uid === g.uid;
      });
      if (index === -1) {
        this.tempSelection.splice(index, 1);
      }
    });
    return Object.values(v);
  }

  ngOnInit(): void {
    // console.log(this.type);
    const data = this.type.data[this.type.formControlName];
    if (data) {
      if (this.isRadio()) {
        this.tempSelection[0] = data;
        return (this.initialSelection[0] = { ...data });
      }
      this.tempSelection = data;
      this.initialSelection = [...data];
    }

    this.groups = this.groupService
      .getGroupCollection(this.type.limitType)
      .pipe(map((v) => this.removeUnavailable(v)));

    this.terminals = this.terminalService
      .getTerminalCollection()
      .pipe(map((v) => this.removeUnavailable(v)));

    this.users = this.userService
      .getUserCollection()
      .pipe(map((v) => this.removeUnavailable(v)));

    this.assets = this.assetService
      .getAssetCollection(this.type.limitType)
      .pipe(map((v) => this.removeUnavailable(v)));
  }

  public get data(): Observable<any> {
    switch (this.type.formControlName) {
      case 'groups':
        return this.groups;

      case 'usersAssets':
        if (this.type.limitType === 'user') {
          return this.users;
        }
        return this.assets;

      case 'users':
        return this.users;

      case 'assets':
        return this.assets;

      case 'role':
        return this.role;

      case 'assetType':
        return this.assetTypes;

      case 'groupType':
        return this.groupTypes;

      case 'terminal':
        return this.terminals;

      default:
        return null;
    }
  }

  public inSelection(controlData: any): number {
    return this.tempSelection.findIndex((x, i) => {
      if (x.uid) {
        if (x.uid === controlData.uid) {
          this.tempSelection[i] = controlData;
          return true;
        }
        return false;
      } else {
        return x.name === controlData.name;
      }
    });
  }

  public select(controlData: any): void {
    if (this.isRadio()) {
      this.tempSelection[0] = controlData;
    } else {
      const index = this.inSelection(controlData);

      if (index === -1) {
        this.tempSelection.push(controlData);
      } else {
        this.tempSelection.splice(index, 1);
      }
    }
  }

  public confirmSelection(): void {
    const { section, formControlName } = this.type;
    const data = this.isRadio()
      ? { [formControlName]: this.tempSelection[0] }
      : { [formControlName]: this.tempSelection };
    return this.selection.emit(
      section
        ? {
            section,
            data,
          }
        : { data }
    );
  }

  public isRadio(): boolean {
    const { formControlName } = this.type;
    if (
      formControlName === 'role' ||
      formControlName === 'assetType' ||
      formControlName === 'groupType' ||
      formControlName === 'terminal'
    ) {
      return true;
    }
    return false;
  }

  public get buttonText(): string {
    function selectionMap(selection: Array<any>): Array<any> {
      return (
        selection?.map((item: any) => {
          if (item.uid) {
            return item.uid;
          }
          return item.name;
        }) ?? []
      );
    }
    const initial = selectionMap(this.initialSelection);
    const current = selectionMap(this.tempSelection);
    const changed = !isEqual(initial, current);
    if (changed) {
      return `Confirm selection`;
    }
    return 'Close';
  }
}
