<div class="container">
  <div class="card-container">
    <router-outlet></router-outlet>
  </div>
  <div class="shadow">
    <svg viewBox="0 0 717.593 135.086">
      <path
        id="Path_423"
        data-name="Path 423"
        d="M515.159,565.58c12.364-.933,24.715,1.332,36.52,3.824C622.561,584.06,695.068,605,768.854,597.21c39.158-4.144,75.945-16.295,115.623-16.961,67.231-1.146,128.48,30.644,195.378,26.434,13.67-.866,30.365-2.518,38.639,4.2,9.593,7.834-.839,21.4,11.458,27.673,3.664,1.865,8.834,2.665,11.565,5.036,4.21,3.677.16,8.874-5.329,11.858-33.309,18.014-89.268-8.727-129.08,3.424-8.834,2.665-15.988,7.115-24.476,10.139-12.085,4.29-26.328,5.556-40.171,6.742l-211.846,18.2c-50.723,4.357-102.1,8.714-153.009,5.236s-102.139-15.7-135.422-39.5c-7.155-5.116-13.564-10.939-15.415-17.574C418.043,610.813,462.477,569.577,515.159,565.58Z"
        transform="translate(-425.669 -565.365)"
        fill="#252223"
        opacity="0.1"
      />
    </svg>
  </div>
</div>
