import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from 'src/app/shared/services/asset.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { QrCodeService } from 'src/app/shared/services/qrcode.service';
import { FilterService } from 'src/app/shared/services/filter.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  constructor(
    public deviceService: DeviceService,
    private userService: UserService,
    private assetService: AssetService,
    private groupService: GroupService,
    public dashboardService: DashboardService,
    private qrCodeService: QrCodeService,
    public filterService: FilterService,
    private route: ActivatedRoute
  ) {}
  public headerType: string;
  public selection: any[] = [];
  public confirmDeletion = false;
  public deleting = false;

  private selectionSubscription: Subscription;

  ngOnInit(): void {
    this.filterService.setFilter('');
    this.selectionSubscription = this.dashboardService.selection.subscribe(
      (ids) => {
        this.selection = ids;
      }
    );

    this.route.data.subscribe(({ type }) => {
      this.headerType = type;
    });
  }

  ngOnDestroy(): void {
    this.dashboardService.selection.next([]);
    this.selectionSubscription.unsubscribe();
  }

  public bulkDelete(): void {
    if (this.confirmDeletion) {
      this.deleting = true;
      const ids = this.selection.map((data) => data.uid);
      this.deleteItems(ids).subscribe((res) => {
        this.dashboardService.selection.next([]);
        this.confirmDeletion = false;
        this.deleting = false;
      });
    } else {
      this.confirmDeletion = true;
    }
  }

  public bulkExport(): void {
    this.qrCodeService.generatePDF(this.headerType);
  }

  private deleteItems(ids: Array<string>): Observable<any> {
    switch (this.headerType) {
      case 'users':
        return this.userService.deleteUsers(ids);
      case 'assets':
        return this.assetService.deleteAssets(ids);
      case 'groups':
        return this.groupService.deleteGroups(ids);
      default:
        return null;
    }
  }
}
