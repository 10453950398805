import { Component, Input, OnInit } from '@angular/core';
import { Scanner } from 'src/app/shared/models/terminal.model';
import { User } from 'src/app/shared/models/user.model';
import { TerminalService } from 'src/app/shared/services/terminal.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  constructor(public terminalService: TerminalService) {}

  @Input() user: User;
  @Input() scanner: Scanner;
  @Input() state: string;

  ngOnInit(): void {}

  public complete(position: number): boolean {
    const {
      link: { step },
    } = this.scanner;

    if (step > position) {
      return true;
    }
    return false;
  }

  public get getSection(): number {
    if (!this.user) {
      return 0;
    }
    if (this.state === 'busy') {
      return 1;
    }
    return 2;
  }

  public get assetText(): string {
    const asset = this.scanner?.asset;
    if (asset) {
      if (asset.user) {
        return `${asset.displayName} has been scanned for return.`;
      }
      return `${asset.displayName} has been scanned for sign out.`;
    }
    return 'Please present the QR Code found on the asset.';
  }

  public get assetClass(): string {
    const asset = this.scanner?.asset;
    if (asset) {
      if (asset.user) {
        return 'asset-return';
      }
      return 'asset-sign-out';
    }
    return '';
  }

  public get instruction(): string {
    const {
      link: { step },
    } = this.scanner;

    if (step === 3) {
      return `Please verify that we captured your fingerprint correctly.`;
    }
    return `Please place your right index finger on the reader.`;
  }

  public get hasError(): boolean {
    return this.scanner?.hasError || false;
  }
}
