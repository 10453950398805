<section class="checkbox-modal" *ngIf="type">
  <div class="checbox-modal-loader" *ngIf="!(data | async)"></div>
  <ul class="selection">
    <li *ngFor="let controlData of data | async" (click)="select(controlData)">
      {{ controlData.name | capitaliseFirstLetter }}
      <div
        class="control"
        [class.radio]="isRadio()"
        [class.selected]="inSelection(controlData) > -1"
      ></div>
    </li>
  </ul>
  <button
    type="button"
    class="btn-no-margin btn-secondary"
    (click)="confirmSelection()"
  >
    {{ buttonText }}
  </button>
</section>
