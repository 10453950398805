import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QrComponent } from 'src/app/shared/components/dashboard/qr/qr.component';
import { UserDetailsComponent } from 'src/app/shared/components/dashboard/user-details/user-details.component';
import { UsersComponent } from 'src/app/shared/components/dashboard/users/users.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { DetailsResolver } from 'src/app/shared/resolvers/details.resolver';
import { ListResolver } from 'src/app/shared/resolvers/list.resolver';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'overview' },
      { path: 'overview', component: UsersComponent },
      {
        path: 'qr',
        component: QrComponent,
      },
      {
        path: 'users',
        data: { type: 'users' },
        children: [
          {
            path: '',
            component: UsersComponent,
            resolve: { data: ListResolver },
          },
          {
            path: ':id',
            component: UserDetailsComponent,
            resolve: { data: DetailsResolver },
          },
        ],
      },
      {
        path: 'assets',
        data: { type: 'assets' },
        children: [
          {
            path: '',
            component: UsersComponent,
            resolve: { data: ListResolver },
          },
          {
            path: ':id',
            component: UserDetailsComponent,
            resolve: { data: DetailsResolver },
          },
        ],
      },
      {
        path: 'groups',
        data: { type: 'groups' },
        children: [
          {
            path: '',
            component: UsersComponent,
            resolve: { data: ListResolver },
          },
          {
            path: ':id',
            component: UserDetailsComponent,
            resolve: { data: DetailsResolver },
          },
        ],
      },
      { path: '**', pathMatch: 'full', redirectTo: 'overview' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
