import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toast: ToastrService) {}

  public userAddedToQueue({ name, email }): void {
    this.toast.info(
      `Fingerprint setup for ${name} (${email}) added to terminal's queue.`
    );
  }

  public terminalError(err: string): void {
    this.toast.error(err);
  }

  public terminalSuccess(message: string): void {
    this.toast.success(message);
  }
}
