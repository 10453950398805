import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {}

  public toggleForm(): void {
    const open = this.dashboardService.newForm.value;
    this.dashboardService.newForm.next(!open);
  }
}
