<div class="view-root">
  <section class="terminal-registration" *ngIf="state === 'offline'">
    <app-link></app-link>
  </section>
  <section class="background">
    <svg [ngClass]="state" viewBox="0 0 695.456 664.744">
      <defs>
        <filter
          id="Rectangle_216"
          x="254"
          y="174.327"
          width="279"
          height="279"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="22.5" result="blur" />
          <feFlood flood-opacity="0.02" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_215" data-name="Group 215" transform="translate(-14)">
        <g id="Group_173" data-name="Group 173">
          <g id="undraw_ethereum_desire_wy1b" transform="translate(14 317.601)">
            <path
              id="colorBox"
              data-name="Path 397"
              d="M962.853,711.871s76.83,46.35-64.754,117.716c0,0-31.2-57.938,3.295-105.162A46.509,46.509,0,0,1,961.739,711.2Z"
              transform="translate(-777.564 -514.517)"
              fill="#28d8a1"
            />
            <path
              id="Path_398"
              data-name="Path 398"
              d="M968.2,710.22s-65.754,50.3-63.9,118.693"
              transform="translate(-783.798 -513.843)"
              fill="none"
              stroke="#535461"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_399"
              data-name="Path 399"
              d="M972.851,846.682s24.073-81.794-4.544-141.9a129.11,129.11,0,0,1-10.44-81,217.153,217.153,0,0,1,11.462-38.012"
              transform="translate(-856.077 -530.772)"
              fill="none"
              stroke="#535461"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="colorBox"
              data-name="Path 400"
              d="M950.52,554.333c0,10.02,18.177,39.068,18.177,39.068s18.177-29.048,18.177-39.068a18.176,18.176,0,0,0-36.273,0Z"
              transform="translate(-855.024 -537.358)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 401"
              d="M980.421,604.626c5.442,8.407,36.455,22.936,36.455,22.936s-.568-34.24-6.01-42.647a18.176,18.176,0,1,0-30.446,19.721Z"
              transform="translate(-914.904 -532.156)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 402"
              d="M980.953,688.79c8.941,4.544,43.044,1.4,43.044,1.4s-17.768-29.276-26.719-33.786a18.176,18.176,0,1,0-16.336,32.388Z"
              transform="translate(-914.13 -521.507)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 403"
              d="M962.007,751.413c8.054,5.953,42.192,8.645,42.192,8.645s-12.565-31.809-20.619-37.818A18.176,18.176,0,0,0,962,751.4Z"
              transform="translate(-878.042 -512.605)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 404"
              d="M979.847,626.633c-7.18,6.975-40.658,14.212-40.658,14.212s8.2-33.252,15.382-40.227a18.176,18.176,0,0,1,25.277,26.015Z"
              transform="translate(-839.795 -529.341)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 405"
              d="M959.918,701.081c-8.941,4.544-43.044,1.4-43.044,1.4s17.768-29.287,26.663-33.8a18.176,18.176,0,1,1,16.336,32.388Z"
              transform="translate(-800.872 -519.836)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 406"
              d="M955.053,774.915c-8.055,5.953-42.192,8.645-42.192,8.645s12.564-31.809,20.619-37.818A18.177,18.177,0,1,1,955.064,774.9Z"
              transform="translate(-788.736 -509.593)"
              fill="#28d8a1"
            />
            <path
              id="Path_407"
              data-name="Path 407"
              d="M950.52,554.333c0,10.02,18.177,39.068,18.177,39.068s18.177-29.048,18.177-39.068a18.176,18.176,0,0,0-36.273,0Z"
              transform="translate(-855.024 -537.358)"
              opacity="0.25"
            />
            <path
              id="Path_408"
              data-name="Path 408"
              d="M980.421,604.626c5.442,8.407,36.455,22.936,36.455,22.936s-.568-34.24-6.01-42.647a18.176,18.176,0,1,0-30.446,19.721Z"
              transform="translate(-914.904 -532.156)"
              opacity="0.25"
            />
            <path
              id="Path_409"
              data-name="Path 409"
              d="M980.953,688.79c8.941,4.544,43.044,1.4,43.044,1.4s-17.768-29.276-26.719-33.786a18.176,18.176,0,1,0-16.336,32.388Z"
              transform="translate(-914.13 -521.507)"
              opacity="0.25"
            />
            <path
              id="Path_410"
              data-name="Path 410"
              d="M962.007,751.413c8.054,5.953,42.192,8.645,42.192,8.645s-12.565-31.809-20.619-37.818A18.176,18.176,0,0,0,962,751.4Z"
              transform="translate(-878.042 -512.605)"
              opacity="0.25"
            />
            <path
              id="Path_411"
              data-name="Path 411"
              d="M979.847,626.633c-7.18,6.975-40.658,14.212-40.658,14.212s8.2-33.252,15.382-40.227a18.176,18.176,0,0,1,25.277,26.015Z"
              transform="translate(-839.795 -529.341)"
              opacity="0.25"
            />
            <path
              id="Path_412"
              data-name="Path 412"
              d="M959.918,701.081c-8.941,4.544-43.044,1.4-43.044,1.4s17.768-29.287,26.663-33.8a18.176,18.176,0,1,1,16.336,32.388Z"
              transform="translate(-800.872 -519.836)"
              opacity="0.25"
            />
            <path
              id="Path_413"
              data-name="Path 413"
              d="M955.053,774.915c-8.055,5.953-42.192,8.645-42.192,8.645s12.564-31.809,20.619-37.818A18.177,18.177,0,1,1,955.064,774.9Z"
              transform="translate(-788.736 -509.593)"
              opacity="0.25"
            />
            <path
              id="Path_414"
              data-name="Path 414"
              d="M1021.891,848.052s24.073-81.794-4.544-141.9a129.11,129.11,0,0,1-10.44-81,217.141,217.141,0,0,1,11.463-38.012"
              transform="translate(-960.828 -530.586)"
              fill="none"
              stroke="#535461"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="colorBox"
              data-name="Path 415"
              d="M999.56,555.7c0,10.02,18.177,39.068,18.177,39.068s18.177-29.048,18.177-39.068a18.177,18.177,0,0,0-36.274,0Z"
              transform="translate(-959.775 -537.172)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 416"
              d="M1029.511,606c5.442,8.407,36.455,22.937,36.455,22.937s-.568-34.24-6.01-42.647a18.176,18.176,0,1,0-30.446,19.721Z"
              transform="translate(-1019.762 -531.969)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 417"
              d="M1030.022,690.182c8.941,4.544,43.044,1.4,43.044,1.4s-17.768-29.276-26.72-33.786a18.176,18.176,0,1,0-16.325,32.388Z"
              transform="translate(-1018.91 -521.32)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 418"
              d="M1011.037,752.783c8.054,5.953,42.192,8.645,42.192,8.645s-12.565-31.809-20.619-37.818a18.177,18.177,0,0,0-21.585,29.162Z"
              transform="translate(-982.771 -512.418)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 419"
              d="M1028.877,628.043c-7.18,6.975-40.659,14.212-40.659,14.212s8.2-33.252,15.382-40.227a18.177,18.177,0,0,1,25.277,26.015Z"
              transform="translate(-944.525 -529.149)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 420"
              d="M1010.788,702.323c-8.941,4.544-43.044,1.4-43.044,1.4s17.768-29.287,26.708-33.8a18.177,18.177,0,0,1,16.336,32.388Z"
              transform="translate(-907.453 -519.522)"
              fill="#28d8a1"
            />
            <path
              id="colorBox"
              data-name="Path 421"
              d="M1004.093,776.285c-8.054,5.953-42.192,8.645-42.192,8.645s12.564-31.809,20.619-37.818a18.177,18.177,0,1,1,21.585,29.162Z"
              transform="translate(-893.487 -509.406)"
              fill="#28d8a1"
            />
          </g>
          <g
            id="Group_172"
            data-name="Group 172"
            transform="translate(193.165)"
          >
            <g id="undraw_android_jr64" transform="translate(303.822 156.037)">
              <g
                id="Group_44"
                data-name="Group 44"
                transform="translate(38.82 135.668)"
                opacity="0.1"
              >
                <path
                  id="Path_73"
                  data-name="Path 73"
                  d="M729,655.683s26.036-64.167,74.655-87.968A104.929,104.929,0,0,0,851.3,521.506,176.466,176.466,0,0,0,863.242,491.5"
                  transform="translate(-722.826 -453.093)"
                  fill="none"
                  stroke="#535461"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <path
                  id="colorBox"
                  data-name="Path 74"
                  d="M904.888,473.12c-5.069,6.37-31.3,15.7-31.3,15.7s3.158-27.7,8.226-34.022A14.772,14.772,0,0,1,904.9,473.148Z"
                  transform="translate(-733.921 -449.901)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 75"
                  d="M894.848,527.346c-7.718,2.594-34.788-3.859-34.788-3.859s17.68-21.493,25.4-24.088a14.772,14.772,0,0,1,9.39,27.947Z"
                  transform="translate(-732.883 -453.661)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 76"
                  d="M842.546,591.894C834.578,590.242,814.46,571,814.46,571s26.11-9.63,34.087-7.977a14.772,14.772,0,0,1-6,28.861Z"
                  transform="translate(-729.384 -458.568)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 77"
                  d="M791.285,631.741c-8.134-.286-31.215-15.843-31.215-15.843s24.115-13.877,32.249-13.618a14.772,14.772,0,1,1-1.034,29.461Z"
                  transform="translate(-725.21 -461.594)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 78"
                  d="M832.758,504.7c1.043,8.079,18.668,29.609,18.668,29.609s11.605-25.3,10.571-33.376a14.772,14.772,0,0,0-29.24,3.767Z"
                  transform="translate(-730.786 -452.901)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 79"
                  d="M773.552,551.578c3.407,7.386,26.673,22.666,26.673,22.666s3.508-27.615.1-35a14.772,14.772,0,1,0-26.775,12.344Z"
                  transform="translate(-726.108 -456.031)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 80"
                  d="M722.6,605.863c2.105,7.866,22.463,26.848,22.463,26.848s8.125-26.618,6.01-34.484a14.772,14.772,0,0,0-28.473,7.635Z"
                  transform="translate(-722.312 -460.515)"
                  fill="#28d8a1"
                />
                <path
                  id="Path_81"
                  data-name="Path 81"
                  d="M904.888,473.12c-5.069,6.37-31.3,15.7-31.3,15.7s3.158-27.7,8.226-34.022A14.772,14.772,0,0,1,904.9,473.148Z"
                  transform="translate(-733.921 -449.901)"
                  opacity="0.25"
                />
                <path
                  id="Path_82"
                  data-name="Path 82"
                  d="M894.848,527.346c-7.718,2.594-34.788-3.859-34.788-3.859s17.68-21.493,25.4-24.088a14.772,14.772,0,0,1,9.39,27.947Z"
                  transform="translate(-732.883 -453.661)"
                  opacity="0.25"
                />
                <path
                  id="Path_83"
                  data-name="Path 83"
                  d="M842.546,591.894C834.578,590.242,814.46,571,814.46,571s26.11-9.63,34.087-7.977a14.772,14.772,0,0,1-6,28.861Z"
                  transform="translate(-729.384 -458.568)"
                  opacity="0.25"
                />
                <path
                  id="Path_84"
                  data-name="Path 84"
                  d="M791.285,631.741c-8.134-.286-31.215-15.843-31.215-15.843s24.115-13.877,32.249-13.618a14.772,14.772,0,1,1-1.034,29.461Z"
                  transform="translate(-725.21 -461.594)"
                  opacity="0.25"
                />
                <path
                  id="Path_85"
                  data-name="Path 85"
                  d="M832.758,504.7c1.043,8.079,18.668,29.609,18.668,29.609s11.605-25.3,10.571-33.376a14.772,14.772,0,0,0-29.24,3.767Z"
                  transform="translate(-730.786 -452.901)"
                  opacity="0.25"
                />
                <path
                  id="Path_86"
                  data-name="Path 86"
                  d="M773.552,551.578c3.407,7.386,26.673,22.666,26.673,22.666s3.508-27.615.1-35a14.772,14.772,0,1,0-26.775,12.344Z"
                  transform="translate(-726.108 -456.031)"
                  opacity="0.25"
                />
                <path
                  id="Path_87"
                  data-name="Path 87"
                  d="M722.6,605.863c2.105,7.866,22.463,26.848,22.463,26.848s8.125-26.618,6.01-34.484a14.772,14.772,0,0,0-28.473,7.635Z"
                  transform="translate(-722.312 -460.515)"
                  opacity="0.25"
                />
              </g>
              <g id="Group_45" data-name="Group 45" opacity="0.1">
                <path
                  id="Path_88"
                  data-name="Path 88"
                  d="M709,551.136s3.287-69.171,41.26-107.754A104.928,104.928,0,0,0,779.8,383.97a176.467,176.467,0,0,0,1.32-32.24"
                  transform="translate(-682.47 -306.7)"
                  fill="none"
                  stroke="#535461"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <path
                  id="colorBox"
                  data-name="Path 89"
                  d="M810.63,323.53C807.962,331.221,786.3,348.7,786.3,348.7s-6.186-27.135-3.518-34.825a14.772,14.772,0,1,1,27.846,9.657Z"
                  transform="translate(-688.081 -302.957)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 90"
                  d="M819.045,377.757c-6.463,5-34.105,7.9-34.105,7.9s9.556-26.147,15.972-31.151a14.772,14.772,0,1,1,18.133,23.248Z"
                  transform="translate(-688.298 -306.627)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 91"
                  d="M790.652,456.067c-8.069,1.08-33.422-10.4-33.422-10.4s21.447-17.745,29.544-18.825a14.772,14.772,0,1,1,3.915,29.221Z"
                  transform="translate(-686.172 -312.442)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 92"
                  d="M755.655,510.674c-7.774,2.428-34.705-4.616-34.705-4.616s18.133-21.115,25.907-23.543a14.772,14.772,0,0,1,8.8,28.141Z"
                  transform="translate(-683.388 -316.704)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 93"
                  d="M752.612,377.511c3.693,7.275,27.439,21.743,27.439,21.743s2.557-27.7-1.1-34.992a14.772,14.772,0,0,0-26.341,13.249Z"
                  transform="translate(-685.726 -307.1)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 94"
                  d="M712.416,441.5c5.669,5.844,32.683,12.538,32.683,12.538s-5.844-27.208-11.513-33.053a14.772,14.772,0,0,0-21.17,20.524Z"
                  transform="translate(-682.461 -311.719)"
                  fill="#28d8a1"
                />
                <path
                  id="colorBox"
                  data-name="Path 95"
                  d="M682.245,509.495c4.616,6.721,30.1,17.884,30.1,17.884s-1.163-27.809-5.761-34.53a14.772,14.772,0,0,0-24.328,16.619Z"
                  transform="translate(-680.265 -317.103)"
                  fill="#28d8a1"
                />
              </g>
            </g>
            <g id="Group_55" data-name="Group 55">
              <path
                id="colorBox"
                data-name="Path 96"
                d="M351.19,818.7c36.3,12.205,80.591,4.782,121.778,5.641,5.641.12,11.605.471,16.129,2.576s6.749,5.54,9.851,8.448c11.541,10.885,35.185,14.384,55.857,12.538s39.626-7.958,59.089-12.612c33.717-8.051,72.263-11.707,105.519-2.936,3.056.8,6.269,1.727,9.537,1.413,7.294-.72,9.463-6.555,14.56-9.888,9.233-6.02,29.2-4.385,33.237-12.316,2.373-4.616-3.084-9.417-9.02-12.593-22.731-12.15-55.248-14.154-85-12.279s-59,7.044-88.9,7.063c-37.253,0-73.251-7.931-110.145-11.181-25.556-2.253-53.447-1.976-77.406,4.616C380.383,794.41,374.659,810.512,351.19,818.7Z"
                transform="translate(-351.19 -183.628)"
                fill="#28d8a1"
                opacity="0.1"
              />
              <rect
                id="Rectangle_60"
                data-name="Rectangle 60"
                width="6.463"
                height="91.403"
                transform="translate(60.723 89.556)"
                fill="#535461"
              />
              <rect
                id="Rectangle_61"
                data-name="Rectangle 61"
                width="6.463"
                height="51.703"
                transform="translate(60.723 212.35)"
                fill="#535461"
              />
              <rect
                id="Rectangle_62"
                data-name="Rectangle 62"
                width="290.827"
                height="631.509"
                rx="47.1"
                transform="translate(65.339)"
                fill="#535461"
              />
              <rect
                id="colorBox"
                data-name="Rectangle 63"
                width="290.827"
                height="575.19"
                rx="47.1"
                transform="translate(65.339 29.544)"
                fill="#28d8a1"
              />
              <rect
                id="Rectangle_64"
                data-name="Rectangle 64"
                width="48.009"
                height="6.463"
                rx="3.231"
                transform="translate(187.209 13.849)"
                fill="#eaeaf3"
              />
              <circle
                id="Ellipse_9"
                data-name="Ellipse 9"
                cx="4.616"
                cy="4.616"
                r="4.616"
                transform="translate(247.221 12.926)"
                fill="#eaeaf3"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_214"
          data-name="Group 214"
          transform="translate(332.418 238.827)"
        >
          <g
            id="Group_186"
            data-name="Group 186"
            transform="translate(0 0)"
            *ngIf="!user"
          >
            <g
              transform="matrix(1, 0, 0, 1, -318.42, -238.83)"
              filter="url(#Rectangle_216)"
            >
              <rect
                id="Rectangle_216-2"
                data-name="Rectangle 216"
                width="144"
                height="144"
                rx="6"
                transform="translate(318.5 238.83)"
                fill="#fff"
              />
            </g>
            <g id="barcode" transform="translate(19.93 19.44)" *ngIf="!svgData">
              <path
                id="Path_37"
                data-name="Path 37"
                d="M12.158,0H1.737a1.737,1.737,0,1,0,0,3.474h8.684v8.684a1.737,1.737,0,1,0,3.474,0V1.737A1.737,1.737,0,0,0,12.158,0Z"
                transform="translate(90.314)"
              />
              <path
                id="Path_38"
                data-name="Path 38"
                d="M1.737,13.894a1.737,1.737,0,0,0,1.737-1.737V3.474h8.684a1.737,1.737,0,0,0,0-3.474H1.737A1.737,1.737,0,0,0,0,1.737V12.158A1.737,1.737,0,0,0,1.737,13.894Z"
              />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M12.158,0a1.737,1.737,0,0,0-1.737,1.737v8.684H1.737a1.737,1.737,0,1,0,0,3.474H12.158a1.737,1.737,0,0,0,1.737-1.737V1.737A1.737,1.737,0,0,0,12.158,0Z"
                transform="translate(90.314 90.314)"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M1.737,13.895H12.158a1.737,1.737,0,1,0,0-3.474H3.474V1.737A1.737,1.737,0,1,0,0,1.737V12.158A1.737,1.737,0,0,0,1.737,13.895Z"
                transform="translate(0 90.314)"
              />
              <path
                id="Path_41"
                data-name="Path 41"
                d="M0,1.737V33a1.737,1.737,0,0,0,1.737,1.737H33A1.737,1.737,0,0,0,34.736,33V1.737A1.737,1.737,0,0,0,33,0H1.737A1.737,1.737,0,0,0,0,1.737ZM3.474,3.474H31.263V31.263H3.474Z"
                transform="translate(6.947 6.947)"
              />
              <path
                id="Path_42"
                data-name="Path 42"
                d="M19.1,0H1.737A1.737,1.737,0,0,0,0,1.737V19.1a1.737,1.737,0,0,0,1.737,1.737H19.1A1.737,1.737,0,0,0,20.842,19.1V1.737A1.737,1.737,0,0,0,19.1,0ZM17.368,17.368H3.474V3.474H17.368Z"
                transform="translate(13.894 13.894)"
              />
              <path
                id="Path_43"
                data-name="Path 43"
                d="M19.1,0H1.737A1.737,1.737,0,0,0,0,1.737V19.1a1.737,1.737,0,0,0,1.737,1.737H19.1A1.737,1.737,0,0,0,20.842,19.1V1.737A1.737,1.737,0,0,0,19.1,0ZM17.368,17.368H3.474V3.474H17.368Z"
                transform="translate(13.894 69.472)"
              />
              <path
                id="Path_44"
                data-name="Path 44"
                d="M19.1,0H1.737A1.737,1.737,0,0,0,0,1.737V19.1a1.737,1.737,0,0,0,1.737,1.737H19.1A1.737,1.737,0,0,0,20.842,19.1V1.737A1.737,1.737,0,0,0,19.1,0ZM17.368,17.368H3.474V3.474H17.368Z"
                transform="translate(69.472 13.894)"
              />
              <path
                id="Path_45"
                data-name="Path 45"
                d="M1.737,34.628H33a1.737,1.737,0,0,0,1.737-1.737V1.737A1.737,1.737,0,0,0,33,0H1.737A1.737,1.737,0,0,0,0,1.737V32.891A1.737,1.737,0,0,0,1.737,34.628ZM3.474,3.474H31.263v27.68H3.474Z"
                transform="translate(62.525 6.947)"
              />
              <path
                id="Path_46"
                data-name="Path 46"
                d="M0,33a1.737,1.737,0,0,0,1.737,1.737H33A1.737,1.737,0,0,0,34.736,33V1.737A1.737,1.737,0,0,0,33,0H1.737A1.737,1.737,0,0,0,0,1.737ZM3.474,3.474H31.263V31.263H3.474Z"
                transform="translate(6.947 62.525)"
              />
              <path
                id="Path_47"
                data-name="Path 47"
                d="M1.737,13.894a1.737,1.737,0,0,0,1.737-1.737V3.474H5.21A1.737,1.737,0,0,0,5.21,0H1.737A1.737,1.737,0,0,0,0,1.737V12.158A1.737,1.737,0,0,0,1.737,13.894Z"
                transform="translate(45.157 6.947)"
              />
              <ellipse
                id="Ellipse_4"
                data-name="Ellipse 4"
                cx="1.858"
                cy="1.673"
                rx="1.858"
                ry="1.673"
                transform="translate(55.332 7.062)"
              />
              <path
                id="Path_48"
                data-name="Path 48"
                d="M1.737,6.947H5.21A1.737,1.737,0,0,0,6.947,5.21V1.737a1.737,1.737,0,1,0-3.474,0V3.474H1.737a1.737,1.737,0,1,0,0,3.474Z"
                transform="translate(52.104 13.894)"
              />
              <path
                id="Path_49"
                data-name="Path 49"
                d="M5.21,0H1.737A1.737,1.737,0,0,0,0,1.737V8.684a1.737,1.737,0,1,0,3.474,0V3.474H5.21A1.737,1.737,0,0,0,5.21,0Z"
                transform="translate(52.104 26.052)"
              />
              <path
                id="Path_50"
                data-name="Path 50"
                d="M1.737,17.368H12.158a1.737,1.737,0,1,0,0-3.474H3.474V1.737A1.737,1.737,0,0,0,0,1.737V15.631A1.737,1.737,0,0,0,1.737,17.368Z"
                transform="translate(45.157 24.315)"
              />
              <path
                id="Path_51"
                data-name="Path 51"
                d="M8.684,0A1.737,1.737,0,0,0,6.947,1.737v8.684H1.737a1.737,1.737,0,0,0,0,3.474H8.684a1.737,1.737,0,0,0,1.737-1.737V1.737A1.737,1.737,0,0,0,8.684,0Z"
                transform="translate(52.104 83.367)"
              />
              <ellipse
                id="Ellipse_5"
                data-name="Ellipse 5"
                cx="1.858"
                cy="1.673"
                rx="1.858"
                ry="1.673"
                transform="translate(44.925 93.665)"
              />
              <path
                id="Path_52"
                data-name="Path 52"
                d="M1.737,6.947A1.737,1.737,0,0,0,3.474,5.21V3.474h5.21A1.737,1.737,0,1,0,8.684,0H1.737A1.737,1.737,0,0,0,0,1.737V5.21A1.737,1.737,0,0,0,1.737,6.947Z"
                transform="translate(45.157 83.367)"
              />
              <path
                id="Path_53"
                data-name="Path 53"
                d="M1.737,13.895a1.737,1.737,0,0,0,1.737-1.737V3.474H6.947A1.737,1.737,0,0,0,6.947,0H1.737A1.737,1.737,0,0,0,0,1.737V12.158A1.737,1.737,0,0,0,1.737,13.895Z"
                transform="translate(45.157 52.104)"
              />
              <path
                id="Path_54"
                data-name="Path 54"
                d="M1.737,3.474H12.158v8.684a1.737,1.737,0,1,0,3.474,0V1.737A1.737,1.737,0,0,0,13.895,0H1.737a1.737,1.737,0,0,0,0,3.474Z"
                transform="translate(45.157 45.157)"
              />
              <path
                id="Path_55"
                data-name="Path 55"
                d="M0,5.21A1.737,1.737,0,0,0,1.737,6.947H12.158a1.737,1.737,0,1,0,0-3.474H3.474V1.737A1.737,1.737,0,1,0,0,1.737Z"
                transform="translate(6.947 52.104)"
              />
              <path
                id="Path_56"
                data-name="Path 56"
                d="M8.684,6.947A1.737,1.737,0,0,0,10.421,5.21V1.737A1.737,1.737,0,0,0,8.684,0H1.737a1.737,1.737,0,0,0,0,3.474h5.21V5.21A1.737,1.737,0,0,0,8.684,6.947Z"
                transform="translate(6.947 45.157)"
              />
              <path
                id="Path_57"
                data-name="Path 57"
                d="M1.737,0A1.737,1.737,0,0,0,0,1.737V5.21A1.737,1.737,0,0,0,1.737,6.947H12.158a1.737,1.737,0,1,0,0-3.474H3.474V1.737A1.737,1.737,0,0,0,1.737,0Z"
                transform="translate(20.842 45.157)"
              />
              <path
                id="Path_58"
                data-name="Path 58"
                d="M17.368,1.737a1.737,1.737,0,1,0-3.474,0v8.684H1.737a1.737,1.737,0,1,0,0,3.474H15.631a1.737,1.737,0,0,0,1.737-1.737Z"
                transform="translate(24.315 45.157)"
              />
              <path
                id="Path_59"
                data-name="Path 59"
                d="M13.895,1.737A1.737,1.737,0,0,0,12.158,0H1.737a1.737,1.737,0,1,0,0,3.474h8.684V5.21a1.737,1.737,0,0,0,3.474,0Z"
                transform="translate(83.367 45.157)"
              />
              <circle
                id="Ellipse_6"
                data-name="Ellipse 6"
                cx="1.673"
                cy="1.673"
                r="1.673"
                transform="translate(64.253 62.443)"
              />
              <path
                id="Path_60"
                data-name="Path 60"
                d="M12.158,5.21V1.737a1.737,1.737,0,1,0-3.474,0V3.474H1.737a1.737,1.737,0,1,0,0,3.474h8.684A1.737,1.737,0,0,0,12.158,5.21Z"
                transform="translate(78.156 52.104)"
              />
              <path
                id="Path_61"
                data-name="Path 61"
                d="M8.684,0H1.737a1.737,1.737,0,0,0,0,3.474h5.21V5.21a1.737,1.737,0,1,0,3.474,0V1.737A1.737,1.737,0,0,0,8.684,0Z"
                transform="translate(79.893 76.42)"
              />
              <circle
                id="Ellipse_7"
                data-name="Ellipse 7"
                cx="1.858"
                cy="1.858"
                r="1.858"
                transform="translate(86.554 86.603)"
              />
              <ellipse
                id="Ellipse_8"
                data-name="Ellipse 8"
                cx="1.673"
                cy="1.858"
                rx="1.673"
                ry="1.858"
                transform="translate(72.801 76.196)"
              />
              <path
                id="Path_62"
                data-name="Path 62"
                d="M8.684,3.474H3.474V1.737A1.737,1.737,0,1,0,0,1.737V5.21A1.737,1.737,0,0,0,1.737,6.947H8.684a1.737,1.737,0,0,0,0-3.474Z"
                transform="translate(72.946 83.367)"
              />
              <path
                id="Path_63"
                data-name="Path 63"
                d="M1.737,0A1.737,1.737,0,0,0,0,1.737V12.158a1.737,1.737,0,0,0,1.737,1.737H10.2a1.737,1.737,0,0,0,0-3.474H3.474V1.737A1.737,1.737,0,0,0,1.737,0Z"
                transform="translate(71.209 52.104)"
              />
              <path
                id="Path_64"
                data-name="Path 64"
                d="M13.894,0H1.737A1.737,1.737,0,0,0,0,1.737V12.158a1.737,1.737,0,1,0,3.474,0V3.474H13.894a1.737,1.737,0,0,0,0-3.474Z"
                transform="translate(64.262 45.157)"
              />
              <path
                id="Path_65"
                data-name="Path 65"
                d="M1.737,10.421H15.631a1.737,1.737,0,0,0,1.737-1.737V1.737A1.737,1.737,0,0,0,15.631,0H1.737A1.737,1.737,0,0,0,0,1.737V8.684A1.737,1.737,0,0,0,1.737,10.421ZM3.474,3.474H13.895V6.947H3.474Z"
                transform="translate(45.157 69.472)"
              />
              <path
                id="Path_66"
                data-name="Path 66"
                d="M1.737,27.789H29.526a1.737,1.737,0,0,0,1.737-1.737V1.737A1.737,1.737,0,0,0,29.526,0H1.737A1.737,1.737,0,0,0,0,1.737V26.052A1.737,1.737,0,0,0,1.737,27.789ZM3.474,3.474H27.789V24.315H3.474Z"
                transform="translate(65.999 69.472)"
              />
              <path
                id="Path_67"
                data-name="Path 67"
                d="M4.993,0H1.737a1.737,1.737,0,0,0,0,3.474H4.993A1.737,1.737,0,0,0,4.993,0Z"
                transform="translate(54.058 62.525)"
              />
              <path
                id="Path_68"
                data-name="Path 68"
                d="M10.421,1.737a1.737,1.737,0,0,0-3.474,0v5.21H1.737a1.737,1.737,0,0,0,0,3.474H8.684a1.737,1.737,0,0,0,1.737-1.737Z"
                transform="translate(86.841 55.578)"
              />
            </g>
            <g id="barcode" transform="translate(12 13)" *ngIf="svgData">
              <path [attr.d]="svgData"></path>
            </g>
          </g>
          <g *ngIf="user">
            <g transform="translate(-30 35)">
              <text font-size="30" fill="#fff">
                <tspan x="0" dy=".5em">Hello</tspan>
                <tspan x="0" dy="1.1em">{{ user.name }}</tspan>
              </text>
            </g>
            <g transform="translate(-30 105)" *ngIf="state === 'busy'">
              <text font-size="14" fill="#fff">
                <tspan x="0" dy=".6em">Not you? Speak with your</tspan>
                <tspan x="0" dy="1.2em">administrator for help</tspan>
              </text>
            </g>
            <g transform="translate(-30 105)" *ngIf="state === 'link'">
              <text font-size="14" fill="#fff" *ngIf="!scanner?.link?.valid">
                <tspan x="0" dy=".6em">Lets get you linked to your</tspan>
                <tspan x="0" dy="1.2em">fingerprint</tspan>
              </text>
              <text font-size="14" fill="#fff" *ngIf="scanner?.link?.valid">
                <tspan x="0" dy=".6em">Looks like you're all set</tspan>
              </text>
            </g>
            <g transform="translate(60 300)" *ngIf="state === 'busy'">
              <text font-size="25" fill="#fff">
                <tspan x="0" dy=".6em">{{ timer }}</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </section>
  <section class="content">
    <app-main [user]="user" [scanner]="scanner" [state]="state"></app-main>
  </section>
  <section class="terminal-status">
    <span class="status-indicator" [ngClass]="state"></span
    >{{
      state !== "offline"
        ? terminalName + " terminal"
        : "Waiting for registration"
    }}
  </section>
</div>
