import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-circle',
  templateUrl: './account-circle.component.html',
  styleUrls: ['./account-circle.component.scss'],
})
export class AccountCircleComponent implements OnInit {
  constructor() {}

  public userLetter = 'm';

  ngOnInit(): void {}
}
