import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor() {}

  public selection = new BehaviorSubject([]);
  public newForm = new BehaviorSubject(false);
}
