import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { firebase } from '@firebase/app';
import '@firebase/auth';
import { AngularFireAuth } from '@angular/fire/auth';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$!: Observable<any>;
  orgId$ = new BehaviorSubject('');

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth
  ) {
    this.user$ = this.afAuth.authState;

    this.afAuth.idTokenResult
      .pipe(
        map((token) => {
          // console.log(token);
          return token?.claims;
        })
      )
      .subscribe((claims) => {
        this.orgId$.next(claims?.orgId);
      });
  }

  async emailSignIn(email: string, password: string): Promise<any> {
    try {
      const cred = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      this.redirect(cred);
      return cred;
    } catch (error) {
      throw error;
    }
  }

  async googleSignIn(): Promise<any> {
    try {
      const provider: any = new firebase.auth.GoogleAuthProvider();
      const cred = await this.afAuth.signInWithPopup(provider);
      this.redirect(cred);
      return cred;
    } catch (error) {
      // console.log(error);
    }
  }

  async logout(): Promise<any> {
    try {
      await this.afAuth.signOut();
      return this.router.navigate(['/auth/login']);
    } catch (error) {
      // console.log(error);
    }
  }

  private redirect(cred: any): void {
    if (cred) {
      this.router.navigateByUrl(this.route.snapshot.queryParams.return || '/');
    }
  }
}
