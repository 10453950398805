import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { LoginComponent } from 'src/app/shared/components/auth/login/login.component';
import { ForgotPasswordComponent } from 'src/app/shared/components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/app/shared/components/auth/reset-password/reset-password.component';
import { SelectorComponent } from '../../shared/components/selector/selector.component';
import { AuthComponent } from 'src/app/shared/components/auth/auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LandingComponent,
    SelectorComponent,
    AuthComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class LandingModule {}
