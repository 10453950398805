import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { TerminalService } from 'src/app/shared/services/terminal.service';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
})
export class QrComponent implements OnInit {
  constructor(
    private router: Router,
    private terminalService: TerminalService
  ) {}

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  public title = 'Hang tight';
  public body = 'We are doing some thinking in the background.';
  public busy = false;
  public loading = true;

  ngOnInit(): void {}

  public scanSuccessHandler($event): void {
    const url = $event.replace('https://', '');
    const { terminal, authCode } = this.router.parseUrl(url).queryParams;

    if (terminal && !this.busy) {
      this.busy = true;
      this.terminalService
        .authUserWithQr(terminal, authCode)
        .subscribe((res) => {
          // console.log(res);
          this.setPromptState(res);
        });
    }
  }

  public closePrompt(): void {
    if (!this.loading) {
      this.busy = false;
      this.loading = true;
      this.title = 'Hang tight';
      this.body = 'We are doing some thinking in the background.';
    }
  }

  private setPromptState(res: string, error?: boolean): void {
    this.title = 'All done';
    this.body = res;
    this.loading = false;
  }
}
