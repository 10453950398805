import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxControlComponent } from './components/other/checkbox-control/checkbox-control.component';
import { CapitaliseFirstLetterPipe } from './pipes/capitalise-first-letter.pipe';
import { ModalComponent } from './components/other/modal/modal.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

const components = [CheckboxControlComponent, ModalComponent];

@NgModule({
  declarations: [...components, CapitaliseFirstLetterPipe],
  imports: [CommonModule, ZXingScannerModule],
  exports: [...components, CapitaliseFirstLetterPipe, ZXingScannerModule],
})
export class SharedModule {}
