import { Validators } from '@angular/forms';

export const userFormDetails = {
  formGroup: {
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    contact: [
      '',
      [
        Validators.required,
        Validators.pattern('^((?:\\+27|27)|0)(\\d{2})-?(\\d{3})-?(\\d{4})$'),
      ],
    ],
    password: ['', [Validators.minLength(8)]],
    role: [''],
    groups: [''],
    notes: [''],
  },
  formFields: [
    {
      type: 'text',
      placeholder: 'Name',
      formControlName: 'name',
    },
    {
      type: 'email',
      placeholder: 'Email address',
      formControlName: 'email',
    },
    {
      type: 'tel',
      placeholder: 'Contact number',
      formControlName: 'contact',
    },
    {
      type: 'menu',
      placeholder: 'Role',
      formControlName: 'role',
    },
    {
      type: 'menu',
      placeholder: 'Groups',
      formControlName: 'groups',
    },
    {
      type: 'password',
      placeholder: 'Password',
      formControlName: 'password',
      autocomplete: 'new-password',
    },
  ],
};

export const assetFormDetails = {
  formGroup: {
    displayName: ['', Validators.required],
    name: ['', Validators.required],
    serialCode: [''],
    groups: [''],
    notes: [''],
  },
  formFields: [
    {
      type: 'text',
      placeholder: 'Display name',
      formControlName: 'displayName',
    },
    {
      type: 'text',
      placeholder: 'Full name',
      formControlName: 'name',
    },
    {
      type: 'text',
      placeholder: 'Serial / Code',
      formControlName: 'serialCode',
    },
    {
      type: 'menu',
      placeholder: 'Groups',
      formControlName: 'groups',
    },
  ],
};

export const groupFormDetails = {
  formGroup: {
    displayName: ['', Validators.required],
    name: ['', Validators.required],
    serialCode: [''],
    // usersAssets: [''],
    notes: [''],
  },
  formFields: [
    {
      type: 'text',
      placeholder: 'Display name',
      formControlName: 'displayName',
    },
    {
      type: 'text',
      placeholder: 'Full name',
      formControlName: 'name',
    },
    {
      type: 'text',
      placeholder: 'Serial / Code',
      formControlName: 'serialCode',
    },
    // {
    //   type: 'menu',
    //   placeholder: 'Users / Assets',
    //   formControlName: 'usersAssets',
    //   readonly: true,
    // },
  ],
};

export const userFormNew = {
  formGroup: {
    section1: {
      name: ['', Validators.required],
      surname: ['', Validators.required],
      contact: [
        '',
        [
          Validators.required,
          Validators.pattern('^((?:\\+27|27)|0)(\\d{2})-?(\\d{3})-?(\\d{4})$'),
        ],
      ],
    },
    section2: {
      email: ['', [Validators.required, Validators.email]],
      pwd: ['', [Validators.required, Validators.minLength(6)]],
      confPwd: [''],
    },
    section3: {
      role: [''],
      groups: [''],
      notes: [''],
    },
  },
  sections: [
    {
      formGroupName: 'section1',
      formFields: [
        {
          type: 'text',
          placeholder: 'Name',
          formControlName: 'name',
        },
        {
          type: 'text',
          placeholder: 'Surname',
          formControlName: 'surname',
        },
        {
          type: 'tel',
          placeholder: 'Contact number',
          formControlName: 'contact',
        },
      ],
    },
    {
      formGroupName: 'section2',
      formFields: [
        {
          type: 'email',
          placeholder: 'Email',
          formControlName: 'email',
        },
        {
          type: 'password',
          placeholder: 'Password',
          formControlName: 'pwd',
        },
        {
          type: 'password',
          placeholder: 'Confirm password',
          formControlName: 'confPwd',
        },
      ],
    },
    {
      formGroupName: 'section3',
      permsRequired: [{ userCreate: true }],
      formFields: [
        {
          type: 'menu',
          placeholder: 'Role',
          formControlName: 'role',
          readonly: true,
        },
        {
          type: 'menu',
          placeholder: 'Groups',
          formControlName: 'groups',
          readonly: true,
        },
        {
          type: 'text',
          placeholder: 'Notes',
          formControlName: 'notes',
        },
      ],
    },
  ],
};

export const assetFormNew = {
  formGroup: {
    section1: {
      displayName: ['', Validators.required],
      name: ['', Validators.required],
      serialCode: [''],
    },
    section2: {
      assetType: ['', Validators.required],
      groups: [''],
      notes: [''],
    },
  },
  sections: [
    {
      formGroupName: 'section1',
      formFields: [
        {
          type: 'text',
          placeholder: 'Display name',
          formControlName: 'displayName',
        },
        {
          type: 'text',
          placeholder: 'Full name',
          formControlName: 'name',
        },
        {
          type: 'text',
          placeholder: 'Serial / Code',
          formControlName: 'serialCode',
        },
      ],
    },
    {
      formGroupName: 'section2',
      formFields: [
        {
          type: 'menu',
          placeholder: 'Type',
          formControlName: 'assetType',
          readonly: true,
        },
        {
          type: 'menu',
          placeholder: 'Groups',
          formControlName: 'groups',
          readonly: true,
        },
        {
          type: 'text',
          placeholder: 'Notes',
          formControlName: 'notes',
        },
      ],
    },
  ],
};

export const groupFormNew = {
  formGroup: {
    section1: {
      displayName: ['', Validators.required],
      name: ['', Validators.required],
      serialCode: [''],
    },
    section2: {
      groupType: ['', Validators.required],
      usersAssets: [''],
      notes: [''],
    },
  },
  sections: [
    {
      formGroupName: 'section1',
      formFields: [
        {
          type: 'text',
          placeholder: 'Display name',
          formControlName: 'displayName',
        },
        {
          type: 'text',
          placeholder: 'Full name',
          formControlName: 'name',
        },
        {
          type: 'text',
          placeholder: 'Serial / Code',
          formControlName: 'serialCode',
        },
      ],
    },
    {
      formGroupName: 'section2',
      formFields: [
        {
          type: 'menu',
          placeholder: 'Type',
          formControlName: 'groupType',
          readonly: true,
        },
        {
          type: 'menu',
          placeholder: 'Users / Assets',
          formControlName: 'usersAssets',
          readonly: true,
        },
        {
          type: 'text',
          placeholder: 'Notes',
          formControlName: 'notes',
        },
      ],
    },
  ],
};

export const terminalFormNew = {
  formGroup: {
    terminalName: ['', Validators.required],
    groups: ['', Validators.required],
  },

  formFields: [
    {
      type: 'text',
      placeholder: 'Terminal name',
      formControlName: 'terminalName',
    },
    {
      type: 'menu',
      placeholder: 'Groups',
      formControlName: 'groups',
    },
  ],
};
