<div class="card card-form">
  <div class="card-return" routerLink="../login"></div>
  <div class="card-title">Reset password</div>
  <div class="card-subtitle">
    Only reset your password if you made this request. If you did not please
    speak to your system administrator.
  </div>
  <div class="card-body">
    <form class="form">
      <input class="form-input" type="password" placeholder="Password" />
      <input
        class="form-input"
        type="password"
        placeholder="Confirm password"
      />
      <button class="btn btn-secondary">Reset</button>
    </form>
  </div>
</div>
