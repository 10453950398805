import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { assetFormNew, groupFormNew, userFormNew } from '../models/form.model';
import { assetsTable, groupsTable, usersTable } from '../models/table.model';
import { AssetService } from '../services/asset.service';
import { GroupService } from '../services/group.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ListResolver implements Resolve<boolean> {
  constructor(
    private userService: UserService,
    private assetService: AssetService,
    private groupService: GroupService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    switch (route.data.type) {
      case 'users':
        return {
          table: usersTable,
          newForm: userFormNew,
          collection: this.userService.getUserCollection(),
        };
      case 'assets':
        return {
          table: assetsTable,
          newForm: assetFormNew,
          collection: this.assetService.getAssetCollection(),
        };
      case 'groups':
        return {
          table: groupsTable,
          newForm: groupFormNew,
          collection: this.groupService.getGroupCollection(),
        };
    }
  }
}
