import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  assetFormDetails,
  userFormDetails,
  groupFormDetails,
} from '../models/form.model';
import { AssetService } from '../services/asset.service';
import { GroupService } from '../services/group.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class DetailsResolver implements Resolve<any> {
  constructor(
    private userService: UserService,
    private assetService: AssetService,
    private groupService: GroupService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable((observer) => {
      switch (route.data.type) {
        case 'users':
          this.userService
            .getUser(route.params.id)
            .pipe(take(1))
            .subscribe(
              (user) => {
                observer.next({
                  form: userFormDetails,
                  details: user,
                });
                observer.complete();
              },
              () => {
                this.router.navigateByUrl('dashboard/users');
              }
            );
          break;
        case 'assets':
          this.assetService
            .getAsset(route.params.id)
            .pipe(take(1))
            .subscribe(
              (asset) => {
                observer.next({
                  form: assetFormDetails,
                  details: asset,
                });
                observer.complete();
              },
              () => {
                this.router.navigateByUrl('dashboard/assets');
              }
            );
          break;
        case 'groups':
          this.groupService
            .getGroup(route.params.id)
            .pipe(take(1))
            .subscribe(
              (group) => {
                observer.next({
                  form: groupFormDetails,
                  details: group,
                });
                observer.complete();
              },
              () => {
                this.router.navigateByUrl('dashboard/groups');
              }
            );
          break;

        default:
          observer.complete();
          break;
      }
    });
  }
}
