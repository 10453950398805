<div class="card card-form">
  <div
    class="card-return"
    [class.reset]="step === 0 || step === 'final'"
    (click)="previous()"
  ></div>
  <div class="card-title" *ngIf="mode == 'standalone'">Sign up</div>
  <div class="card-body">
    <form
      class="form"
      [formGroup]="newForm"
      autocomplete="off"
      autocapitalize="words"
    >
      <app-checkbox-control
        [type]="modal"
        *ngIf="modal"
        (selection)="selection($event)"
      ></app-checkbox-control>
      <div
        *ngFor="
          let section of formService?.getNewForm(formType).sections;
          let i = index
        "
      >
        <section
          class="form-group"
          *ngIf="step == i"
          (keyup.enter)="next(section.formGroupName)"
          [formGroupName]="section.formGroupName"
        >
          <div
            class="validation-container"
            *ngFor="let formField of section.formFields"
            [class.invalid]="
              formService?.inputValidation(
                newForm,
                formField,
                section.formGroupName
              ) && !modal
            "
          >
            <input
              class="form-input"
              autocomplete="new-password"
              autocapitalize="words"
              [type]="formField.type"
              [placeholder]="formField.placeholder"
              [formControlName]="formField.formControlName"
              [readonly]="formField.readonly"
              (click)="
                formField.type == 'menu' &&
                  openModal(formField.formControlName, section.formGroupName)
              "
            />
            <p class="form-error-text">
              {{
                formService?.inputValidationError(
                  newForm,
                  formField,
                  section.formGroupName
                )
              }}
            </p>
          </div>
          <button
            class="btn-no-margin btn-secondary"
            type="button"
            (click)="next(section.formGroupName)"
          >
            <div [class.loader]="loading">
              {{ i == formLength ? "Create" : "Next" }}
            </div>
          </button>
          <p class="creation-error" *ngIf="errorMessage">
            {{ errorMessage }}
          </p>
        </section>
      </div>
      <section class="form-group" *ngIf="step == 'final'">
        <div class="form-qrcode">
          <svg viewBox="0 0 150 150" *ngIf="qrCode">
            <path [attr.d]="qrCode" />
          </svg>
        </div>
        <h5>
          {{
            formType == "users"
              ? "Present this QR Code to nearest terminal to link your fingerprint with
          your account."
              : "Scan this QR Code from any device to view more information."
          }}
        </h5>
        <div class="hr"><span>or</span></div>
        <button
          class="btn-no-margin btn-secondary"
          (click)="finalStepHandler()"
        >
          <div [class.loader]="loading">
            {{ formType === "users" ? "Choose terminal" : "View more info" }}
          </div>
        </button>
      </section>
    </form>
  </div>
  <div class="card-footer">
    <div
      class="text-light"
      *ngIf="step <= formService?.getNewForm(formType).sections.length - 1"
    >
      Step {{ step + 1 }} of
      {{ formService?.getNewForm(formType).sections.length }}
    </div>
  </div>
  <div *ngIf="step != 'final' && mode == 'dashboard'">
    <div class="hr">
      <span>or</span>
    </div>
    <div class="card-body">
      <div class="form-qrcode-auto">
        <svg viewBox="0 0 100.697 100.697" *ngIf="!qrCode">
          <path
            id="Path_37"
            data-name="Path 37"
            d="M443.748,16h-10.07a1.678,1.678,0,0,0,0,3.357h8.391v8.391a1.678,1.678,0,1,0,3.357,0V17.678A1.678,1.678,0,0,0,443.748,16Z"
            transform="translate(-344.729 -16)"
          />
          <path
            id="Path_38"
            data-name="Path 38"
            d="M17.678,29.426a1.678,1.678,0,0,0,1.678-1.678V19.357h8.391a1.678,1.678,0,0,0,0-3.357H17.678A1.678,1.678,0,0,0,16,17.678v10.07A1.678,1.678,0,0,0,17.678,29.426Z"
            transform="translate(-16 -16)"
          />
          <path
            id="Path_39"
            data-name="Path 39"
            d="M443.748,432a1.678,1.678,0,0,0-1.678,1.678v8.391h-8.391a1.678,1.678,0,1,0,0,3.357h10.07a1.678,1.678,0,0,0,1.678-1.678v-10.07A1.678,1.678,0,0,0,443.748,432Z"
            transform="translate(-344.729 -344.729)"
          />
          <path
            id="Path_40"
            data-name="Path 40"
            d="M17.678,445.426h10.07a1.678,1.678,0,1,0,0-3.357H19.357v-8.391a1.678,1.678,0,0,0-3.357,0v10.07A1.678,1.678,0,0,0,17.678,445.426Z"
            transform="translate(-16 -344.729)"
          />
          <path
            id="Path_41"
            data-name="Path 41"
            d="M48,49.678V79.887a1.678,1.678,0,0,0,1.678,1.678H79.887a1.678,1.678,0,0,0,1.678-1.678V49.678A1.678,1.678,0,0,0,79.887,48H49.678A1.678,1.678,0,0,0,48,49.678Zm3.357,1.678H78.209V78.209H51.357Z"
            transform="translate(-41.287 -41.287)"
          />
          <path
            id="Path_42"
            data-name="Path 42"
            d="M98.461,80H81.678A1.678,1.678,0,0,0,80,81.678V98.461a1.678,1.678,0,0,0,1.678,1.678H98.461a1.678,1.678,0,0,0,1.678-1.678V81.678A1.678,1.678,0,0,0,98.461,80ZM96.783,96.783H83.357V83.357H96.783Z"
            transform="translate(-66.574 -66.574)"
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M98.461,336H81.678A1.678,1.678,0,0,0,80,337.678v16.783a1.678,1.678,0,0,0,1.678,1.678H98.461a1.678,1.678,0,0,0,1.678-1.678V337.678A1.678,1.678,0,0,0,98.461,336Zm-1.678,16.783H83.357V339.357H96.783Z"
            transform="translate(-66.574 -268.869)"
          />
          <path
            id="Path_44"
            data-name="Path 44"
            d="M354.461,80H337.678A1.678,1.678,0,0,0,336,81.678V98.461a1.678,1.678,0,0,0,1.678,1.678h16.783a1.678,1.678,0,0,0,1.678-1.678V81.678A1.678,1.678,0,0,0,354.461,80Zm-1.678,16.783H339.357V83.357h13.426Z"
            transform="translate(-268.869 -66.574)"
          />
          <path
            id="Path_45"
            data-name="Path 45"
            d="M305.678,81.461h30.209a1.678,1.678,0,0,0,1.678-1.678v-30.1A1.678,1.678,0,0,0,335.888,48H305.678A1.678,1.678,0,0,0,304,49.678v30.1A1.678,1.678,0,0,0,305.678,81.461Zm1.678-30.1h26.853V78.1H307.357Z"
            transform="translate(-243.582 -41.287)"
          />
          <path
            id="Path_46"
            data-name="Path 46"
            d="M48,335.888a1.678,1.678,0,0,0,1.678,1.678H79.887a1.678,1.678,0,0,0,1.678-1.678V305.678A1.678,1.678,0,0,0,79.887,304H49.678A1.678,1.678,0,0,0,48,305.678Zm3.357-28.531H78.209v26.853H51.357Z"
            transform="translate(-41.287 -243.582)"
          />
          <path
            id="Path_47"
            data-name="Path 47"
            d="M225.678,61.426a1.678,1.678,0,0,0,1.678-1.678V51.357h1.678a1.678,1.678,0,1,0,0-3.357h-3.357A1.678,1.678,0,0,0,224,49.678v10.07A1.678,1.678,0,0,0,225.678,61.426Z"
            transform="translate(-180.364 -41.287)"
          />
          <ellipse
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="1.796"
            cy="1.616"
            rx="1.796"
            ry="1.616"
            transform="translate(53.468 6.824)"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M257.678,86.713h3.357a1.678,1.678,0,0,0,1.678-1.678V81.678a1.678,1.678,0,1,0-3.357,0v1.678h-1.678a1.678,1.678,0,0,0,0,3.357Z"
            transform="translate(-205.651 -66.574)"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M261.035,136h-3.357A1.678,1.678,0,0,0,256,137.678v6.713a1.678,1.678,0,0,0,3.357,0v-5.035h1.678a1.678,1.678,0,1,0,0-3.357Z"
            transform="translate(-205.651 -110.826)"
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M225.678,144.783h10.07a1.678,1.678,0,0,0,0-3.357h-8.391V129.678a1.678,1.678,0,1,0-3.357,0V143.1A1.678,1.678,0,0,0,225.678,144.783Z"
            transform="translate(-180.364 -104.504)"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M264.391,400a1.678,1.678,0,0,0-1.678,1.678v8.391h-5.035a1.678,1.678,0,1,0,0,3.357h6.713a1.678,1.678,0,0,0,1.678-1.678v-10.07A1.678,1.678,0,0,0,264.391,400Z"
            transform="translate(-205.651 -319.442)"
          />
          <ellipse
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="1.796"
            cy="1.616"
            rx="1.796"
            ry="1.616"
            transform="translate(43.411 90.509)"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M225.678,406.713a1.678,1.678,0,0,0,1.678-1.678v-1.678h5.035a1.678,1.678,0,1,0,0-3.357h-6.713A1.678,1.678,0,0,0,224,401.678v3.357A1.678,1.678,0,0,0,225.678,406.713Z"
            transform="translate(-180.364 -319.442)"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M225.678,269.426a1.678,1.678,0,0,0,1.678-1.678v-8.391h3.357a1.678,1.678,0,0,0,0-3.357h-5.035A1.678,1.678,0,0,0,224,257.678v10.07A1.678,1.678,0,0,0,225.678,269.426Z"
            transform="translate(-180.364 -205.651)"
          />
          <path
            id="Path_54"
            data-name="Path 54"
            d="M225.678,227.357h10.07v8.391a1.678,1.678,0,0,0,3.357,0v-10.07A1.678,1.678,0,0,0,237.426,224H225.678a1.678,1.678,0,1,0,0,3.357Z"
            transform="translate(-180.364 -180.364)"
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M48,261.035a1.678,1.678,0,0,0,1.678,1.678h10.07a1.678,1.678,0,1,0,0-3.357H51.357v-1.678a1.678,1.678,0,0,0-3.357,0Z"
            transform="translate(-41.287 -205.651)"
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M56.391,230.713a1.678,1.678,0,0,0,1.678-1.678v-3.357A1.678,1.678,0,0,0,56.391,224H49.678a1.678,1.678,0,0,0,0,3.357h5.035v1.678A1.678,1.678,0,0,0,56.391,230.713Z"
            transform="translate(-41.287 -180.364)"
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M113.678,224A1.678,1.678,0,0,0,112,225.678v3.357a1.678,1.678,0,0,0,1.678,1.678h10.07a1.678,1.678,0,0,0,0-3.357h-8.391v-1.678A1.678,1.678,0,0,0,113.678,224Z"
            transform="translate(-91.861 -180.364)"
          />
          <path
            id="Path_58"
            data-name="Path 58"
            d="M144.783,225.678a1.678,1.678,0,1,0-3.357,0v8.391H129.678a1.678,1.678,0,1,0,0,3.357H143.1a1.678,1.678,0,0,0,1.678-1.678Z"
            transform="translate(-104.504 -180.364)"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M413.426,225.678A1.678,1.678,0,0,0,411.748,224h-10.07a1.678,1.678,0,0,0,0,3.357h8.391v1.678a1.678,1.678,0,1,0,3.357,0Z"
            transform="translate(-319.442 -180.364)"
          />
          <circle
            id="Ellipse_6"
            data-name="Ellipse 6"
            cx="1.616"
            cy="1.616"
            r="1.616"
            transform="translate(62.088 60.339)"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M387.748,261.035v-3.357a1.678,1.678,0,0,0-3.357,0v1.678h-6.713a1.678,1.678,0,1,0,0,3.357h8.391A1.678,1.678,0,0,0,387.748,261.035Z"
            transform="translate(-300.477 -205.651)"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M392.391,368h-6.713a1.678,1.678,0,0,0,0,3.357h5.035v1.678a1.678,1.678,0,1,0,3.357,0v-3.357A1.678,1.678,0,0,0,392.391,368Z"
            transform="translate(-306.799 -294.155)"
          />
          <circle
            id="Ellipse_7"
            data-name="Ellipse 7"
            cx="1.796"
            cy="1.796"
            r="1.796"
            transform="translate(83.637 83.685)"
          />
          <ellipse
            id="Ellipse_8"
            data-name="Ellipse 8"
            cx="1.616"
            cy="1.796"
            rx="1.616"
            ry="1.796"
            transform="translate(70.348 73.628)"
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M360.391,403.357h-5.035v-1.678a1.678,1.678,0,1,0-3.357,0v3.357a1.678,1.678,0,0,0,1.678,1.678h6.713a1.678,1.678,0,1,0,0-3.357Z"
            transform="translate(-281.512 -319.442)"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M345.678,256A1.678,1.678,0,0,0,344,257.678v10.07a1.678,1.678,0,0,0,1.678,1.678h8.182a1.678,1.678,0,0,0,0-3.357h-6.5v-8.391A1.678,1.678,0,0,0,345.678,256Z"
            transform="translate(-275.19 -205.651)"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M325.426,224H313.678A1.678,1.678,0,0,0,312,225.678v10.07a1.678,1.678,0,1,0,3.357,0v-8.391h10.07a1.678,1.678,0,0,0,0-3.357Z"
            transform="translate(-249.903 -180.364)"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M225.678,346.07H239.1a1.678,1.678,0,0,0,1.678-1.678v-6.713A1.678,1.678,0,0,0,239.1,336H225.678A1.678,1.678,0,0,0,224,337.678v6.713A1.678,1.678,0,0,0,225.678,346.07Zm1.678-6.713h10.07v3.357h-10.07Z"
            transform="translate(-180.364 -268.869)"
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M321.678,362.853h26.853a1.678,1.678,0,0,0,1.678-1.678v-23.5A1.678,1.678,0,0,0,348.531,336H321.678A1.678,1.678,0,0,0,320,337.678v23.5A1.678,1.678,0,0,0,321.678,362.853Zm1.678-23.5h23.5V359.5h-23.5Z"
            transform="translate(-256.225 -268.869)"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M269.825,304h-3.147a1.678,1.678,0,1,0,0,3.357h3.147a1.678,1.678,0,0,0,0-3.357Z"
            transform="translate(-212.763 -243.582)"
          />
          <path
            id="Path_68"
            data-name="Path 68"
            d="M426.07,273.678a1.678,1.678,0,0,0-3.357,0v5.035h-5.035a1.678,1.678,0,0,0,0,3.357h6.713a1.678,1.678,0,0,0,1.678-1.678Z"
            transform="translate(-332.086 -218.295)"
          />
        </svg>
        <svg viewBox="0 0 150 150" *ngIf="qrCode">
          <path [attr.d]="qrCode" />
        </svg>
        <div
          class="qr-loader"
          [class.loading]="qrLoading"
          *ngIf="changed || qrLoading"
          (click)="reloadQrCode()"
        ></div>
      </div>
      <h5>
        {{
          changed
            ? "Click reload to generate a new QR Code with the latest data."
            : "Scan this QR code from any device to continue the setup."
        }}
      </h5>
    </div>
  </div>
</div>
