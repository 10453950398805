import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { TerminalService } from 'src/app/shared/services/terminal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  constructor(private terminalService: TerminalService) {}

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  @Input() svgData: any;
  @Input() formType = 'users';
  @Input() details: any;
  public loading = false;
  public modal: any;

  ngOnInit(): void {}

  public selection({ data: { terminal } }: any): void {
    this.modal = false;
    if (terminal) {
      this.loading = true;
      const { uid } = terminal;
      const { id } = this.details;
      this.terminalService.addUserToQueue(uid, id).subscribe((res) => {
        this.loading = false;
      });
    }
  }

  public clickHandler(): void {
    this.modal = { data: {}, formControlName: 'terminal' };
  }
}
