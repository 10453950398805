<app-user-new
  *ngIf="
    (deviceService?.isMobile | async) && (dashboardService?.newForm | async)
      ? true
      : false
  "
></app-user-new>
<div
  class="table-container"
  *ngIf="
    (deviceService?.isMobile | async) && (dashboardService?.newForm | async)
      ? false
      : true
  "
>
  <div class="table table-header">
    <div class="btn-group no-mobile" *ngIf="!selection.length">
      <div class="buttons">
        <button class="btn-alt btn-primary">
          <svg viewBox="0 0 24 24">
            <g
              id="Group_242"
              data-name="Group 242"
              transform="translate(24 24) rotate(180)"
            >
              <path
                id="Path_477"
                data-name="Path 477"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Path_478"
                data-name="Path 478"
                d="M5,4V6H19V4ZM5,14H9v6h6V14h4L12,7Z"
                transform="translate(0 0)"
                fill="#fff"
              />
            </g>
          </svg>
          <span>Import {{ headerType }}</span>
        </button>
      </div>
    </div>
    <div class="btn-group" *ngIf="selection.length">
      <div class="buttons" [class.confirm-delete]="confirmDeletion">
        <button
          class="btn-icon btn-danger"
          [class.deleting-loader]="deleting"
          (click)="bulkDelete()"
          (mouseleave)="confirmDeletion = false"
        >
          <svg viewBox="0 0 24.361 24.361">
            <path
              id="Path_97"
              data-name="Path 97"
              d="M0,0H24.361V24.361H0Z"
              fill="none"
            />
            <path
              id="Path_98"
              data-name="Path 98"
              d="M16.165,9.09v10.15H8.045V9.09h8.12M14.643,3H9.568L8.553,4.015H5v2.03H19.21V4.015H15.658ZM18.2,7.06H6.015v12.18a2.036,2.036,0,0,0,2.03,2.03h8.12a2.036,2.036,0,0,0,2.03-2.03Z"
              transform="translate(0.075 0.045)"
              fill="#fff"
            />
          </svg>
          <span *ngIf="!confirmDeletion">Delete selected</span>
          <span *ngIf="confirmDeletion">Are you sure?</span>
        </button>
        <button class="btn-icon btn-warn">
          <svg viewBox="0 0 24 24" fill="currentColor" color="#fff">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z"
            ></path>
          </svg>
          <span>Disable selected</span>
        </button>
        <button class="btn-icon btn-secondary">
          <svg viewBox="0 0 24 24">
            <path
              id="Path_473"
              data-name="Path 473"
              d="M0,0H24V24H0Z"
              fill="none"
            />
            <path
              id="Path_474"
              data-name="Path 474"
              d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM5.92,19H5v-.92l9.06-9.06.92.92ZM20.71,5.63,18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41Z"
              fill="#fff"
            />
          </svg>
          <span>Edit selected</span>
        </button>
        <button class="btn-icon btn-primary" (click)="bulkExport()">
          <svg viewBox="0 0 24 24">
            <path
              id="Path_477"
              data-name="Path 477"
              d="M0,0H24V24H0Z"
              fill="none"
            />
            <path
              id="Path_478"
              data-name="Path 478"
              d="M5,4V6H19V4ZM5,14H9v6h6V14h4L12,7Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </svg>
          <span>Export selected</span>
        </button>
      </div>
      <span class="selected-count"
        >{{ selection.length }} {{ headerType }} selected</span
      >
    </div>
    <input
      class="filter-input"
      type="text"
      placeholder="Search and filter {{ headerType }}"
      #filterInput
      (input)="filterService?.setFilter(filterInput.value)"
    />
  </div>
  <div class="table-body">
    <app-user-new *ngIf="dashboardService?.newForm | async"></app-user-new>
    <div class="table table-data">
      <app-table></app-table>
    </div>
  </div>
</div>
