import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from 'src/app/shared/components/auth/forgot-password/forgot-password.component';
import { LoginComponent } from 'src/app/shared/components/auth/login/login.component';
import { ResetPasswordComponent } from 'src/app/shared/components/auth/reset-password/reset-password.component';
import { SelectorComponent } from 'src/app/shared/components/selector/selector.component';
import { SignUpComponent } from 'src/app/shared/components/auth/sign-up/sign-up.component';
import { LandingComponent } from './landing.component';
import { AuthComponent } from 'src/app/shared/components/auth/auth.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { LoginGuard } from 'src/app/shared/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: SelectorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'auth',
        component: AuthComponent,
        canActivateChild: [LoginGuard],
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'forgot-password',
            component: ForgotPasswordComponent,
          },
          {
            path: 'reset-password',
            component: ResetPasswordComponent,
          },
          {
            path: 'sign-up',
            component: SignUpComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
