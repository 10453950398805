export const usersTable = {
  headings: ['Name', 'Email Address', 'Contact number', 'Role'],
  dataAttr: ['name', 'email', 'contact', 'role'],
};

export const assetsTable = {
  headings: ['Display name', 'Full name', 'Type', 'Serial / Code'],
  dataAttr: ['displayName', 'name', 'assetType', 'serialCode'],
};

export const groupsTable = {
  headings: ['Display name', 'Full name', 'Type', 'Serial / Code'],
  dataAttr: ['displayName', 'name', 'groupType', 'serialCode'],
};
