import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssetRoutingModule } from './asset-routing.module';
import { LinkComponent } from './link/link.component';
import { FeedbackComponent } from './feedback/feedback.component';

@NgModule({
  declarations: [LinkComponent, FeedbackComponent],
  imports: [CommonModule, AssetRoutingModule],
})
export class AssetModule {}
