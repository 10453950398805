import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import {
  delay,
  take,
  map,
  expand,
  distinctUntilChanged,
  takeWhile,
  concatMap,
  filter,
  retryWhen,
  tap,
} from 'rxjs/internal/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  constructor(
    private http: HttpClient,
    private fn: AngularFireFunctions,
    private toastService: ToastService
  ) {
    if (location.hostname !== 'assetio.co.za') {
      fn.useFunctionsEmulator('http://172.16.5.204:5001');
    }
  }

  public usersSub = new BehaviorSubject([]);

  public registerNewFingerprint(
    userId: string,
    terminalId: string
  ): Observable<any> {
    return new Observable((observer) => {
      this.beginCapture(1)
        .pipe(
          take(1),
          concatMap(() => this.getStep()),
          concatMap((step) => {
            observer.next({ step });
            if (step === 3) {
              return this.validateRegistration();
            }
            return EMPTY;
          })
        )
        .subscribe(
          ({ valid, template }) => {
            observer.next({ valid });

            if (valid) {
              this.fn
                .httpsCallable('terminal-updateFingerTemplate')({
                  terminalId,
                  userId,
                  template,
                })
                .subscribe(
                  () => {
                    return observer.complete();
                  },
                  (err) => {
                    observer.error(err);
                  }
                );
            }
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  public authUserWithFingerprint(): Observable<any> {
    return this.beginCapture(2).pipe(
      take(1),
      concatMap(() => this.getStep().pipe(filter((x) => x === 3))),
      concatMap(() => this.getTemplate()),
      concatMap((template) => {
        const users = this.usersSub.value;
        return this.matchTemplate({ ...users[0] }, template).pipe(
          expand(({ valid }, index) => {
            if (!valid && index === users.length) {
              return throwError(
                'No user found, please try again. If the problem persists, please speak to an administrator.'
              );
            }
            if (!valid) {
              return this.matchTemplate({ ...users[index] }, template);
            }
            return EMPTY;
          }),
          distinctUntilChanged((prev, cur) => prev.valid === cur.valid)
        );
      })
    );
  }

  private beginCapture(type: number): Observable<any> {
    return this.http
      .get(
        `http://127.0.0.1:22001/zkbioonline/fingerprint/beginCapture?type=${type}&FakeFunOn=1`
      )
      .pipe(
        map((res: any) => {
          if (res.ret === -2001) {
            throw new Error('Error communicating with the scanner.');
          }
        })
      );
  }

  private getImage(): Observable<number> {
    return this.http
      .get('http://127.0.0.1:22001/zkbioonline/fingerprint/getImage')
      .pipe(
        delay(100),
        map((res: any) => {
          if (res.ret === 0) {
            const index = res.data.enroll_index;
            return index;
          }
          return 0;
        })
      );
  }

  private getStep(): Observable<number> {
    return this.getImage().pipe(
      expand((step: number) => {
        if (step < 3) {
          return this.getImage();
        }
        return EMPTY;
      }),
      distinctUntilChanged(),
      takeWhile((step) => step < 3, true)
    );
  }

  private validateRegistration(): Observable<{
    valid: boolean;
    template?: string;
  }> {
    let template: string;
    return this.getTemplate().pipe(
      concatMap((initialTemplate) => {
        template = initialTemplate;
        return this.beginCapture(2);
      }),
      concatMap(() => this.getStep().pipe(filter((x) => x === 3))),
      concatMap(() => this.getTemplate()),
      concatMap((postTemplate) => {
        return this.matchTemplate({ template }, postTemplate);
      }),
      take(1)
    );
  }

  private getTemplate(): Observable<string | null> {
    return this.http
      .get('http://127.0.0.1:22001/zkbioonline/fingerprint/getTemplate')
      .pipe(
        take(1),
        map((res: any) => {
          if (res.ret === 0) {
            const template = res.data.template;
            return template;
          }
          throw new Error('No template.');
        })
      );
  }

  private matchTemplate(
    data: { template: string; name?: string },
    ver: string
  ): Observable<{ valid: boolean; template?: string; name?: string }> {
    const { template } = data;
    const headers = new HttpHeaders({
      'Content-Type': 'text/plain',
    });
    return this.http
      .post(
        'http://127.0.0.1:22001/zkbioonline/fingerprint/verify',
        {
          reg: template,
          ver,
        },
        {
          headers,
        }
      )
      .pipe(
        take(1),
        map((res: any) => {
          if (res.score > 0) {
            return { valid: true, ...data };
          }
          return { valid: false };
        })
      );
  }

  private cancelScan(): void {
    this.http
      .get('http://127.0.0.1:22001/zkbioonline/fingerprint/cancelCapture')
      .subscribe();
  }
}
