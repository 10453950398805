import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {
  constructor() {}

  public windows = false;

  ngOnInit(): void {
    if (window.navigator.platform === 'Win32') {
      this.windows = true;
    }
  }
}
