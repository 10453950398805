<div class="container">
  <div class="card-alt card-selector" routerLink="/dashboard">
    <svg class="illustration" viewBox="0 0 217.398 176.124">
      <g id="undraw_dashboard_nklg" transform="translate(-72.367 -133.05)">
        <ellipse
          id="Ellipse_30"
          data-name="Ellipse 30"
          cx="69.483"
          cy="3.101"
          rx="69.483"
          ry="3.101"
          transform="translate(105.345 302.973)"
          fill="#28d8a1"
          opacity="0.1"
        />
        <path
          id="Path_240"
          data-name="Path 240"
          d="M531.25,742.722V744.6H475.84v-1.521a18.211,18.211,0,0,0,1.253-33.634h53.781a18.21,18.21,0,0,0,.376,33.282Z"
          transform="translate(-315.183 -442.102)"
          fill="#c8cad7"
        />
        <path
          id="Path_241"
          data-name="Path 241"
          d="M534.859,709.44a18.237,18.237,0,0,0-10.064,12.074H491.144A18.241,18.241,0,0,0,481.08,709.44Z"
          transform="translate(-319.167 -442.102)"
          opacity="0.1"
        />
        <path
          id="Path_242"
          data-name="Path 242"
          d="M211,669.6v10.519c0,5.808,4.251,10.517,9.5,10.517H383.533c5.245,0,9.5-4.708,9.5-10.517V669.6Z"
          transform="translate(-113.801 -411.808)"
          fill="#c8cad7"
        />
        <path
          id="Path_243"
          data-name="Path 243"
          d="M531.25,842.234v1.874H475.84v-1.522a18.208,18.208,0,0,0,4.272-2.276h47.74A18.305,18.305,0,0,0,531.25,842.234Z"
          transform="translate(-315.183 -541.614)"
          opacity="0.1"
        />
        <rect
          id="Rectangle_157"
          data-name="Rectangle 157"
          width="92.033"
          height="5.552"
          rx="2.776"
          transform="translate(142.346 299.281)"
          fill="#c8cad7"
        />
        <path
          id="Path_244"
          data-name="Path 244"
          d="M393.028,158.481a9.493,9.493,0,0,0-9.5-9.481H220.5a9.493,9.493,0,0,0-9.5,9.481V275.788H393.028Z"
          transform="translate(-113.801 -15.95)"
          fill="#474157"
        />
        <path
          id="Path_245"
          data-name="Path 245"
          d="M403.859,191.415v100.8a4.672,4.672,0,0,1-4.675,4.675H236.435a4.672,4.672,0,0,1-4.675-4.675v-100.8a4.675,4.675,0,0,1,4.675-4.675H399.172a4.675,4.675,0,0,1,4.687,4.675Z"
          transform="translate(-129.587 -44.647)"
          fill="#4c4c78"
        />
        <circle
          id="Ellipse_31"
          data-name="Ellipse 31"
          cx="2.192"
          cy="2.192"
          r="2.192"
          transform="translate(186.023 135.372)"
          fill="#fff"
        />
        <circle
          id="Ellipse_32"
          data-name="Ellipse 32"
          cx="5.406"
          cy="5.406"
          r="5.406"
          transform="translate(182.809 263.344)"
          fill="#fff"
        />
        <path
          id="Path_246"
          data-name="Path 246"
          d="M268.126,196.5V304.309H236.435a4.672,4.672,0,0,1-4.675-4.675v-100.8l.17-.635.455-1.7Z"
          transform="translate(-129.587 -52.068)"
          fill="#fff"
        />
        <path
          id="Path_247"
          data-name="Path 247"
          d="M237.192,187.65v9.7H196.76v-9.069l.17-.635Z"
          transform="translate(-94.587 -41.517)"
          fill="#4c4c78"
        />
        <path
          id="Path_248"
          data-name="Path 248"
          d="M403.859,191.422H231.76a4.672,4.672,0,0,1,4.675-4.672H399.172a4.672,4.672,0,0,1,4.687,4.672Z"
          transform="translate(-129.587 -44.655)"
          fill="#c8cad7"
        />
        <circle
          id="Ellipse_33"
          data-name="Ellipse 33"
          cx="1.169"
          cy="1.169"
          r="1.169"
          transform="translate(105.678 143.262)"
          fill="#ededf4"
        />
        <circle
          id="Ellipse_34"
          data-name="Ellipse 34"
          cx="1.169"
          cy="1.169"
          r="1.169"
          transform="translate(109.184 143.262)"
          fill="#ededf4"
        />
        <circle
          id="Ellipse_35"
          data-name="Ellipse 35"
          cx="1.169"
          cy="1.169"
          r="1.169"
          transform="translate(112.689 143.262)"
          fill="#ededf4"
        />
        <rect
          id="Rectangle_158"
          data-name="Rectangle 158"
          width="24.28"
          height="2.236"
          transform="translate(108.216 150.405)"
          fill="#28d8a1"
        />
        <rect
          id="Rectangle_159"
          data-name="Rectangle 159"
          width="24.28"
          height="2.236"
          transform="translate(108.216 161.307)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_160"
          data-name="Rectangle 160"
          width="24.28"
          height="2.236"
          transform="translate(108.216 169.016)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="24.28"
          height="2.236"
          transform="translate(108.216 176.724)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_162"
          data-name="Rectangle 162"
          width="24.28"
          height="2.236"
          transform="translate(108.216 184.43)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_163"
          data-name="Rectangle 163"
          width="24.28"
          height="2.236"
          transform="translate(108.216 192.138)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="24.28"
          height="2.236"
          transform="translate(108.216 199.847)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_165"
          data-name="Rectangle 165"
          width="24.28"
          height="2.236"
          transform="translate(108.216 207.553)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <rect
          id="Rectangle_166"
          data-name="Rectangle 166"
          width="107.588"
          height="0.359"
          transform="translate(158.417 158.626)"
          fill="#dce0ed"
          opacity="0.5"
        />
        <rect
          id="Rectangle_167"
          data-name="Rectangle 167"
          width="107.588"
          height="0.359"
          transform="translate(158.417 166.972)"
          fill="#dce0ed"
          opacity="0.5"
        />
        <rect
          id="Rectangle_168"
          data-name="Rectangle 168"
          width="107.588"
          height="0.359"
          transform="translate(158.417 175.32)"
          fill="#dce0ed"
          opacity="0.5"
        />
        <rect
          id="Rectangle_169"
          data-name="Rectangle 169"
          width="107.588"
          height="0.359"
          transform="translate(158.417 183.666)"
          fill="#dce0ed"
          opacity="0.5"
        />
        <rect
          id="Rectangle_170"
          data-name="Rectangle 170"
          width="107.588"
          height="0.359"
          transform="translate(158.417 192.011)"
          fill="#dce0ed"
          opacity="0.5"
        />
        <path
          id="Path_249"
          data-name="Path 249"
          d="M538.662,289.675H430.94V273.262l9.879,4.869,10.224,1.385,8.971-2.705,10.823-8.394,5.444-3.144,3.98-.767,3.807.2,6.263,3.093,8.511,5.422,6.68.86,5.3-1.826,11.226-9.2,5.732-2.044,6,.927,4.886,2.82Z"
          transform="translate(-272.655 -97.307)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <path
          id="Path_250"
          data-name="Path 250"
          d="M484.716,295.3c-9.7,0-18.666-5.933-18.776-6.009l.266-.4c.139.093,13.936,9.223,25.66,4.648,4.279-1.665,7.318-4.26,10.27-6.767,3.074-2.619,5.978-5.094,9.934-6.168a14.862,14.862,0,0,1,13.27,2.981c3.22,2.648,9.637,6.9,16.416,5.628,3.455-.647,6.369-3.172,9.453-5.844,5.9-5.128,12.029-10.421,22.593-2.82l-.28.388c-10.267-7.378-16.236-2.207-22.006,2.794-3.134,2.717-6.1,5.271-9.678,5.954-6.973,1.306-13.524-3.029-16.809-5.729a14.387,14.387,0,0,0-12.838-2.887c-3.855,1.045-6.709,3.484-9.75,6.067-2.974,2.533-6.05,5.154-10.4,6.85a20.1,20.1,0,0,1-7.325,1.311Z"
          transform="translate(-307.655 -113.09)"
          fill="#28d8a1"
        />
        <circle
          id="Ellipse_36"
          data-name="Ellipse 36"
          cx="2.667"
          cy="2.667"
          r="2.667"
          transform="translate(207.521 164.485)"
          fill="#28d8a1"
        />
        <g
          id="Group_95"
          data-name="Group 95"
          transform="translate(167.925 211.068)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_171"
            data-name="Rectangle 171"
            width="15.814"
            height="3.355"
            fill="#dce0ed"
          />
          <rect
            id="Rectangle_172"
            data-name="Rectangle 172"
            width="15.814"
            height="3.355"
            transform="translate(36.313)"
            fill="#dce0ed"
          />
          <rect
            id="Rectangle_173"
            data-name="Rectangle 173"
            width="15.814"
            height="3.355"
            transform="translate(72.626)"
            fill="#dce0ed"
          />
        </g>
        <ellipse
          id="Ellipse_37"
          data-name="Ellipse 37"
          cx="6.453"
          cy="1.09"
          rx="6.453"
          ry="1.09"
          transform="translate(276.86 292.078)"
          fill="#28d8a1"
          opacity="0.1"
        />
        <ellipse
          id="Ellipse_38"
          data-name="Ellipse 38"
          cx="6.453"
          cy="1.09"
          rx="6.453"
          ry="1.09"
          transform="translate(76.182 305.844)"
          fill="#28d8a1"
          opacity="0.1"
        />
        <ellipse
          id="Ellipse_39"
          data-name="Ellipse 39"
          cx="9.635"
          cy="1.629"
          rx="9.635"
          ry="1.629"
          transform="translate(259.555 303.222)"
          fill="#28d8a1"
        />
        <path
          id="Path_287"
          data-name="Path 287"
          d="M917.135,840.8a2.789,2.789,0,0,0,.918-1.387,1.231,1.231,0,0,0-.64-1.411,1.805,1.805,0,0,0-1.7.6,2.265,2.265,0,0,1-1.648.8,2.516,2.516,0,0,0,.776-2.351.977.977,0,0,0-.216-.479c-.328-.35-.92-.2-1.313.074-1.246.877-1.594,2.569-1.6,4.093a7.69,7.69,0,0,1-.022-1.677,1.733,1.733,0,0,0-.633-1.493,1.918,1.918,0,0,0-.958-.225,1.991,1.991,0,0,0-1.567.443,1.544,1.544,0,0,0,.06,1.917,6.237,6.237,0,0,0,1.622,1.3,3.626,3.626,0,0,1,1.16,1.1,1.165,1.165,0,0,1,.084.2h3.513A9.847,9.847,0,0,0,917.135,840.8Z"
          transform="translate(-643.963 -538.607)"
          fill="#28d8a1"
        />
        <path
          id="Path_288"
          data-name="Path 288"
          d="M139.95,807.19s1.318,1.723-.606,4.313-3.51,4.792-2.875,6.414c0,0,2.9-4.828,5.271-4.9S142.55,810.094,139.95,807.19Z"
          transform="translate(-57.021 -516.43)"
          fill="#28d8a1"
        />
        <path
          id="Path_289"
          data-name="Path 289"
          d="M140.2,807.19a2.159,2.159,0,0,1,.271.542c2.305,2.708,3.532,5.235,1.315,5.3-2.063.06-4.538,3.75-5.132,4.682a2.241,2.241,0,0,0,.069.213s2.9-4.828,5.271-4.9S142.8,810.094,140.2,807.19Z"
          transform="translate(-57.267 -516.43)"
          opacity="0.1"
        />
        <path
          id="Path_290"
          data-name="Path 290"
          d="M160.7,815.1c0,.606-.067,1.1-.151,1.1s-.151-.479-.151-1.1.084-.321.168-.321S160.7,814.5,160.7,815.1Z"
          transform="translate(-75.326 -522.15)"
          fill="#ffd037"
        />
        <path
          id="Path_291"
          data-name="Path 291"
          d="M161.877,818.048c-.532.29-1,.465-1.035.391s.357-.369.889-.659.323-.079.362,0S162.409,817.758,161.877,818.048Z"
          transform="translate(-75.66 -524.369)"
          fill="#ffd037"
        />
        <path
          id="Path_292"
          data-name="Path 292"
          d="M113.053,807.19s-1.318,1.723.606,4.313,3.51,4.792,2.875,6.414c0,0-2.9-4.828-5.271-4.9S110.453,810.094,113.053,807.19Z"
          transform="translate(-37.074 -516.43)"
          fill="#28d8a1"
        />
        <path
          id="Path_293"
          data-name="Path 293"
          d="M113.036,807.19a2.158,2.158,0,0,0-.271.542c-2.305,2.708-3.532,5.235-1.315,5.3,2.063.06,4.538,3.75,5.132,4.682a1.716,1.716,0,0,1-.072.213s-2.9-4.828-5.271-4.9S110.436,810.094,113.036,807.19Z"
          transform="translate(-37.058 -516.43)"
          opacity="0.1"
        />
        <path
          id="Path_294"
          data-name="Path 294"
          d="M112.22,815.1c0,.606.069,1.1.153,1.1s.151-.479.151-1.1-.084-.321-.168-.321S112.22,814.5,112.22,815.1Z"
          transform="translate(-38.69 -522.15)"
          fill="#ffd037"
        />
        <path
          id="Path_295"
          data-name="Path 295"
          d="M107.691,818.048c.532.29.994.465,1.035.391s-.357-.369-.889-.659-.323-.079-.364,0S107.159,817.758,107.691,818.048Z"
          transform="translate(-35 -524.369)"
          fill="#ffd037"
        />
        <path
          id="Path_296"
          data-name="Path 296"
          d="M114,844.736s3.68-.115,4.792-.9,5.662-1.735,5.938-.467,5.53,6.3,1.375,6.333-9.654-.647-10.761-1.32S114,844.736,114,844.736Z"
          transform="translate(-40.044 -543.467)"
          fill="#a8a8a8"
        />
        <path
          id="Path_297"
          data-name="Path 297"
          d="M126.147,855.556c-4.155.034-9.656-.647-10.763-1.32-.841-.515-1.179-2.358-1.289-3.206h-.125s.24,2.966,1.342,3.642,6.606,1.354,10.761,1.32c1.2,0,1.613-.436,1.591-1.069C127.5,855.312,127.038,855.549,126.147,855.556Z"
          transform="translate(-40.021 -549.765)"
          opacity="0.2"
        />
        <path
          id="Path_298"
          data-name="Path 298"
          d="M494.428,537.818a8.705,8.705,0,0,1,8.885-8.66h.069V526.93h-.069A10.935,10.935,0,0,0,492.2,537.818a11.085,11.085,0,0,0,3.808,8.363l1.578-1.58A8.866,8.866,0,0,1,494.428,537.818Z"
          transform="translate(-327.623 -303.323)"
          fill="#28d8a1"
        />
        <path
          id="Path_299"
          data-name="Path 299"
          d="M516.38,526.94v2.228a8.882,8.882,0,1,1-5.8,15.664L509,546.411a11.108,11.108,0,1,0,7.375-19.471Z"
          transform="translate(-340.397 -303.331)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <path
          id="Path_300"
          data-name="Path 300"
          d="M817.262,528.688a8.705,8.705,0,0,1,8.66,8.885v.069h2.228v-.069a10.937,10.937,0,0,0-10.888-11.113,11.079,11.079,0,0,0-8.363,3.807l1.579,1.579A8.866,8.866,0,0,1,817.262,528.688Z"
          transform="translate(-568.437 -302.966)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <path
          id="Path_301"
          data-name="Path 301"
          d="M818.75,550.655h-2.228a8.882,8.882,0,1,1-15.663-5.8l-1.572-1.579a11.108,11.108,0,1,0,19.471,7.375Z"
          transform="translate(-559.039 -315.756)"
          fill="#28d8a1"
        />
        <path
          id="Path_302"
          data-name="Path 302"
          d="M661.151,529.66l-.443,2.219a8.7,8.7,0,0,1,5.346,8.238,8.882,8.882,0,1,1-12.443-8.135l-.987-2a11.108,11.108,0,1,0,15.668,10.136A10.93,10.93,0,0,0,661.151,529.66Z"
          transform="translate(-444.626 -305.399)"
          fill="#28d8a1"
          opacity="0.5"
        />
        <path
          id="Path_303"
          data-name="Path 303"
          d="M679.142,528.618a7.763,7.763,0,0,1,3.093.642l.443-2.216a9.8,9.8,0,0,0-3.537-.654,11.062,11.062,0,0,0-4.553.98l1,2A8.793,8.793,0,0,1,679.142,528.618Z"
          transform="translate(-466.309 -302.913)"
          fill="#28d8a1"
        />
      </g>
    </svg>
    <span class="text-icon"
      >dashboard
      <svg
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
        data-attributes-set=",xmlns:xlink,xmlns,viewBox,preserveAspectRatio,xmlns:xlink,xmlns,viewBox,preserveAspectRatio"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M0 0h24v24H0z"
          fill="none"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
        ></path></svg
    ></span>
  </div>
  <div class="card-alt card-selector" *ngIf="windows" routerLink="/terminal">
    <svg class="illustration" viewBox="0 0 226.429 134.785">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="gray" stop-opacity="0.251" />
          <stop offset="0.54" stop-color="gray" stop-opacity="0.122" />
          <stop offset="1" stop-color="gray" stop-opacity="0.102" />
        </linearGradient>
      </defs>
      <g
        id="undraw_web_devices_ad58_1_"
        data-name="undraw_web_devices_ad58 (1)"
        transform="translate(0.001 0)"
      >
        <path
          id="Path_304"
          data-name="Path 304"
          d="M118.779,144.017a18.139,18.139,0,0,0-5.8.564,9.662,9.662,0,0,0-6.6,10.9c.77,4.341,3.938,8.124,7.225,11.581s6.848,6.875,8.651,11.007a8,8,0,0,1,.406,6.433c-1.409,3.175-5.658,4.949-9.737,5.318s-8.175-.35-12.268-.59a15.422,15.422,0,0,0-5.1.352,13.5,13.5,0,0,0-4.616,2.614c-5.308,4.222-9.557,9.982-8.87,16.034.777,6.858,7.711,12.278,15.3,15.186a43.95,43.95,0,0,1,7.388,3.119c5.83,3.627,7.14,10.429,6.559,16.432s-2.558,12.013-1.458,17.963c1.295,6.921,7.026,13.174,14.756,16.094,6.887,2.6,15.506,2.429,21.621-1.241a26.163,26.163,0,0,0,7.2-6.858,55.325,55.325,0,0,0,7.154-12.39c.853-2.062,1.654-4.28,3.644-5.714a12.523,12.523,0,0,1,4.346-1.759c4.693-1.156,9.637-1.472,14.374-2.5s9.5-2.957,12.079-6.382c3.644-4.859,1.943-11.083,1.96-16.8.029-13.036,9.5-24.7,13-37.412,1.142-4.152,1.567-8.746-.984-12.45-1.732-2.514-4.655-4.329-7.725-5.731-6.756-3.083-14.435-4.455-21.988-5.792-10.5-1.856-23.244-2.1-31.38-8.1A50.7,50.7,0,0,0,118.779,144.017Z"
          transform="translate(-82 -144)"
          fill="#28d8a1"
          opacity="0.2"
        />
        <path
          id="Path_310"
          data-name="Path 310"
          d="M499.372,318.9H483.766a5.2,5.2,0,0,0,.544-2.325V245.505a5.225,5.225,0,0,0-5.225-5.225H358.264a5.225,5.225,0,0,0-5.228,5.225v71.075a5.2,5.2,0,0,0,.544,2.325H337.422a1.382,1.382,0,0,0-1.382,1.382V321.4a7.73,7.73,0,0,0,7.73,7.723H493.024a7.73,7.73,0,0,0,7.73-7.73v-1.115a1.382,1.382,0,0,0-1.382-1.377ZM403.2,323.324l-1.331-1.518h33.214l-1.482,1.521Z"
          transform="translate(-274.326 -216.89)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_349"
          data-name="Path 349"
          d="M10.089,0H115.037c12.032,0,13.253-.9,13.253,11.128V68.436c0,12.032,1.321,11.243-10.711,11.243h-95.8A21.786,21.786,0,0,1-.008,57.893V12.908C-.008.876-1.943,0,10.089,0Z"
          transform="translate(80.202 24.396)"
          fill="#535461"
        />
        <rect
          id="Rectangle_175"
          data-name="Rectangle 175"
          width="122.626"
          height="65.094"
          transform="translate(82.893 36.01)"
          fill="#fff"
        />
        <circle
          id="Ellipse_41"
          data-name="Ellipse 41"
          cx="1.081"
          cy="1.081"
          r="1.081"
          transform="translate(143.126 29.256)"
          fill="#fff"
        />
        <path
          id="Path_311"
          data-name="Path 311"
          d="M443.289,560.73l-4.215,4.322H409.363l-3.807-4.322H345.081a1.351,1.351,0,0,0-1.351,1.351v1.088a7.555,7.555,0,0,0,7.555,7.555H497.155a7.555,7.555,0,0,0,7.555-7.555v-1.088a1.351,1.351,0,0,0-1.351-1.351Z"
          transform="translate(-280.148 -459.492)"
          fill="#535461"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          d="M404.422,301.65H352.694a4.74,4.74,0,0,0-4.745,4.732v71.665a4.74,4.74,0,0,0,4.745,4.732h51.728a4.74,4.74,0,0,0,4.745-4.732V306.382a4.74,4.74,0,0,0-4.745-4.732Z"
          transform="translate(-283.343 -263.352)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_350"
          data-name="Path 350"
          d="M8.811-.009h37.9c10.346,0,13.048-1.845,13.048,8.5v58c0,10.346,1.92,12.936-8.426,12.936H12.508c-10.346,0-12.513,2.263-12.513-8.083V13.114C-.005,2.768-1.535-.009,8.811-.009Z"
          transform="translate(65.339 39.279)"
          fill="#535461"
        />
        <rect
          id="Rectangle_177"
          data-name="Rectangle 177"
          width="53.688"
          height="64.411"
          transform="translate(68.37 46.784)"
          fill="#fff"
        />
        <circle
          id="Ellipse_42"
          data-name="Ellipse 42"
          cx="0.729"
          cy="0.729"
          r="0.729"
          transform="translate(94.243 42.185)"
          fill="#fff"
        />
        <circle
          id="Ellipse_43"
          data-name="Ellipse 43"
          cx="2.186"
          cy="2.186"
          r="2.186"
          transform="translate(92.785 112.636)"
          fill="#fff"
        />
        <path
          id="Path_314"
          data-name="Path 314"
          d="M281.779,571.475s-3.221-10.932.6-18.983a17.271,17.271,0,0,0,1.4-10.837,29.046,29.046,0,0,0-1.533-5.085"
          transform="translate(-232.287 -441.202)"
          fill="none"
          stroke="#535461"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <path
          id="Path_315"
          data-name="Path 315"
          d="M282.309,508.059c0,1.341-2.429,5.226-2.429,5.226s-2.429-3.887-2.429-5.226a2.429,2.429,0,0,1,4.859,0Z"
          transform="translate(-229.97 -417.778)"
          fill="#28d8a1"
        />
        <path
          id="Path_316"
          data-name="Path 316"
          d="M298.8,534.219c-.729,1.125-4.878,3.068-4.878,3.068s.075-4.582.8-5.706a2.429,2.429,0,1,1,4.074,2.638Z"
          transform="translate(-242.438 -436.514)"
          fill="#28d8a1"
        />
        <path
          id="Path_317"
          data-name="Path 317"
          d="M295.29,584.228c-1.2.6-5.76.187-5.76.187s2.378-3.916,3.574-4.518a2.429,2.429,0,0,1,2.186,4.334Z"
          transform="translate(-239.115 -473.839)"
          fill="#28d8a1"
        />
        <path
          id="Path_318"
          data-name="Path 318"
          d="M286.206,624.358c-1.079.8-5.646,1.156-5.646,1.156s1.681-4.261,2.76-5.058a2.429,2.429,0,0,1,2.886,3.9Z"
          transform="translate(-232.324 -504.4)"
          fill="#28d8a1"
        />
        <path
          id="Path_319"
          data-name="Path 319"
          d="M270.353,546.654c.972.933,5.439,1.9,5.439,1.9s-1.1-4.448-2.058-5.381a2.429,2.429,0,1,0-3.382,3.481Z"
          transform="translate(-223.975 -445.621)"
          fill="#28d8a1"
        />
        <path
          id="Path_320"
          data-name="Path 320"
          d="M258,591.628c1.2.6,5.76.187,5.76.187s-2.378-3.916-3.574-4.519A2.429,2.429,0,1,0,258,591.63Z"
          transform="translate(-214.16 -479.371)"
          fill="#28d8a1"
        />
        <path
          id="Path_321"
          data-name="Path 321"
          d="M255.671,638.958c1.079.8,5.646,1.156,5.646,1.156s-1.681-4.261-2.76-5.058a2.429,2.429,0,0,0-2.886,3.9Z"
          transform="translate(-212.807 -515.453)"
          fill="#28d8a1"
        />
        <path
          id="Path_345"
          data-name="Path 345"
          d="M797.283,680a.223.223,0,0,1-.022.068,7.114,7.114,0,0,0-.381,1.285c.058-.19.126-.384.2-.578A3.524,3.524,0,0,0,797.283,680Z"
          transform="translate(-623.212 -549.788)"
          fill="#dddbe2"
        />
        <path
          id="Path_348"
          data-name="Path 348"
          d="M702.274,686c-.129.034-.255.207-.374.442l.039-.015c.107-.029.289-.243.51-.559A.47.47,0,0,1,702.274,686Z"
          transform="translate(-551.306 -554.232)"
          fill="#dddbe2"
        />
      </g>
    </svg>
    <span class="text-icon"
      >terminal
      <svg
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
        data-attributes-set=",xmlns:xlink,xmlns,viewBox,preserveAspectRatio,xmlns:xlink,xmlns,viewBox,preserveAspectRatio"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M0 0h24v24H0z"
          fill="none"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
        ></path></svg
    ></span>
  </div>
  <div class="card-alt card-selector" routerLink="/asset/link">
    <svg class="illustration" viewBox="0 0 206.707 152.301">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="gray" stop-opacity="0.251" />
          <stop offset="0.54" stop-color="gray" stop-opacity="0.122" />
          <stop offset="1" stop-color="gray" stop-opacity="0.102" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0.5"
          x2="0.5"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="0.5"
          x2="0.5"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="1.105"
          y1="1.163"
          x2="1.105"
          y2="0.163"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="0.5"
          x2="0.5"
          xlink:href="#linear-gradient"
        />
      </defs>
      <g
        id="undraw_cloud_sync_2aph_1_"
        data-name="undraw_cloud_sync_2aph (1)"
        transform="translate(-61.27)"
      >
        <g
          id="Group_96"
          data-name="Group 96"
          transform="translate(174.899 18.554)"
          opacity="0.8"
        >
          <rect
            id="Rectangle_178"
            data-name="Rectangle 178"
            width="93.078"
            height="103.885"
            fill="url(#linear-gradient)"
          />
        </g>
        <rect
          id="Rectangle_179"
          data-name="Rectangle 179"
          width="90"
          height="32.263"
          transform="translate(176.438 87.912)"
          fill="#fff"
        />
        <rect
          id="Rectangle_180"
          data-name="Rectangle 180"
          width="3.488"
          height="16.531"
          transform="translate(183.826 95.566)"
          fill="#64ffda"
        />
        <g
          id="Group_97"
          data-name="Group 97"
          transform="translate(190.71 95.566)"
          opacity="0.7"
        >
          <rect
            id="Rectangle_181"
            data-name="Rectangle 181"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_98"
          data-name="Group 98"
          transform="translate(197.594 95.566)"
          opacity="0.6"
        >
          <rect
            id="Rectangle_182"
            data-name="Rectangle 182"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_99"
          data-name="Group 99"
          transform="translate(204.481 95.566)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_183"
            data-name="Rectangle 183"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_100"
          data-name="Group 100"
          transform="translate(211.365 95.566)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_184"
            data-name="Rectangle 184"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_101"
          data-name="Group 101"
          transform="translate(218.252 95.566)"
          opacity="0.4"
        >
          <rect
            id="Rectangle_185"
            data-name="Rectangle 185"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_102"
          data-name="Group 102"
          transform="translate(225.136 95.566)"
          opacity="0.3"
        >
          <rect
            id="Rectangle_186"
            data-name="Rectangle 186"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <circle
          id="Ellipse_44"
          data-name="Ellipse 44"
          cx="8.416"
          cy="8.416"
          r="8.416"
          transform="translate(245.282 95.31)"
          fill="#28d8a1"
        />
        <rect
          id="Rectangle_187"
          data-name="Rectangle 187"
          width="90"
          height="32.263"
          transform="translate(176.438 54.394)"
          fill="#fff"
        />
        <rect
          id="Rectangle_188"
          data-name="Rectangle 188"
          width="3.488"
          height="16.531"
          transform="translate(183.826 62.046)"
          fill="#64ffda"
        />
        <g
          id="Group_103"
          data-name="Group 103"
          transform="translate(190.71 62.046)"
          opacity="0.7"
        >
          <rect
            id="Rectangle_189"
            data-name="Rectangle 189"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_104"
          data-name="Group 104"
          transform="translate(197.594 62.046)"
          opacity="0.6"
        >
          <rect
            id="Rectangle_190"
            data-name="Rectangle 190"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_105"
          data-name="Group 105"
          transform="translate(204.481 62.046)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_191"
            data-name="Rectangle 191"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_106"
          data-name="Group 106"
          transform="translate(211.365 62.046)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_192"
            data-name="Rectangle 192"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_107"
          data-name="Group 107"
          transform="translate(218.252 62.046)"
          opacity="0.4"
        >
          <rect
            id="Rectangle_193"
            data-name="Rectangle 193"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_108"
          data-name="Group 108"
          transform="translate(225.136 62.046)"
          opacity="0.3"
        >
          <rect
            id="Rectangle_194"
            data-name="Rectangle 194"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <circle
          id="Ellipse_45"
          data-name="Ellipse 45"
          cx="8.416"
          cy="8.416"
          r="8.416"
          transform="translate(245.282 61.792)"
          fill="#28d8a1"
        />
        <rect
          id="Rectangle_195"
          data-name="Rectangle 195"
          width="90"
          height="32.263"
          transform="translate(176.438 20.876)"
          fill="#fff"
        />
        <rect
          id="Rectangle_196"
          data-name="Rectangle 196"
          width="3.488"
          height="16.531"
          transform="translate(183.826 28.529)"
          fill="#64ffda"
        />
        <g
          id="Group_109"
          data-name="Group 109"
          transform="translate(190.71 28.529)"
          opacity="0.7"
        >
          <rect
            id="Rectangle_197"
            data-name="Rectangle 197"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_110"
          data-name="Group 110"
          transform="translate(197.594 28.529)"
          opacity="0.6"
        >
          <rect
            id="Rectangle_198"
            data-name="Rectangle 198"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_111"
          data-name="Group 111"
          transform="translate(204.481 28.529)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_199"
            data-name="Rectangle 199"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_112"
          data-name="Group 112"
          transform="translate(211.365 28.529)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_200"
            data-name="Rectangle 200"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_113"
          data-name="Group 113"
          transform="translate(218.252 28.529)"
          opacity="0.4"
        >
          <rect
            id="Rectangle_201"
            data-name="Rectangle 201"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <g
          id="Group_114"
          data-name="Group 114"
          transform="translate(225.136 28.529)"
          opacity="0.3"
        >
          <rect
            id="Rectangle_202"
            data-name="Rectangle 202"
            width="3.488"
            height="16.531"
            fill="#64ffda"
          />
        </g>
        <circle
          id="Ellipse_46"
          data-name="Ellipse 46"
          cx="9.072"
          cy="9.072"
          r="9.072"
          transform="translate(244.626 27.618)"
          fill="url(#linear-gradient)"
        />
        <circle
          id="Ellipse_47"
          data-name="Ellipse 47"
          cx="9.072"
          cy="9.072"
          r="9.072"
          transform="translate(244.626 61.362)"
          fill="url(#linear-gradient-3)"
        />
        <circle
          id="Ellipse_48"
          data-name="Ellipse 48"
          cx="9.072"
          cy="9.072"
          r="9.072"
          transform="translate(244.626 94.877)"
          fill="url(#linear-gradient-4)"
        />
        <circle
          id="Ellipse_49"
          data-name="Ellipse 49"
          cx="8.416"
          cy="8.416"
          r="8.416"
          transform="translate(245.282 28.274)"
          fill="#28d8a1"
        />
        <rect
          id="Rectangle_203"
          data-name="Rectangle 203"
          width="68.6"
          height="123.825"
          rx="10.24"
          transform="translate(61.27 28.475)"
          fill="url(#linear-gradient-5)"
        />
        <rect
          id="Rectangle_204"
          data-name="Rectangle 204"
          width="66.748"
          height="120.48"
          rx="13.64"
          transform="translate(62.199 30.149)"
          fill="#fff"
        />
        <path
          id="Path_355"
          data-name="Path 355"
          d="M283.024,206.64a5.411,5.411,0,0,1-5.342,4.6H254.638a5.411,5.411,0,0,1-5.342-4.6H237.084a2.544,2.544,0,0,0-2.544,2.544V318.552a2.544,2.544,0,0,0,2.544,2.544h58.335a2.544,2.544,0,0,0,2.544-2.544V209.174a2.544,2.544,0,0,0-2.544-2.544Z"
          transform="translate(-170.683 -173.468)"
          fill="#28d8a1"
        />
        <rect
          id="Rectangle_205"
          data-name="Rectangle 205"
          width="14.875"
          height="0.929"
          rx="0.464"
          transform="translate(88.229 34.612)"
          fill="#dbdbdb"
        />
        <circle
          id="Ellipse_51"
          data-name="Ellipse 51"
          cx="0.557"
          cy="0.557"
          r="0.557"
          transform="translate(105.706 34.426)"
          fill="#dbdbdb"
        />
        <g
          id="Group_115"
          data-name="Group 115"
          transform="translate(76.435 71.253)"
          opacity="0.5"
        >
          <circle
            id="Ellipse_52"
            data-name="Ellipse 52"
            cx="19.136"
            cy="19.136"
            r="19.136"
            transform="translate(0)"
            fill="url(#linear-gradient-6)"
          />
        </g>
        <circle
          id="Ellipse_53"
          data-name="Ellipse 53"
          cx="18.012"
          cy="18.012"
          r="18.012"
          transform="translate(77.559 72.378)"
          fill="#69f0ae"
        />
        <path
          id="Path_359"
          data-name="Path 359"
          d="M157.124,319.831l6.754,6.19,9.568-14.071,3.378,2.814-12.947,16.887-9.568-10.7Z"
          transform="translate(-69.37 -231.844)"
          fill="#fff"
        />
        <path
          id="Path_364"
          data-name="Path 364"
          d="M207.36,23.9V1H308.1V14.738"
          transform="translate(-108.924)"
          fill="none"
          stroke="#3ad29f"
          stroke-miterlimit="10"
          stroke-width="2"
          stroke-dasharray="12"
        />
        <path
          id="Path_365"
          data-name="Path 365"
          d="M441.36,518.9h43.5V496"
          transform="translate(-283.394 -369.071)"
          fill="none"
          stroke="#3ad29f"
          stroke-miterlimit="10"
          stroke-width="2"
          stroke-dasharray="12"
        />
      </g>
    </svg>
    <span class="text-icon"
      >device link
      <svg
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
        data-attributes-set=",xmlns:xlink,xmlns,viewBox,preserveAspectRatio,xmlns:xlink,xmlns,viewBox,preserveAspectRatio"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M0 0h24v24H0z"
          fill="none"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
        ></path></svg
    ></span>
  </div>
</div>
