import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private fn: AngularFireFunctions,
    private fs: AngularFirestore,
    private authService: AuthService,
    private router: Router
  ) {
    if (location.hostname !== 'assetio.co.za') {
      fn.useFunctionsEmulator('http://172.16.5.204:5001');
    }
  }

  private get collection(): string {
    return `organisations/${this.authService.orgId$.value}/users`;
  }

  public createUser(data: any): Observable<any> {
    const { checkboxControl, formValue } = data;
    const { groups } = checkboxControl;
    const {
      section1: { name, surname, contact },
      section2: { email, pwd },
      section3: { role, notes },
    } = formValue;
    return this.fn.httpsCallable('users-newUser')({
      name,
      surname,
      contact,
      email,
      pwd,
      role,
      groups,
      notes,
    });
  }

  public getUserCollection(): Observable<any> {
    return this.fs
      .collection(this.collection, (ref) =>
        ref
          .orderBy('name')
          .where('name', '>=', '')
          .where('name', '<=', '' + '\uf8ff')
      )
      .snapshotChanges()
      .pipe(
        map((arr) => {
          if (!arr) {
            return new Error(
              'User collection empty. Add a user and try again.'
            );
          }

          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc['id'];
            const data: any = cur.payload.doc.data();

            data.uid = id;

            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  public getUser(id: string): Observable<any> {
    return this.fs
      .collection(this.collection)
      .doc(id)
      .get()
      .pipe(
        map((doc) => {
          if (!doc.exists) {
            return new Error('User does not exist');
          }
          const data = doc.data();
          data['id'] = doc.id;

          return data;
        })
      );
  }

  public updateUser(id: string, updatedData: any): Observable<any> {
    return this.fn
      .httpsCallable('users-updateUser')({ id, updatedData })
      .pipe(take(1));
  }

  public deleteUser(id: string): void {
    this.fn
      .httpsCallable('users-deleteUser')(id)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('dashboard/users');
      });
  }

  public deleteUsers(ids: Array<string>): Observable<any> {
    return this.fn.httpsCallable('users-deleteUser')(ids).pipe(take(1));
  }

  // public updatePassword()
}
