import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {}

  public tableFilter = new BehaviorSubject<string>('');

  public setFilter(filter: string) {
    this.tableFilter.next(filter);
  }

  public filterData(data: any[]) {
    return this.tableFilter.pipe(
      map((filter) => {
        if (filter !== '') {
          const options = {
            threshold: 0.3,
            keys: [
              'name',
              'displayName',
              'serialCode',
              'groupType',
              'assetType',
              'serialCode',
              'email',
              'contact',
            ],
          };
          const fuse = new Fuse(data, options);
          return fuse.search(filter).map((v) => v.item);
        }
        return data;
      })
    );
  }
}
