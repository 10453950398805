import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import kjua from 'kjua-svg';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  constructor(private router: Router) {}

  public test!: string;
  public code = ['3', 'z', '9'];
  public svgData: any;

  ngOnInit(): void {
    const test = kjua({
      render: 'svg',
      crisp: true,
      minVersion: 1,
      ecLevel: 'L',
      size: 120,
      text: 'assetio.co.za/qr?type=link&data=hHmYmJFgPKrVr8CIhyA2',
      quiet: 0,
      mode: 'plain',
    });

    this.svgData = test.lastChild.attributes.d.value;

    console.dir(test);
  }
}
