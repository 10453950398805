import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { AssetService } from 'src/app/shared/services/asset.service';
import { FormService } from 'src/app/shared/services/form.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { QrCodeService } from 'src/app/shared/services/qrcode.service';
import { TerminalService } from 'src/app/shared/services/terminal.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public step: any = 0;
  public admin = false;
  public mode = 'dashboard';
  public formType: string;
  public formLength: number;
  public modal: any = false;

  public qrCode: string;
  public errorMessage: string;
  private responseId: string;
  public changed = true;
  public loading = false;
  public qrLoading = false;
  private checkboxControlData: any = {};

  newForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private qrService: QrCodeService,
    private userService: UserService,
    private assetService: AssetService,
    private groupService: GroupService,
    private terminalService: TerminalService,
    public formService: FormService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(({ type, data }) => {
      const newForm = data.newForm ?? data;

      const form = {};
      Object.keys(newForm.formGroup).forEach((cur) => {
        if (newForm.formGroup[cur]) {
          form[cur] = this.fb.group(newForm.formGroup[cur]);
        }
      });

      this.newForm = this.fb.group(form);
      this.formType = type;
      this.formLength = Object.keys(this.newForm.controls).length - 1;

      this.newForm
        .get(['section2', 'confPwd'])
        ?.setValidators([
          this.matchPasswords(),
          Validators.required,
          Validators.minLength(6),
        ]);

      if (this.mode === 'dashboard') {
        this.newForm.valueChanges.subscribe(() => {
          this.changed = true;
        });

        this.qrService.formQRCode.subscribe((svgData: string) => {
          this.qrCode = svgData;
          this.qrLoading = false;
          this.changed = false;
        });
      }
    });
  }

  public openModal(formControlName: string, section?: any): void {
    if (
      this.formType === 'groups' &&
      this.newForm.get(['section2', 'groupType']).invalid &&
      formControlName !== 'groupType'
    ) {
      return this.newForm.get(['section2', 'groupType']).markAsTouched();
    }
    if (
      this.formType === 'assets' &&
      this.newForm.get(['section2', 'assetType']).invalid &&
      formControlName !== 'assetType'
    ) {
      return this.newForm.get(['section2', 'assetType']).markAsTouched();
    }
    const limit =
      this.checkboxControlData?.assetType?.name ??
      this.checkboxControlData?.groupType?.name ??
      'user';
    this.modal = {
      data: this.checkboxControlData,
      formControlName,
      section,
      limitType: limit.toLowerCase(),
    };
  }

  public selection({
    section,
    data,
  }: {
    section: string;
    data: Array<any>;
  }): void {
    this.modal = false;

    const formControlName = Object.keys(data)[0];
    const formControlData = data[formControlName];
    const length = formControlData.length;
    this.checkboxControlData[formControlName] = formControlData;

    if (formControlName === 'terminal') {
      const { uid } = formControlData;
      this.addUserToQueue(uid);
    }

    if (length > 1) {
      return this.newForm.patchValue({
        [section]: {
          [formControlName]: `${formControlData[0].name} + ${length - 1} more`,
        },
      });
    }
    this.newForm.patchValue({
      [section]: formControlData[0]
        ? {
            [formControlName]: formControlData[0].name,
          }
        : { [formControlName]: formControlData.name },
    });
  }

  private addUserToQueue(terminalId: string): void {
    this.loading = true;
    this.terminalService
      .addUserToQueue(terminalId, this.responseId)
      .subscribe((res) => {
        this.loading = false;
        // console.log(res);
      });
  }

  public reloadQrCode(): void {
    if (this.changed && !this.qrLoading) {
      this.qrLoading = true;
      this.qrService.generateFormQRCode(
        this.formType,
        this.step,
        this.newForm.value
      );
    }
  }

  public next(formGroupName): void {
    const formGroup = this.newForm.get(formGroupName);
    if (formGroup.invalid) {
      return formGroup.markAllAsTouched();
    }

    if (this.formLength === this.step) {
      const formValue = this.newForm.value;
      const checkboxControl = this.checkboxControlData;
      this.loading = true;

      this.create({ formValue, checkboxControl }).subscribe((res) => {
        if (!res.errorInfo) {
          this.responseId = res.uid;
          this.errorMessage = '';
          this.qrCode = this.qrService.generateSVGFromToken(res.uid);
          this.loading = false;
          return (this.step = 'final');
        }
        this.loading = false;
        return (this.errorMessage = res.errorInfo.message);
      });
    } else {
      this.step++;
    }
  }

  private create(data): Observable<any> {
    switch (this.formType) {
      case 'users':
        return this.userService.createUser(data);
      case 'assets':
        return this.assetService.createAsset(data);
      case 'groups':
        return this.groupService.createGroup(data);

      default:
        return null;
    }
  }

  public previous(): void {
    if (this.modal) {
      this.modal = false;
      return;
    }
    this.errorMessage = '';
    if (this.step === 0 || this.step === 'final') {
      this.newForm.reset();
      this.qrCode = null;
      this.loading = false;
      this.step = 0;
    } else {
      this.step--;
    }
  }

  public finalStepHandler(): void {
    this.openModal('terminal');
  }

  private matchPasswords(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pass = this.newForm.get(['section2', 'pwd'])?.value;
      const confirmPass = control.value;

      return isEqual(pass, confirmPass) ? null : { noMatch: true };
    };
  }
}
