import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { assetFormNew, groupFormNew, userFormNew } from '../models/form.model';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}

  public getNewForm(formType: string): any {
    switch (formType) {
      case 'users':
        return userFormNew;
      case 'assets':
        return assetFormNew;
      case 'groups':
        return groupFormNew;
    }
  }

  public inputValidation(
    form: FormGroup,
    { formControlName },
    formGroupName?
  ): boolean {
    const input = form.get(
      formGroupName ? [formGroupName, formControlName] : formControlName
    );

    if (input.touched && input.invalid) {
      return true;
    }
    return false;
  }

  public inputValidationError(
    form: FormGroup,
    { formControlName, placeholder },
    formGroupName?
  ): string {
    const input = form.get(
      formGroupName ? [formGroupName, formControlName] : formControlName
    );
    const error = input.errors;

    if (error?.required) {
      return `${placeholder} is required`;
    }
    if (error?.pattern) {
      return `Invalid phone number`;
    }
    if (error?.email) {
      return `Invalid email address`;
    }
    if (error?.minlength) {
      return `${placeholder} must be ${error.minlength.requiredLength} characters or longer`;
    }
    if (error?.noMatch) {
      return `Passwords do not match`;
    }
    if (error?.userGroupLimit) {
      return `You cannot select more than 10 user groups`;
    }
    return '';
  }
}
