import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TerminalComponent } from './terminal.component';
import { MainComponent } from 'src/app/shared/components/terminal/main/main.component';
import { LinkComponent } from 'src/app/shared/components/terminal/link/link.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [MainComponent, LinkComponent, TerminalComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class TerminalModule {}
