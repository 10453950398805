import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(
    private fs: AngularFirestore,
    private fn: AngularFireFunctions,
    private authService: AuthService,
    private router: Router
  ) {
    if (location.hostname !== 'assetio.co.za') {
      fn.useFunctionsEmulator('http://172.16.5.204:5001');
    }

    this.authService.orgId$.subscribe((orgId) => {
      this.collection = `organisations/${orgId}/assets`;
    });
  }
  private collection: string;

  public createAsset(data: any): Observable<any> {
    const { checkboxControl, formValue } = data;
    const { groups } = checkboxControl;
    const {
      section1: { displayName, name, serialCode },
      section2: { assetType, notes },
    } = formValue;
    return this.fn.httpsCallable('assets-newAsset')({
      displayName,
      name,
      serialCode,
      assetType,
      groups,
      notes,
    });
  }

  public getAssetCollection(limit?: string): Observable<any> {
    return this.fs
      .collection(this.collection, (ref) =>
        ref
          .orderBy('displayName')
          .where(
            'assetType',
            limit ? '==' : 'in',
            limit ? limit : ['device', 'accessory', 'book']
          )
          .where('displayName', '>=', '')
          .where('displayName', '<=', '' + '\uf8ff')
      )
      .snapshotChanges()
      .pipe(
        map((arr) => {
          if (!arr) {
            throw new Error(
              'Asset collection empty. Add an asset and try again.'
            );
          }
          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc['id'];
            const data: any = cur.payload.doc.data();

            data.uid = id;

            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  public getAsset(id: string): Observable<any> {
    return this.fs
      .collection(this.collection)
      .doc(id)
      .get()
      .pipe(
        map((doc) => {
          if (!doc.exists) {
            throw new Error('Asset does not exist');
          }
          const data = doc.data();
          data['id'] = doc.id;

          return data;
        })
      );
  }

  public updateAsset(id: string, updatedData: any): Observable<any> {
    return this.fn
      .httpsCallable('assets-updateAsset')({ id, updatedData })
      .pipe(take(1));
  }

  public deleteAsset(id: string): void {
    this.fn
      .httpsCallable('assets-deleteAsset')(id)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('dashboard/assets');
      });
  }

  public deleteAssets(ids: Array<string>): Observable<any> {
    return this.fn.httpsCallable('assets-deleteAsset')(ids).pipe(take(1));
  }
}
