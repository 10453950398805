import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPageInfo } from 'ngx-virtual-scroller';
import { map, switchMap } from 'rxjs/operators';
import {
  ActivatedRoute,
  ResolveEnd,
  ResolveStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { FilterService } from 'src/app/shared/services/filter.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  constructor(
    private dashboardService: DashboardService,
    private filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public data;
  public loading = false;
  public selection: Array<any> = [];

  private routerEvents: Subscription;
  private selectionSubscription: Subscription;

  public tableType: string;
  public tableConfig: any;

  @Input() filter: string;

  ngOnInit(): void {
    this.routerEvents = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof ResolveStart) {
        this.loading = true;
      }
      if (event instanceof ResolveEnd) {
        this.loading = false;
      }
    });

    this.route.data.subscribe(({ type, data }) => {
      const { table, collection } = data;

      this.tableType = type;
      this.tableConfig = table;
      this.data = collection.pipe(
        switchMap((v) => this.filterService.filterData(Object.values(v)))
      );
    });

    this.selectionSubscription = this.dashboardService.selection.subscribe(
      (ids) => {
        this.selection = ids;
      }
    );
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
    this.selectionSubscription.unsubscribe();
  }

  public fetchMore(event: IPageInfo): void {
    // console.log(event);
  }

  public masterToggle(scroll: any): void {
    if (this.selection.length) {
      this.selection = [];
    } else {
      scroll._items.forEach((item) => {
        this.selection.push(item);
      });
    }
    this.dashboardService.selection.next(this.selection);
  }

  public toggleCheck(data): void {
    const { uid } = data;
    const index = this.selection.findIndex((item) => {
      return item.uid === uid;
    });

    if (index === -1) {
      this.selection.push(data);
    } else {
      this.selection.splice(index, 1);
    }
    this.dashboardService.selection.next(this.selection);
  }

  public isSelected(uid: string): boolean {
    return this.selection.find((v) => {
      if (v.uid === uid) {
        return true;
      }
      return false;
    });
  }

  public isIntermediate(scroll: any): boolean {
    return (
      this.selection.length < scroll.cachedItemsLength &&
      this.selection.length !== 0
    );
  }

  public isViewportItemsChecked(scroll: any): boolean {
    return (
      scroll.cachedItemsLength === this.selection.length &&
      this.selection.length !== 0
    );
  }
}
