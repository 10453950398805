import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { NavbarComponent } from '../../shared/components/dashboard/navbar/navbar.component';
import { BreadcrumbComponent } from '../../shared/components/dashboard/breadcrumb/breadcrumb.component';
import { UsersComponent } from '../../shared/components/dashboard/users/users.component';
import { UserNewComponent } from '../../shared/components/dashboard/user-new/user-new.component';
import { UserDetailsComponent } from '../../shared/components/dashboard/user-details/user-details.component';
import { DashboardComponent } from './dashboard.component';
import { AccountCircleComponent } from '../../shared/components/dashboard/account-circle/account-circle.component';
import { SignUpComponent } from 'src/app/shared/components/auth/sign-up/sign-up.component';
import { TableComponent } from 'src/app/shared/components/dashboard/table/table.component';
import { DetailsResolver } from 'src/app/shared/resolvers/details.resolver';
import { SharedModule } from 'src/app/shared/shared.module';
import { QrComponent } from 'src/app/shared/components/dashboard/qr/qr.component';
import { LogComponent } from 'src/app/shared/components/other/log/log.component';

@NgModule({
  declarations: [
    NavbarComponent,
    BreadcrumbComponent,
    UsersComponent,
    UserNewComponent,
    UserDetailsComponent,
    DashboardComponent,
    AccountCircleComponent,
    SignUpComponent,
    TableComponent,
    QrComponent,
    LogComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    VirtualScrollerModule,
    SharedModule,
  ],
  providers: [DetailsResolver],
})
export class DashboardModule {}
