<app-modal
  [svgData]="svgData"
  [details]="data"
  [formType]="formType"
  *ngIf="qrModal"
  (click)="toggleQrModal()"
></app-modal>
<div class="card-container-detail">
  <div class="card-dashboard-alt card-detail open">
    <div class="header-detail">
      <p>Asset details</p>
      <svg viewBox="0 0 24 24">
        <path
          id="Path_479"
          data-name="Path 479"
          d="M24,24H0V0H24Z"
          fill="none"
          opacity="0.87"
        />
        <path
          id="Path_480"
          data-name="Path 480"
          d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
        />
      </svg>
    </div>
    <div class="card-header">
      <div class="header-details">
        <h6 [ngClass]="getStatusClass(data.status)">{{ data.status }}</h6>
        <h6>Last updated: {{ modifiedTimeCalculator() }}</h6>
        <h6>Created: {{ data.createdAt.seconds * 1000 | date }}</h6>
        <h6>Members: 50</h6>
      </div>
      <div class="header-icon" (click)="toggleQrModal()">
        <svg viewBox="0 0 100 100">
          <path [attr.d]="svgData"></path>
        </svg>
      </div>
    </div>
    <div class="card-body">
      <form class="detail-form" [formGroup]="detailsForm">
        <app-checkbox-control
          [type]="modal"
          *ngIf="modal"
          (selection)="selection($event)"
        ></app-checkbox-control>
        <div
          class="validation-container"
          *ngFor="let formField of formFields"
          [class.invalid]="formService?.inputValidation(detailsForm, formField)"
        >
          <input
            class="form-input"
            [type]="formField.type"
            [placeholder]="formField.placeholder"
            [formControlName]="formField.formControlName"
            [readonly]="formField.type == 'menu'"
            (click)="
              formField.type == 'menu' && openModal(formField.formControlName)
            "
          />
          <p class="form-error-text">
            {{ formService?.inputValidationError(detailsForm, formField) }}
          </p>
        </div>
        <textarea
          class="detail-textarea"
          type="text"
          rows="0"
          placeholder="Notes"
          formControlName="notes"
        ></textarea>
        <div class="btn-group-detail" [class.confirm-delete]="confirm">
          <button
            class="btn-detail btn-secondary"
            type="button"
            (click)="update(detailsForm)"
          >
            <div [class.loader]="loading">Update</div>
          </button>
          <button class="btn-icon btn-danger" (click)="delete()">
            <svg viewBox="0 0 22.944 22.944" *ngIf="!confirm">
              <path
                id="Path_97"
                data-name="Path 97"
                d="M0,0H22.944V22.944H0Z"
                fill="none"
              />
              <path
                id="Path_98"
                data-name="Path 98"
                d="M15.516,8.736V18.3H7.868V8.736h7.648M14.082,3H9.3l-.956.956H5V5.868H18.384V3.956H15.038Zm3.346,3.824H5.956V18.3a1.918,1.918,0,0,0,1.912,1.912h7.648A1.918,1.918,0,0,0,17.428,18.3Z"
                transform="translate(-0.22 -0.132)"
                fill="#fff"
              />
            </svg>
            <div *ngIf="confirm" [class.loader]="loading">Are you sure?</div>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="table table-detail closed">
    <div class="header-detail">
      <p>Asset usage history</p>
      <svg viewBox="0 0 24 24">
        <path
          id="Path_479"
          data-name="Path 479"
          d="M24,24H0V0H24Z"
          fill="none"
          opacity="0.87"
        />
        <path
          id="Path_480"
          data-name="Path 480"
          d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
        />
      </svg>
    </div>
    <div class="table-detail-header">
      <input
        class="filter-input"
        type="text"
        placeholder="Search and filter users"
      />
    </div>
    <div class="table-detail-data">
      <app-log [type]="formType" [id]="data?.id"></app-log>
    </div>
  </div>
</div>
