import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private fs: AngularFirestore,
    private fn: AngularFireFunctions,
    private authService: AuthService,
    private router: Router
  ) {
    if (location.hostname !== 'assetio.co.za') {
      fn.useFunctionsEmulator('http://172.16.5.204:5001');
    }

    this.authService.orgId$.subscribe((orgId) => {
      this.collection = `organisations/${orgId}/logs`;
    });
  }
  private collection: string;

  public getLogCollection(limit?: {
    type: string;
    id: string;
  }): Observable<any> {
    return this.fs
      .collection(this.collection, (ref) => {
        if (!limit) {
          return ref.orderBy('timestamp', 'desc');
        }
        return ref
          .orderBy('timestamp', 'desc')
          .where(limit.type, '==', limit.id);
      })
      .snapshotChanges()
      .pipe(
        map((arr) => {
          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc['id'];
            const data: any = cur.payload.doc.data();

            data.uid = id;

            return { ...acc, [id]: data };
          }, {});
        })
      );
  }
}
